import { Box } from '@mui/material'

import './container.css'

const regularStyles = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '50px',
  minWidth: '100px',
  margin: '10px',
  padding: '10px',
  boxShadow: 'rgba(0, 0, 0, 0.09) 0px 4px 0px 0px',
  borderRadius: '15px',
}

const headStyle = {
  boxShadow: 'none',
  borderRadius: '15px 15px 0 0',
  background: `radial-gradient(circle at 0 100%, transparent 20px, #fff 15px),
  radial-gradient(circle at 100% 100%, transparent 20px, #fff 15px),
  radial-gradient(#fff 15px, #fff 15px),
  radial-gradient(#fff 15px, #fff 15px)`,
  backgroundPosition: 'bottom left, bottom right, top left, top right',
  backgroundSize: '51% 51%',
  backgroundRepeat: 'no-repeat',
  marginBottom: '1px',
}

const bodyStyle = {
  boxShadow: 'none',
  borderRadius: '15px 15px 0 0',
  background: `radial-gradient(circle at 0 100%, transparent 20px, #fff 15px),
  radial-gradient(circle at 100% 100%, transparent 20px, #fff 15px),
  radial-gradient(circle at 0 0, transparent 20px, #fff 15px),
  radial-gradient(circle at 100% 0, transparent 20px, #fff 15px)`,
  backgroundPosition: 'bottom left, bottom right, top left, top right',
  backgroundSize: '51% 51%',
  backgroundRepeat: 'no-repeat',
  marginBottom: '1px',
  marginTop: '1px',
}

const footerStyle = {
  boxShadow: 'none',
  borderRadius: '0 0 15px 15px',
  background: `radial-gradient(#fff 20px, #fff 15px),
  radial-gradient(#fff 20px, #fff 15px),
  radial-gradient(circle at 0 0, transparent 20px, #fff 15px),
  radial-gradient(circle at 100% 0, transparent 20px, #fff 15px)`,
  backgroundPosition: 'bottom left, bottom right, top left, top right',
  backgroundSize: '51% 51%',
  backgroundRepeat: 'no-repeat',
  marginTop: '1px',
}

const middleStyle = {
  boxShadow: 'none',
  borderRadius: '15px',
  background: `radial-gradient(circle at 0 0, transparent 20px, #fff 15px),
  radial-gradient(circle at 100% 0, transparent 20px, #fff 15px),
  radial-gradient(circle at 0 100%, transparent 20px, #fff 15px),
  radial-gradient(circle at 100% 100%, transparent 20px, #fff 15px)`,
  backgroundPosition: 'bottom left, bottom right, top left, top right',
  backgroundSize: '51% 51%',
  backgroundRepeat: 'no-repeat',
}

const boarded = {
  borderRadius: '15px',
  backgroundColor: '#222b79',
}

const expired = {
  color: '#6c6c6c',
}

/**
 *  Ticket shape container
 *
 * @param {object} props
 * @param {('middle'| 'head' | 'body' | 'footer')} props.type - props to pass to select where the circles should go.
 * @param {string} props.otherClasses - props to pass for any other custom class.
 * @returns {React.JSX.Element}
 */
export const TicketContainer = ({
  type = 'middle',
  otherClasses = '',
  children,
}) => {
  const containerTicket = (type = {}, other = {}) => {
    return { ...regularStyles, ...type, ...other }
  }

  return (
    <Box
      component='section'
      // className={`container-ticket ${type} ${otherClasses}`}
      style={containerTicket(
        type === 'head'
          ? headStyle
          : type === 'body'
          ? bodyStyle
          : type === 'footer'
          ? footerStyle
          : type === 'boarded'
          ? boarded
          : type === 'expired'
          ? expired
          : middleStyle,
      )}>
      {children}
    </Box>
  )
}

import { busiAPi } from '../../utils/urls'
import { Box, Button } from '@mui/material'
import { BusiLogoWhite } from '../../components/icons/Busi-Logo'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export const HeaderMenu = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [value, setValue] = useState()

  useEffect(() => {
    const tabs = pathname.includes('profile')
      ? 2
      : pathname.includes('ticket') || pathname.includes('all')
      ? 1
      : 0

    setValue(tabs)
    // setValue(pathname.includes('search') || pathname.includes('buy') ? 0 : 1)
  }, [pathname])

  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
      component='section'>
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <BusiLogoWhite />

        {busiAPi.includes('dev') && (
          <h2 className='pages-login_dev'>USO INTERNO</h2>
        )}
        {busiAPi.includes('stage') && (
          <h2 className='pages-login_dev'>(STAGE) USO INTERNO</h2>
        )}
      </Box>

      <Box style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
        <Button
          onClick={() => navigate('/main/search')}
          style={{
            border: `1px solid ${value === 0 ? '#FFC600' : 'white'}`,
            borderRadius: 10,
            padding: 10,
          }}>
          <span
            style={{
              color: `${value === 0 ? '#FFC600' : 'white'}`,
              textTransform: 'capitalize',
              fontSize: '16px',
              fontFamily: 'Kufam',
              height: '23px',
            }}>
            Busqueda
          </span>
        </Button>

        <Button
          onClick={() => navigate('/main/ticket')}
          style={{
            border: `1px solid ${value === 1 ? '#FFC600' : 'white'}`,
            borderRadius: 10,
            padding: 10,
          }}>
          <span
            style={{
              color: `${value === 1 ? '#FFC600' : 'white'}`,
              textTransform: 'capitalize',
              fontSize: '16px',
              fontFamily: 'Kufam',
              height: '23px',
            }}>
            Boletos
          </span>
        </Button>

        <Button
          onClick={() => navigate('/main/profile')}
          style={{
            border: `1px solid ${value === 2 ? '#FFC600' : 'white'}`,
            borderRadius: 10,
            padding: 10,
          }}>
          <span
            style={{
              color: `${value === 2 ? '#FFC600' : 'white'}`,
              textTransform: 'capitalize',
              fontSize: '16px',
              fontFamily: 'Kufam',
              height: '23px',
            }}>
            Perfil
          </span>
        </Button>

        {/* <p style={{ color: 'white' }}>Boletos</p>
      <p style={{ color: 'white' }}>Perfil</p> */}
      </Box>
    </Box>
  )
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pages-user-validation {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: 0.2fr 1fr 0.2fr;
}

.pages-user-validation-top {
  margin-top: 3.86rem;
  /* margin-bottom: 3.86rem; */

  margin-left: 2.85rem;
  margin-right: 2.85rem;
}

.pages-user-validation-title {
  color: #aaa6a6;
  font-family: Kufam;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.8px;
}

.pages-reset_subtitle {
  color: #29348f;
  font-family: Kufam;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;

  & span {
    color: #b01f8c;
    font-family: Kufam;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}

.pages-user-validation-middle {
  margin-left: 2.85rem;
  margin-right: 2.7rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.actions {
  margin: auto;
}

.back {
  color: #29348f;
}

.pages-user-validation-middle-title {
  color: #29348f;
  font-family: Kufam;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ValidationCodePage/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,0BAA0B;EAC1B,mCAAmC;AACrC;;AAEA;EACE,mBAAmB;EACnB,4BAA4B;;EAE5B,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,SAAS;;EAET;IACE,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;EACrB;AACF;;AAEA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB","sourcesContent":[".pages-user-validation {\n  display: grid;\n  height: 100%;\n  grid-template-columns: 1fr;\n  grid-template-rows: 0.2fr 1fr 0.2fr;\n}\n\n.pages-user-validation-top {\n  margin-top: 3.86rem;\n  /* margin-bottom: 3.86rem; */\n\n  margin-left: 2.85rem;\n  margin-right: 2.85rem;\n}\n\n.pages-user-validation-title {\n  color: #aaa6a6;\n  font-family: Kufam;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  letter-spacing: 1.8px;\n}\n\n.pages-reset_subtitle {\n  color: #29348f;\n  font-family: Kufam;\n  font-size: 15px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  margin: 0;\n\n  & span {\n    color: #b01f8c;\n    font-family: Kufam;\n    font-size: 15px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: normal;\n  }\n}\n\n.pages-user-validation-middle {\n  margin-left: 2.85rem;\n  margin-right: 2.7rem;\n  margin-top: 1rem;\n  margin-bottom: 1rem;\n}\n\n.actions {\n  margin: auto;\n}\n\n.back {\n  color: #29348f;\n}\n\n.pages-user-validation-middle-title {\n  color: #29348f;\n  font-family: Kufam;\n  font-size: 16px;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

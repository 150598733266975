import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { App } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { Keyboard } from '@capacitor/keyboard'
import { ScreenOrientation } from '@capacitor/screen-orientation'

import { useForm } from 'react-hook-form'

import FormsCitizensId from '../../components/imputs/forms-citizens-id'
import FormsPassword from '../../components/imputs/forms-password'
import { ButtonBlue } from '../../components/buttons'
import FormsForgottenPassword from '../../components/imputs/forms-forgotten-password'
import { BusiLogo } from '../../components/icons/Busi-Logo'
import { ArrowBack } from '../../components/icons/ArrowBack'

import { busiAPi } from '../../utils/urls'
import { usePersistStore, useStore } from '../../store'
import { useGetAppVersion } from '../../hooks/useSettingsApi'
import { Box } from '@mui/material'

import axios from 'axios'
import './styles.login.css'

export function LoginPage() {
  const navigate = useNavigate()
  const { pathname, state } = useLocation()

  const { userData, setUserData } = usePersistStore()
  const { setPassword } = useStore()

  const { data: version, isLoading } = useGetAppVersion()

  const [status, setStatus] = useState('ci')
  const [visibility, setVisibility] = useState('visible')

  const [err, setErr] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
  })

  useEffect(() => {
    App.addListener('backButton', (data) => {
      if (window.location.pathname.includes('login')) {
        if (state?.previousPath?.includes('main')) {
          window.history.back()
        } else {
          App.exitApp()
        }
      } else if (window.location.pathname.includes('validate')) {
        navigate('/login')
      } else {
        if (window.location.pathname === '/main/search') return
        // window.history.back()
      }
    })
  }, [])

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      ScreenOrientation.lock({ orientation: 'portrait' })
    }
  }, [])

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      Keyboard.addListener('keyboardWillShow', (info) => {
        setVisibility('hidden')
      })

      Keyboard.addListener('keyboardWillHide', () => {
        setVisibility('visible')
      })
    }
  }, [])

  // useEffect(() => {
  //   localStorage.setItem('isLogin', false)
  //   const token = localStorage.getItem('token')

  //   if (token !== undefined && token !== '' && token !== null) {
  //     localStorage.setItem('isLogin', true)
  //     navigate('/main/search')
  //   } else {
  //     if (Capacitor.getPlatform() === 'web') {
  //       navigate('/main/search')
  //     }
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])

  // useEffect(() => {
  //   window.FB.ui(
  //     {
  //       method: 'share',
  //       href: 'https://developers.facebook.com/docs/',
  //     },
  //     function (response) {},
  //   )
  // }, [])

  const onSubmit = async (data) => {
    const documentId = `${data.ciCode}-${data.ci}`
    const password = data.password
    setErr(undefined)

    if (status === 'ci') {
      setLoading(true)
      try {
        const { data: info } = await axios.post(
          `${busiAPi}/auth/passengers/pre-register`,
          { documentId },
        )

        const isEmpty = Object.entries(info.data).length === 0

        if (isEmpty) {
          setUserData({
            name: undefined,
            lastName: undefined,
            ci: documentId,
            email: undefined,
            bornDate: undefined,
            phone: undefined,
          })

          setLoading(false)
          navigate('/registration', {
            state: { previousPath: pathname, wantToBuy: state?.wantToBuy },
          })
          return
        }

        if (info.data.status === 'pre_registered') {
          setUserData({
            name: info.data.firstName,
            lastName: info.data.lastName,
            ci: documentId,
            email: undefined,
            bornDate: info.data.dob,
            status: info.data.status,
            gender: undefined,
            phone: info.data.phoneNumber,
          })

          setLoading(false)
          navigate('/registration', { state: { previousPath: pathname } })
          return
        }

        setUserData({
          name: info.data.firstName,
          lastName: info.data.lastName,
          ci: documentId,
          email: info.data.email,
          bornDate: info.data.dob,
          status: info.data.status,
          gender: info.data.gender,
          phone: info.data.phoneNumber,
        })

        setLoading(false)

        if (info.data?.errorCode === 1009) {
          setErr(info.data?.message)
          return
        }

        setStatus('password')
      } catch (error) {
        console.log({ error })
        setLoading(false)
        setErr('Estamos teniendo problemas, intenta más tarde')
      }
    }

    if (status === 'password') {
      setLoading(true)
      try {
        const { data: info } = await axios.post(
          `${busiAPi}/auth/passengers/login`,
          {
            documentId,
            password,
          },
        )

        if (!info.success) {
          console.log({ info })
          setLoading(false)
          setErr(info.error.message)
        }

        if (info?.data?.errorCode === 1001) {
          // try {
          //   const { data } = await axios.post(
          //     `${busiAPi}/auth/passengers/resend-code`,
          //     { email: userData.email },
          //   )

          //   if (data.success) {
          //     setLoading(false)
          //     setPassword(password)
          //     navigate('/validate')
          //     return
          //   }
          //   return
          // } catch (error) {
          //   setLoading(false)
          //   console.log({ error })
          //   return
          // }

          setLoading(false)
          setPassword(password)
          navigate('/validate')
          return
        }

        if (info.success) {
          if (info.data?.errorCode === 1009) {
            setErr(info.data?.message)
            return
          }

          localStorage.setItem('token', info.data.token)
          localStorage.setItem('refreshToken', info.data.refreshToken)
          setLoading(false)
          if (state?.previousPath?.includes('buy')) {
            navigate(state.previousPath)
          } else {
            navigate('/main/search')
          }
        }
      } catch (error) {
        setLoading(false)
        console.log({ error })
        const mainError = error?.response?.data?.error

        if (mainError?.errorCode === 1001) {
          setErr('Usuario no confirmado')
          try {
            const { data } = await axios.post(
              `${busiAPi}/auth/passengers/resend-code`,
              { email: userData.email },
            )

            if (data.success) {
              setLoading(false)
              setPassword(password)
              navigate('/validate')
              return
            }
            return
          } catch (error) {
            setLoading(false)
            console.log({ error })
            return
          }
        }

        if (mainError?.errorCode === 1010) {
          setErr(mainError.message)
          return
        }

        setErr('Estamos teniendo problemas, intenta más tarde')
      }
    }
  }

  return (
    <Box
      component='section'
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      // className='pages-login'
    >
      {(status === 'password' || state?.previousPath?.includes('main')) && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            paddingLeft: '42px',
            height: 'calc(100vh - 620px + env(safe-area-inset-top))',
            alignItems: 'flex-end',
            minHeight: '54px',
          }}
          // className='back_arrow'
        >
          <ArrowBack
            onClick={() => {
              if (status === 'password') {
                setStatus('ci')
              } else {
                navigate(state?.previousPath)
              }
            }}
          />
        </div>
      )}

      <header
        style={{
          marginBottom: '45px',
          marginTop: status === 'ci' ? '120px' : '0px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        // className={`pages-login-top ${status === 'ci' ? 'mt' : ''}`}
      >
        {busiAPi.includes('dev') && (
          <h2 className='pages-login_dev'>USO INTERNO</h2>
        )}
        {busiAPi.includes('stage') && (
          <h2 className='pages-login_dev'>(STAGE) USO INTERNO</h2>
        )}

        <h2 className='pages-login-welcome'>Bienvenido a</h2>
        <BusiLogo />
        <h1 className='pages-login-title'>Boletos</h1>
      </header>

      <form
        style={{
          display: 'grid',
          justifyItems: 'center',
          height: '100%',
          position: 'relative',
        }}
        onSubmit={handleSubmit(onSubmit)}>
        <Box
          component='section'
          style={{
            marginLeft: '3.286rem',
            marginRight: '2.572rem',
            marginBottom: '30px',
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
            height: 'calc(100vh - 360px)',
            overflow: 'scroll',
          }}>
          <FormsCitizensId
            // registerValidation={{
            //   ...register('ci', {
            //     required: 'Este campo es requerido',
            //     maxLength: {
            //       value: getValues('ciCode') === 'J' ? 10 : 8,
            //       message:
            //         getValues('ciCode') === 'J' ? 'Maximo 10' : 'Maximo 8',
            //     },
            //     minLength: { value: 6, message: 'Minimo 6' },
            //     pattern: {
            //       value: /^[0-9]*$/,
            //       message: 'Solo numeros',
            //     },
            //   }),
            // }}
            // registerCode={{ ...register('ciCode', { required: false }) }}
            // error={errors.ci?.message}
            // getValues={getValues}
            readOnly={status === 'password'}
            control={control}
            setValue={setValue}
            getValue={getValues('ciCode')}
          />

          {status === 'password' && (
            <>
              <FormsPassword
                registerValidation={{
                  ...register('password', {
                    required: 'Este campo es requerido',
                  }),
                }}
                error={errors.password?.message}
              />

              <FormsForgottenPassword
                handleClick={() => {
                  navigate('/reset')
                }}
              />
            </>
          )}
          {err && <p className='error_message'>{err}</p>}
        </Box>

        <div
          style={{
            position: 'absolute',
            bottom: '40px',
            visibility: visibility,
          }}>
          <ButtonBlue type='submit' disabled={loading}>
            Continuar
          </ButtonBlue>

          <p
            style={{
              margin: 0,
              marginTop: '10px',
              fontSize: '10px',
              color: '#898989',
              textAlign: 'center',
            }}>
            version: {isLoading ? '2.0.7' : version?.data?.value}
          </p>
        </div>
      </form>
    </Box>
  )
}

import { useEffect, useState } from 'react'

import { GrayCarretIcon } from '../../icons/CaretDown'
import { usePersistStore } from '../../../store'

import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import { Box } from '@mui/material'
import { InputControlMaterial } from '../index'

import './styles.citizen.css'
import { useController } from 'react-hook-form'

const options = [
  { value: 'V', label: 'V' },
  { value: 'E', label: 'E' },
  { value: 'J', label: 'J' },
  { value: 'P', label: 'P' },
  { value: 'C', label: 'C' },
  { value: 'G', label: 'G' },
  { value: 'R', label: 'R' },
  { value: 'F', label: 'F' },
  { value: 'I', label: 'I' },
]

const animatedComponents = makeAnimated()

export default function FormsCitizensId({
  // registerValidation = {},
  // registerCode = {},
  setValue,
  // error = '',
  readOnly = false,
  ciName = 'ci',
  codeId = 'ciCode',
  control,
  getValue,
  max = 8,
}) {
  const [isRead, setIsRead] = useState(false)
  const { userData } = usePersistStore()

  const {
    field: fieldCi,
    fieldState: { error },
  } = useController({
    name: ciName,
    control,
    defaultValue: '',
    rules: {
      required: 'Este campo es requerido',
      maxLength: {
        value: getValue === 'J' || getValue === 'P' ? 10 : max,
        message:
          getValue === 'J' || getValue === 'P' ? 'Maximo 10' : 'Maximo 8',
      },
      minLength: {
        value: getValue === 'J' || getValue === 'P' ? 9 : 6,
        message: getValue === 'J' || getValue === 'P' ? 'Minimo 9' : 'Minimo 6',
      },
      pattern: {
        value: /^[0-9]*$/,
        message: 'Solo numeros',
      },
    },
  })

  const { field: fieldCode } = useController({
    name: codeId,
    control,
    rules: {
      required: 'Este campo es requerido',
    },
  })

  useEffect(() => {
    setIsRead(readOnly)
  }, [readOnly])

  useEffect(() => {
    // fieldCode.onChange(options[0].value)
    setValue(codeId, options[0].value)
    // fieldCode.value = options[0].value
  }, [])

  const handePress = (e) => {
    const keyCode = e.keyCode
    const key = Number(e.key)

    // Only allow numbers to be entered

    if (keyCode !== 8 && keyCode !== 13) {
      if (isNaN(key)) {
        e.preventDefault()
      }
    }
  }

  return (
    <Box
      component='section'
      style={{
        minWidth: '280px',
        display: 'flex',
        gap: '1rem',
        margin: '0 2px',
        alignItems: isRead ? 'flex-end' : 'start',
      }}
      // className={`citizenId ${isRead ? 'read' : ''}`}
    >
      {isRead ? (
        <Box
          // className='select-value_read_container'
          style={{
            display: 'flex',
            gap: '10px',
            borderBottom: '1px solid #0000006b',
            width: '20%',
          }}>
          <p
            // className='select-value_read'
            style={{
              color: '#0000006b',
              fontFamily: 'Kufam',
              fontSize: '16px',
              margin: '0',
              paddingLeft: '5px',
            }}>
            {userData?.ci?.slice(0, 1)}
          </p>
          <GrayCarretIcon />
        </Box>
      ) : (
        <Select
          options={options}
          defaultValue={options[0]}
          isSearchable={false}
          components={animatedComponents}
          // onBlur={fieldCode.onBlur}
          // value={fieldCode.value}
          // name={fieldCode.name}
          // inputRef={fieldCode.ref}
          onChange={(data) => {
            fieldCode.onChange(data.value)
            setValue(codeId, data.value)
          }}
          styles={{
            container: (baseStyles) => ({
              ...baseStyles,
              width: '14%',
              boxShadow: 'none',
              outline: 'none',
              marginTop: '15px',
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              border: 'none',
              borderRadius: '0px',
              borderBottom: '1px solid #29348F',
              boxShadow: 'none',
              minHeight: '30px',
              backgroundColor: 'transparent',
            }),
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              padding: 0,
              height: '33px',
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              padding: 0,
              margin: 0,
            }),
            indicatorSeparator: (baseStyles) => ({
              ...baseStyles,
              display: 'none',
            }),
            dropdownIndicator: (baseStyles) => ({
              ...baseStyles,
              padding: '2px',
              paddingBottom: '0px',
              color: '#29348F',
            }),
            placeholder: (baseStyles) => ({
              ...baseStyles,
              color: '#29348F',
            }),
            singleValue: (baseStyles) => ({
              ...baseStyles,
              color: '#29348F',
              fontSize: '16px',
            }),
            option: (baseStyles) => ({
              ...baseStyles,
              color: '#29348F',
              background: 'transparent',
            }),
          }}
        />
      )}

      <Box component='section' style={{ width: '80%' }}>
        <Box
          className={`form-input ${isRead ? 'read' : ''}`}
          style={{
            width: '100%',
          }}>
          {isRead ? (
            <>
              <p
                className='label_read'
                style={{
                  color: '#0000006b',
                  fontFamily: 'Kufam',
                  fontSize: '12px',
                  margin: '0',
                }}>
                Número de documento
              </p>
              <p
                className='input-value_read'
                style={{
                  color: '#0000006b',
                  borderBottom: '1px solid #0000006b',
                  fontFamily: 'Kufam',
                  fontSize: '16px',
                  width: '100%',
                  margin: '0',
                }}>
                {userData?.ci?.slice(2)}
              </p>
            </>
          ) : (
            <>
              {/* <InputMaterial
              register={registerValidation}
              label='Número de documento'
              type='number'
            /> */}
              <InputControlMaterial
                field={fieldCi}
                onChange={fieldCi.onChange}
                onKeyDown={handePress}
                label='Número de documento'
                type='number'
              />
            </>
          )}
        </Box>

        {error && isRead === false && (
          <p className='error_message_ci'>{error.message}</p>
        )}
      </Box>
    </Box>
  )
}

export function CIWithChange({
  // registerValidation = {},
  // registerCode = {},
  setValue,
  // error = '',
  handleInputChange,
  readOnly = false,
  ciName = 'ci',
  codeId = 'ciCode',
  handleChangeId,
  control,
  getValue,
}) {
  const [isRead, setIsRead] = useState(false)
  const { userData } = usePersistStore()

  const {
    field: fieldCi,
    fieldState: { error },
  } = useController({
    name: ciName,
    control,
    defaultValue: '',
    rules: {
      required: 'Este campo es requerido',
      maxLength: {
        value: getValue === 'J' ? 9 : 8,
        message: getValue === 'J' ? 'Maximo 9' : 'Maximo 8',
      },
      minLength: { value: 6, message: 'Minimo 6' },
      pattern: {
        value: /^[0-9]*$/,
        message: 'Solo numeros',
      },
      onChange: (e) =>
        handleInputChange(e.target.value, handleChangeId, 'document'),
    },
  })

  const { field: fieldCode } = useController({
    name: codeId,
    control,
    rules: {
      required: 'Este campo es requerido',
      onChange: (e) =>
        handleInputChange(e.target.value, handleChangeId, 'typeDocument'),
    },
  })

  useEffect(() => {
    setIsRead(readOnly)
  }, [readOnly])

  useEffect(() => {
    // fieldCode.onChange(options[0].value)
    setValue(codeId, options[0].value)
    fieldCode.value = options[0].value
  }, [])

  const handlePress = (e) => {
    const keyCode = e.keyCode
    const key = Number(e.key)

    // Only allow numbers to be entered

    if (keyCode !== 8 && keyCode !== 13) {
      if (isNaN(key)) {
        e.preventDefault()
      }
    }
  }

  return (
    <Box
      component='section'
      style={{
        minWidth: '280px',
        display: 'flex',
        gap: '1rem',
        margin: '0 2px',
        alignItems: isRead ? 'flex-end' : 'start',
      }}
      // className={`citizenId ${isRead ? 'read' : ''}`}
    >
      {isRead ? (
        <Box
          // className='select-value_read_container'
          style={{
            display: 'flex',
            gap: '10px',
            borderBottom: '1px solid #0000006b',
            width: '20%',
          }}>
          <p
            // className='select-value_read'
            style={{
              color: '#0000006b',
              fontFamily: 'Kufam',
              fontSize: '16px',
              margin: '0',
              paddingLeft: '5px',
            }}>
            {userData?.ci?.slice(0, 1)}
          </p>
          <GrayCarretIcon />
        </Box>
      ) : (
        <Select
          options={options}
          defaultValue={options[0]}
          isSearchable={false}
          components={animatedComponents}
          styles={{
            container: (baseStyles) => ({
              ...baseStyles,
              width: '14%',
              boxShadow: 'none',
              outline: 'none',
              marginTop: '15px',
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              border: 'none',
              borderRadius: '0px',
              borderBottom: '1px solid #29348F',
              boxShadow: 'none',
              minHeight: '30px',
              backgroundColor: 'transparent',
            }),
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              padding: 0,
              height: '33px',
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              padding: 0,
              margin: 0,
            }),
            indicatorSeparator: (baseStyles) => ({
              ...baseStyles,
              display: 'none',
            }),
            dropdownIndicator: (baseStyles) => ({
              ...baseStyles,
              padding: '2px',
              paddingBottom: '0px',
              color: '#29348F',
            }),
            placeholder: (baseStyles) => ({
              ...baseStyles,
              color: '#29348F',
            }),
            singleValue: (baseStyles) => ({
              ...baseStyles,
              color: '#29348F',
              fontSize: '16px',
            }),
            option: (baseStyles) => ({
              ...baseStyles,
              color: '#29348F',
              background: 'transparent',
            }),
          }}
          onChange={(data) => {
            fieldCode.onChange(data.vale)
            setValue(codeId, data.value)
          }}
        />
      )}

      <Box component='section' style={{ width: '80%' }}>
        <Box
          className={`form-input ${isRead ? 'read' : ''}`}
          style={{
            width: '100%',
          }}>
          {isRead ? (
            <>
              <p
                className='label_read'
                style={{
                  color: '#0000006b',
                  fontFamily: 'Kufam',
                  fontSize: '12px',
                  margin: '0',
                }}>
                Número de documento
              </p>
              <p
                className='input-value_read'
                style={{
                  color: '#0000006b',
                  borderBottom: '1px solid #0000006b',
                  fontFamily: 'Kufam',
                  fontSize: '16px',
                  width: '100%',
                  margin: '0',
                }}>
                {userData?.ci?.slice(2)}
              </p>
            </>
          ) : (
            <>
              {/* <InputMaterial
              register={registerValidation}
              label='Número de documento'
              type='number'
            /> */}
              <InputControlMaterial
                field={fieldCi}
                onChange={fieldCi.onChange}
                onKeyDown={handlePress}
                label='Número de documento'
                type='number'
              />
            </>
          )}
        </Box>
        {error && isRead === false && (
          <p className='error_message_ci'>{error.message}</p>
        )}
      </Box>
    </Box>
  )
}

import React, { memo } from "react";

function DoorStairsIcon({ color = "#D88019", style = {} }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="12"
      viewBox="0 0 20 12"
      fill="none"
      style={style}
    >
      <path
        d="M2.02869 0V0.529218H0V11.0882H0.750007V1.29437H2.02869V12L7 11.0882V9.59617V0.913706L2.02869 0ZM2.94323 6.41126C2.77612 6.41126 2.64063 6.23142 2.64063 6.00958C2.64063 5.78775 2.77612 5.60791 2.94323 5.60791C3.11034 5.60791 3.24583 5.78775 3.24583 6.00958C3.24583 6.23142 3.11034 6.41126 2.94323 6.41126Z"
        fill={color}
      />
      <path
        d="M19.6206 3.05713H16.9657C16.8652 3.05713 16.7687 3.09709 16.6976 3.16821C16.6264 3.23934 16.5865 3.33581 16.5865 3.4364V5.33274H14.6901C14.5895 5.33274 14.4931 5.3727 14.4219 5.44383C14.3508 5.51495 14.3109 5.61142 14.3109 5.71201V7.60835H12.4145C12.3139 7.60835 12.2175 7.64831 12.1463 7.71944C12.0752 7.79056 12.0353 7.88703 12.0353 7.98762V9.88396H9.75964C9.65905 9.88396 9.56258 9.92392 9.49146 9.99505C9.42033 10.0662 9.38037 10.1626 9.38037 10.2632C9.38037 10.3638 9.42033 10.4603 9.49146 10.5314C9.56258 10.6025 9.65905 10.6425 9.75964 10.6425H12.4145C12.5151 10.6425 12.6116 10.6025 12.6827 10.5314C12.7538 10.4603 12.7938 10.3638 12.7938 10.2632V8.36689H14.6901C14.7907 8.36689 14.8872 8.32693 14.9583 8.25581C15.0294 8.18468 15.0694 8.08821 15.0694 7.98762V6.09128H16.9657C17.0663 6.09128 17.1628 6.05132 17.2339 5.98019C17.3051 5.90907 17.345 5.8126 17.345 5.71201V3.81567H19.6206C19.7212 3.81567 19.8177 3.77571 19.8888 3.70458C19.9599 3.63345 19.9999 3.53699 19.9999 3.4364C19.9999 3.33581 19.9599 3.23934 19.8888 3.16821C19.8177 3.09709 19.7212 3.05713 19.6206 3.05713Z"
        fill={color}
      />
      <path
        d="M9.75965 6.47052C9.80956 6.47081 9.85904 6.46125 9.90525 6.44237C9.95146 6.4235 9.99349 6.39569 10.0289 6.36054L14.3109 2.07859V2.54889C14.3109 2.64947 14.3508 2.74594 14.422 2.81707C14.4931 2.8882 14.5896 2.92815 14.6901 2.92815C14.7907 2.92815 14.8872 2.8882 14.9583 2.81707C15.0295 2.74594 15.0694 2.64947 15.0694 2.54889V1.16076C15.0694 1.06017 15.0295 0.963706 14.9583 0.892579C14.8872 0.821453 14.7907 0.781494 14.6901 0.781494H13.302C13.2014 0.781494 13.105 0.821453 13.0338 0.892579C12.9627 0.963706 12.9227 1.06017 12.9227 1.16076C12.9227 1.26135 12.9627 1.35782 13.0338 1.42895C13.105 1.50007 13.2014 1.54003 13.302 1.54003H13.7723L9.49037 5.82197C9.43688 5.87502 9.4004 5.9428 9.38558 6.01666C9.37077 6.09052 9.37828 6.16712 9.40717 6.2367C9.43605 6.30627 9.48501 6.36567 9.54778 6.40731C9.61056 6.44896 9.68432 6.47096 9.75965 6.47052Z"
        fill={color}
      />
    </svg>
  );
}

export default memo(DoorStairsIcon);

import { InputMaterial } from '../index'
// import { useController } from 'react-hook-form'

import './styles.username.css'

export default function FormsName({
  readOnly,
  registerName = {},
  registerLastname = {},
  onChangeFirst,
  onChangeSecond,
  errorName = '',
  errorLastname = '',
  // control,
}) {
  // const { field } = useController({ name: 'name', control })

  return (
    <section className='name'>
      <div style={{ width: '50%', margin: 0 }} className='input-field'>
        <InputMaterial
          register={registerName}
          label='Nombre'
          // onChange={onChangeFirst}
        />

        {errorName && <p className='error_message'>{errorName}</p>}
      </div>

      <div style={{ width: '50%', margin: 0 }} className='input-field'>
        <InputMaterial
          register={registerLastname}
          label='Apellido'
          // onChange={onChangeSecond}
        />

        {errorLastname && <p className='error_message'>{errorLastname}</p>}
      </div>
    </section>
  )
}

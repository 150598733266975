import { get } from '../api/axiosHelper'
import { useQuery } from '@tanstack/react-query'

/**
 *
 * @returns {(data: any, isLoading: boolean, isError: boolean, error: any)}
 */
export const useGetUserTickets = (options = {}) => {
  return useQuery({
    queryKey: ['passenger-tickets'],
    queryFn: () => get('passenger/tickets', options),
    retryDelay: 20000,
  })
}

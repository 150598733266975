import { get } from '../api/axiosHelper'
import { useQuery } from '@tanstack/react-query'

export const useGetExchains = (options = {}) => {
  return useQuery({
    queryKey: ['exchain'],
    queryFn: () => get('setting/exchange-rates', options),
    retryDelay: 20000,
  })
}

import { useMemo } from 'react'

export const useWantToLogin = () => {
  const wantToLogin = useMemo(() => {
    const token = localStorage.getItem('token')
    const isToken = token !== undefined && token !== '' && token !== null

    return isToken
  }, [])

  const setWantToLogin = (value) => {
    localStorage.setItem('wantToLogin', value)
  }
  return { wantToLogin, setWantToLogin }
}

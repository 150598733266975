export const ArrowBack = ({ color = '#29348F', onClick }) => {
  return (
    <svg
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'>
      <g clipPath='url(#clip0_3_2959)'>
        <path
          d='M16.6666 9.16668H6.52498L11.1833 4.50834L9.99998 3.33334L3.33331 10L9.99998 16.6667L11.175 15.4917L6.52498 10.8333H16.6666V9.16668Z'
          fill={color}
        />
      </g>
      <defs>
        <clipPath id='clip0_3_2959'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.phone-number_container {
  display: flex;
  gap: 1rem;
  /* align-items: flex-end; */

  .input-field {
    .input-phone_digits {
      color: #29348f;
      font-family: Kufam;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
      text-align: start;
      margin: 0;
      height: 30px;

      border-bottom: 1px solid #29348f;
    }

    .select-wrapper {
      width: 55px;
      height: 33px;
      .select-dropdown {
        padding-left: 5px;
        border-bottom: none;
        margin: 0;
        /* z-index: 0; */
      }

      .caret {
        display: none;
      }
    }
  }
}

.select-wrapper input.select-dropdown:focus {
  border-bottom: 1px solid #29348f;
  font-family: 'Kufam';
}
.select-wrapper input.select-dropdown {
  border-bottom: 1px solid #29348f;
  font-family: 'Kufam';
}
`, "",{"version":3,"sources":["webpack://./src/components/imputs/forms-phone-number/styles.inputphone.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,2BAA2B;;EAE3B;IACE;MACE,cAAc;MACd,kBAAkB;MAClB,eAAe;MACf,kBAAkB;MAClB,gBAAgB;MAChB,iBAAiB,EAAE,SAAS;MAC5B,iBAAiB;MACjB,SAAS;MACT,YAAY;;MAEZ,gCAAgC;IAClC;;IAEA;MACE,WAAW;MACX,YAAY;MACZ;QACE,iBAAiB;QACjB,mBAAmB;QACnB,SAAS;QACT,gBAAgB;MAClB;;MAEA;QACE,aAAa;MACf;IACF;EACF;AACF;;AAEA;EACE,gCAAgC;EAChC,oBAAoB;AACtB;AACA;EACE,gCAAgC;EAChC,oBAAoB;AACtB","sourcesContent":[".phone-number_container {\n  display: flex;\n  gap: 1rem;\n  /* align-items: flex-end; */\n\n  .input-field {\n    .input-phone_digits {\n      color: #29348f;\n      font-family: Kufam;\n      font-size: 16px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: 24px; /* 150% */\n      text-align: start;\n      margin: 0;\n      height: 30px;\n\n      border-bottom: 1px solid #29348f;\n    }\n\n    .select-wrapper {\n      width: 55px;\n      height: 33px;\n      .select-dropdown {\n        padding-left: 5px;\n        border-bottom: none;\n        margin: 0;\n        /* z-index: 0; */\n      }\n\n      .caret {\n        display: none;\n      }\n    }\n  }\n}\n\n.select-wrapper input.select-dropdown:focus {\n  border-bottom: 1px solid #29348f;\n  font-family: 'Kufam';\n}\n.select-wrapper input.select-dropdown {\n  border-bottom: 1px solid #29348f;\n  font-family: 'Kufam';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

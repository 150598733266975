import React from 'react'

export const EditIcon = () => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_3037_413)'>
        <path
          d='M7 1.75H2.91667C2.60725 1.75 2.3105 1.87292 2.09171 2.09171C1.87292 2.3105 1.75 2.60725 1.75 2.91667V11.0833C1.75 11.3928 1.87292 11.6895 2.09171 11.9083C2.3105 12.1271 2.60725 12.25 2.91667 12.25H11.0833C11.3928 12.25 11.6895 12.1271 11.9083 11.9083C12.1271 11.6895 12.25 11.3928 12.25 11.0833V7'
          stroke='#B01F8C'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10.7188 1.53114C10.9508 1.29907 11.2656 1.1687 11.5938 1.1687C11.9219 1.1687 12.2367 1.29907 12.4688 1.53114C12.7008 1.7632 12.8312 2.07795 12.8312 2.40614C12.8312 2.73433 12.7008 3.04907 12.4688 3.28114L7.00001 8.74989L4.66667 9.33322L5.25001 6.99989L10.7188 1.53114Z'
          stroke='#B01F8C'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_3037_413'>
          <rect width='14' height='14' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

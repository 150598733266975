import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { App } from '@capacitor/app'

export const AppUrlListener = () => {
  let navigate = useNavigate()

  useEffect(() => {
    App.addListener('appUrlOpen', (event) => {
      // Example url: https://beerswift.app/tabs/tab2
      // slug = /tabs/tab2

      // console.log({ slug, url: event.url })
      const slug = event.url.split('panel.busitransporte.com').pop()
      if (slug) {
        navigate(slug)
      }
      // If no match, do nothing - let regular routing
      // logic take over
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

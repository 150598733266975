import { useState } from 'react'
import { Box } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Bus, BusLayout } from '../../components/busLayout'
import { GoBack } from '../../components/ui/GoBack'
import { useStore } from '../../store'

import { useForm } from 'react-hook-form'

import dayjs from 'dayjs'

import { Radio } from '../../components/ui/Radio'
import { ButtonBlue } from '../../components/buttons'

import './style.seat.css'

const mat = [
  {
    floor: 1,
    columns: 5,
    seats: [
      { spot: 1, type: 'seat', state: 'available' },
      { spot: 2, type: 'seat', state: 'available' },
      { type: 'floor' },
      { type: 'stair' },
      { type: 'stair' },
      { spot: 3, type: 'seat', state: 'available' },
      { spot: 4, type: 'seat', state: 'available' },
      { type: 'floor' },
      { spot: 5, type: 'seat', state: 'available' },
      { spot: 6, type: 'seat', state: 'available' },
      { spot: 7, type: 'seat', state: 'available' },
      { spot: 8, type: 'seat', state: 'available' },
      { type: 'floor' },
      { spot: 9, type: 'seat', state: 'available' },
      { spot: 10, type: 'seat', state: 'available' },
      { spot: 11, type: 'seat', state: 'available' },
      { spot: 12, type: 'seat', state: 'available' },
      { type: 'floor' },
      { spot: 13, type: 'seat', state: 'available' },
      { spot: 14, type: 'seat', state: 'available' },
      { spot: 15, type: 'seat', state: 'available' },
      { spot: 16, type: 'seat', state: 'available' },
      { type: 'floor' },
      { spot: 17, type: 'seat', state: 'available' },
      { spot: 18, type: 'seat', state: 'available' },
      { spot: 19, type: 'seat', state: 'busy' },
      { spot: 20, type: 'seat', state: 'busy' },
      { type: 'floor' },
      { type: 'floor' },
      { type: 'restroom' },
      { spot: 21, type: 'seat', state: 'available' },
      { spot: 22, type: 'seat', state: 'available' },
      { spot: 23, type: 'seat', state: 'available' },
      { spot: 24, type: 'seat', state: 'available' },
      { spot: 25, type: 'seat', state: 'available' },
    ],
  },
  {
    floor: 2,
    columns: 5,
    seats: [
      { spot: 1, type: 'seat', state: 'available' },
      { spot: 2, type: 'seat', state: 'available' },
      { type: 'floor' },
      { type: 'stair' },
      { type: 'stair' },
      { spot: 3, type: 'seat', state: 'available' },
      { spot: 4, type: 'seat', state: 'available' },
      { type: 'floor' },
      { spot: 5, type: 'seat', state: 'available' },
      { spot: 6, type: 'seat', state: 'available' },
      { spot: 7, type: 'seat', state: 'available' },
      { spot: 8, type: 'seat', state: 'available' },
      { type: 'floor' },
      { spot: 9, type: 'seat', state: 'available' },
      { spot: 10, type: 'seat', state: 'available' },
      { spot: 11, type: 'seat', state: 'available' },
      { spot: 12, type: 'seat', state: 'available' },
      { type: 'floor' },
      { spot: 13, type: 'seat', state: 'available' },
      { spot: 14, type: 'seat', state: 'available' },
      { spot: 15, type: 'seat', state: 'available' },
      { spot: 16, type: 'seat', state: 'available' },
      { type: 'floor' },
      { spot: 17, type: 'seat', state: 'available' },
      { spot: 18, type: 'seat', state: 'available' },
      { spot: 19, type: 'seat', state: 'busy' },
      { spot: 20, type: 'seat', state: 'busy' },
      { type: 'floor' },
      { type: 'floor' },
      { type: 'restroom' },
      { spot: 21, type: 'seat', state: 'available' },
      { spot: 22, type: 'seat', state: 'available' },
      { spot: 23, type: 'seat', state: 'available' },
      { spot: 24, type: 'seat', state: 'available' },
      { spot: 25, type: 'seat', state: 'available' },
    ],
  },
]

export const ChooseSeatPage = () => {
  const navegate = useNavigate()
  const [searchParams] = useSearchParams()
  const ticketsNumber = searchParams.get('ticketsNumber')

  const { destination } = useStore(({ destination }) => ({ destination }))
  const { handleSubmit, register, setValue, watch } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      floor: '1',
    },
  })

  // const [oneFloor, setOneFloor] = useState(0)
  const [selectedSeat, setSelectedSeat] = useState(0)
  const watchFloor = watch('floor')

  // const destinationTime = () => {
  //   if (!destination.date) {
  //     return null
  //   }
  //   const date = dayjs(destination.date, 'DD/MM/YYYY')
  //   const matchDate = Intl.DateTimeFormat('es-VE', {
  //     year: 'numeric',
  //     month: 'long',
  //     day: '2-digit',
  //   })
  //     .format(date.toDate())
  //     .replaceAll('de ', '')

  //   return matchDate
  //   // return dayjs(destination.date, 'DD/MM/YYYY', 'es').format('DD MMMM YYYY')
  // }

  const onSubmit = (data) => {
    console.log({ data })
  }

  return (
    <Box
      component='section'
      style={{
        borderRadius: '20px 20px 0px 0px',
        background: '#f0eeee',
        boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.25)',
        position: 'relative',
        bottom: '18px',
        padding: '16px',
        paddingTop: '22px',
        height: 'calc(100% + 18px)',
        overflow: 'scroll',
      }}>
      <GoBack
        onHandleClick={() => navegate('/main/ticket')}
        title='Selecciona tus asientos'
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            flexWrap: 'nowrap',
          }}>
          <p
            style={{
              width: 'max-content',
              height: '19px',
              fontSize: '14px',
              textWrap: 'nowrap',
            }}
            className='location_text'>
            {destination?.from.split(' ').at(-1)} ({destination.shortFrom})
          </p>

          <hr className='divider-dash_search' />

          <p
            style={{
              width: 'max-content',
              height: '19px',
              fontSize: '14px',
              textWrap: 'nowrap',
            }}
            className='location_text'>
            {destination?.to.split(' ').at(-1)} ({destination.shortTo})
          </p>
        </Box>

        <p
          style={{
            textAlign: 'start',
            fontFamily: 'Kufam',
            fontSize: '14px',
            fontWeight: '700',
          }}
          className='search-bar_date_text'>
          {destination.date}
        </p>

        <Box
          component='section'
          style={{
            display: 'grid',
            gridTemplateColumns: '0.7fr 1fr',
            gap: '10px',
            overflowY: 'scroll',
            height: 'calc(100vh - 325px)',
            marginTop: '30px',
          }}>
          <Box
            component='section'
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              maxHeight: '420px',
              padding: '8px 0px',
            }}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}>
              <Radio
                labelRight='Piso 1'
                registerValidation={{
                  ...register('floor', {
                    required: 'Este campo es requerido',
                  }),
                }}
                value='1'
                id='one'
                setValue={setValue}
              />

              <hr
                className='divider-vertical_dash_search'
                style={{ marginLeft: '7px' }}
              />

              <Radio
                labelRight='Piso 2'
                registerValidation={{
                  ...register('floor', {
                    required: 'Este campo es requerido',
                  }),
                }}
                value='2'
                id='two'
                setValue={setValue}
              />
            </Box>

            <Box>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <Box
                  style={{
                    width: '16px',
                    height: '16px',
                    borderRadius: '4px',
                    background: '#3BD4AE',
                  }}
                />
                <p className='parragraph_legend'>Asientos seleccionados</p>
              </Box>

              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <Box
                  style={{
                    width: '16px',
                    height: '16px',
                    borderRadius: '4px',
                    background: '#FFFFFF',
                  }}
                />
                <p className='parragraph_legend'>Asientos disponibles</p>
              </Box>

              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <Box
                  style={{
                    width: '16px',
                    height: '16px',
                    borderRadius: '4px',
                    background: '#D31E4A',
                  }}
                />
                <p className='parragraph_legend'>Asientos ocupados</p>
              </Box>
            </Box>
          </Box>

          <Bus seats={ticketsNumber} selected={selectedSeat}>
            <BusLayout
              info={mat}
              avaliableSeats={ticketsNumber}
              setSeatNumber={setSelectedSeat}
              seatsNumber={selectedSeat}
              watchFloor={watchFloor}
            />
          </Bus>
        </Box>

        <Box
          component='section'
          style={{ display: 'flex', justifyContent: 'center' }}>
          <Box style={{ position: 'absolute', bottom: '18px' }}>
            <ButtonBlue type='submit'>Guardar selección de asientos</ButtonBlue>
          </Box>
        </Box>
      </form>
    </Box>
  )
}

import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import './style.info.css'

export const InfoCardWarning = ({ title = '', isLittle = false }) => {
  return (
    <section className={`info-card_container ${isLittle ? 'little' : ''}`}>
      <div className={`icon-info-card_container ${isLittle ? 'little' : ''}`}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width={isLittle ? '25' : '39'}
          height={isLittle ? '25' : '39'}
          viewBox='0 0 47 47'
          fill='none'>
          <circle
            cx='23.5'
            cy='23.5'
            r='23.5'
            fill='#29348F'
            fillOpacity='0.5'
          />
          <circle cx='23.4999' cy='23.4999' r='18.1425' fill='#FFFFFE' />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M23.8647 4.36377C34.4825 4.36377 43.093 12.9742 43.093 23.592C43.093 34.2098 34.4825 42.8203 23.8647 42.8203C13.2469 42.8203 4.63647 34.2098 4.63647 23.592C4.63647 12.9742 13.2469 4.36377 23.8647 4.36377ZM23.8553 32.3981C24.9171 32.3981 25.7782 33.2591 25.7782 34.3209C25.7782 35.3827 24.9171 36.2438 23.8553 36.2438C22.7936 36.2438 21.9325 35.3827 21.9325 34.3209C21.9325 33.2591 22.7936 32.3981 23.8553 32.3981ZM21.914 29.8777V11.7986C21.914 11.2339 22.3787 10.7731 22.9395 10.7731H24.79C25.3508 10.7731 25.8154 11.2378 25.8154 11.7986V29.8777C25.8154 30.4385 25.3547 30.9032 24.79 30.9032H22.9395C22.3748 30.9032 21.914 30.4424 21.914 29.8777Z'
            fill='#29348F'
          />
        </svg>
      </div>

      <div className={`text-info-card_container ${isLittle ? 'little' : ''}`}>
        <h2 className='info_title'>{title}</h2>
      </div>
    </section>
  )
}

export const YellowInfoCard = ({ title = '', subtitle = '' }) => {
  return (
    <section className={`yellow-info-card_container`}>
      <div className={`text-info-card_container`}>
        <p className='info_subtitle'>{subtitle}</p>
        <h2 className='info_title'>{title}</h2>
      </div>
    </section>
  )
}
export const YellowInfoCard2 = ({ children }) => {
  return (
    <section className={`yellow-info-card_container`}>
      <div className={`text-info-card_container`}>{children}</div>
    </section>
  )
}

export const InternationalYellowCard = ({ title, noMargin = false }) => {
  return (
    <section
      className={`yellow-info-card_container ${noMargin ? 'nomargin' : ''}`}>
      <div className={`yellow-card_container`}>
        <HelpOutlineIcon fontSize='small' color='primary' />
        <p style={{ fontSize: 10, fontWeight: 700 }}>{title}</p>
      </div>
    </section>
  )
}

import React from 'react'

export const Twitter = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={20}
      viewBox='0 0 500 500'
      fill='none'
      {...props}>
      <path
        fill='#FFC600'
        d='M250 500c138.071 0 250-111.929 250-250S388.071 0 250 0 0 111.929 0 250s111.929 250 250 250Z'
      />
      <path
        fill='#fff'
        d='M411.937 149.569a138.566 138.566 0 0 1-38.261 10.498 66.063 66.063 0 0 0 29.215-36.708 132.613 132.613 0 0 1-42.105 16.09c-25.044-26.782-67.046-28.193-93.828-3.149a66.389 66.389 0 0 0-21.047 48.545 68.33 68.33 0 0 0 1.544 15.128 188.035 188.035 0 0 1-136.873-69.438c-17.766 30.349-8.812 69.275 20.423 88.819a65.668 65.668 0 0 1-30.001-8.188v.726c.061 31.586 22.263 58.797 53.185 65.196a66.182 66.182 0 0 1-17.398 2.167 59.063 59.063 0 0 1-12.593-1.114c8.811 27.007 33.671 45.529 62.068 46.245a133.506 133.506 0 0 1-82.236 28.325 121.251 121.251 0 0 1-15.886-.92 186.712 186.712 0 0 0 101.975 29.797c122.205 0 189.016-101.218 189.016-188.944 0-2.923 0-5.755-.235-8.586a132.112 132.112 0 0 0 33.027-34.469l.01-.02Z'
      />
    </svg>
  )
}
export const Tiktoks = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={20}
      fill='none'
      viewBox='0 0 500 500'
      {...props}>
      <path
        d='M248.053 444.339C346.217 444.339 425.793 364.762 425.793 266.599C425.793 168.436 346.217 88.8594 248.053 88.8594C149.89 88.8594 70.3135 168.436 70.3135 266.599C70.3135 364.762 149.89 444.339 248.053 444.339Z'
        fill='white'
      />
      <path
        d='M250 0C111.956 0 0 111.956 0 250C0 388.044 111.956 500 250 500C388.044 500 500 388.044 500 250C500 111.956 388.055 0 250 0ZM375.425 191.284V225.139C359.46 225.139 343.958 222.019 329.328 215.844C319.926 211.874 311.158 206.754 303.143 200.579L303.38 304.768C303.283 328.231 293.999 350.273 277.196 366.883C263.523 380.395 246.202 388.99 227.419 391.777C223.009 392.433 218.512 392.766 213.972 392.766C193.877 392.766 174.792 386.258 159.183 374.242C156.246 371.982 153.438 369.53 150.759 366.883C132.557 348.885 123.166 324.498 124.747 298.84C125.952 279.315 133.773 260.682 146.811 246.084C164.056 226.763 188.186 216.038 213.983 216.038C218.523 216.038 223.019 216.382 227.43 217.027V264.362C223.245 262.985 218.781 262.221 214.123 262.221C190.553 262.221 171.49 281.445 171.834 305.037C172.06 320.13 180.3 333.319 192.457 340.591C198.169 344.012 204.742 346.132 211.756 346.508C217.253 346.809 222.525 346.045 227.419 344.432C244.255 338.87 256.401 323.056 256.401 304.402L256.455 234.627V107.244H303.068C303.111 111.859 303.584 116.367 304.456 120.735C307.974 138.41 317.935 153.75 331.802 164.207C343.894 173.329 358.955 178.73 375.274 178.73C375.274 178.73 375.425 178.73 375.403 178.73V191.284H375.425Z'
        fill='#FFC600'
      />
    </svg>
  )
}
export const Ig = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={20}
      fill='none'
      viewBox='0 0 500 500'
      {...props}>
      <path
        d='M245.12 427.734C350.829 427.734 436.523 342.04 436.523 236.33C436.523 130.621 350.829 44.9272 245.12 44.9272C139.41 44.9272 53.7163 130.621 53.7163 236.33C53.7163 342.04 139.41 427.734 245.12 427.734Z'
        fill='white'
      />
      <path
        d='M297.853 249.999C297.853 276.431 276.431 297.853 249.999 297.853C223.568 297.853 202.146 276.431 202.146 249.999C202.146 223.568 223.568 202.146 249.999 202.146C276.431 202.146 297.853 223.568 297.853 249.999Z'
        fill='#FFC600'
      />
      <path
        d='M361.914 165.339C359.614 159.109 355.943 153.462 351.17 148.83C346.538 144.058 340.902 140.386 334.661 138.087C329.608 136.122 322.007 133.779 308.003 133.142C292.865 132.45 288.319 132.299 249.989 132.299C211.659 132.299 207.113 132.44 191.975 133.131C177.982 133.768 170.381 136.111 165.317 138.076C159.076 140.376 153.44 144.047 148.808 148.819C144.036 153.451 140.365 159.087 138.065 165.328C136.1 170.381 133.757 177.993 133.12 191.987C132.429 207.124 132.277 211.659 132.277 250C132.277 288.341 132.429 292.866 133.12 308.014C133.757 322.007 136.1 329.609 138.065 334.673C140.365 340.913 144.036 346.55 148.808 351.182C153.44 355.954 159.076 359.625 165.317 361.925C170.37 363.89 177.982 366.233 191.975 366.87C207.113 367.561 211.648 367.701 249.978 367.701C288.309 367.701 292.854 367.561 307.992 366.87C321.985 366.233 329.587 363.89 334.651 361.925C347.175 357.099 357.066 347.197 361.892 334.683C363.857 329.63 366.2 322.029 366.837 308.025C367.528 292.887 367.669 288.341 367.669 250.011C367.669 211.681 367.528 207.135 366.837 191.997C366.2 178.004 363.857 170.403 361.892 165.339H361.914ZM250 323.724C209.283 323.724 176.276 290.728 176.276 250.011C176.276 209.295 209.283 176.298 250 176.298C290.716 176.298 323.713 209.295 323.713 250.011C323.713 290.728 290.706 323.724 250 323.724ZM326.628 190.605C317.116 190.605 309.396 182.895 309.396 173.372C309.396 163.849 317.105 156.14 326.628 156.14C336.151 156.14 343.861 163.849 343.861 173.372C343.861 182.885 336.151 190.605 326.628 190.605Z'
        fill='#FFC600'
      />
      <path
        d='M250 0C111.946 0 0 111.946 0 250C0 388.054 111.946 500 250 500C388.054 500 500 388.054 500 250C500 111.946 388.054 0 250 0ZM392.686 309.191C391.995 324.469 389.566 334.91 386.013 344.033C378.552 363.317 363.307 378.563 344.033 386.013C334.91 389.566 324.469 391.984 309.191 392.686C293.88 393.388 288.989 393.55 250 393.55C211.011 393.55 206.12 393.377 190.809 392.686C175.531 391.984 165.09 389.566 155.967 386.013C146.379 382.407 137.708 376.76 130.539 369.45C123.24 362.281 117.593 353.6 113.987 344.023C110.434 334.899 108.005 324.458 107.314 309.18C106.612 293.869 106.439 288.978 106.439 249.989C106.439 211 106.601 206.109 107.303 190.799C107.994 175.52 110.424 165.079 113.976 155.956C117.582 146.379 123.229 137.698 130.539 130.528C137.708 123.218 146.389 117.571 155.967 113.965C165.101 110.413 175.531 107.994 190.809 107.292C206.12 106.591 211.011 106.429 250 106.429C288.989 106.429 293.88 106.601 309.191 107.303C324.469 107.994 334.91 110.424 344.033 113.965C353.611 117.571 362.292 123.218 369.461 130.528C376.76 137.698 382.418 146.379 386.013 155.956C389.566 165.09 391.995 175.52 392.686 190.799C393.388 206.109 393.55 211 393.55 249.989C393.55 288.978 393.388 293.869 392.686 309.18V309.191Z'
        fill='#FFC600'
      />
    </svg>
  )
}
export const Facebook = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={20}
      fill='none'
      viewBox='0 0 500 500'
      {...props}>
      <path
        d='M250 500C388.071 500 500 388.071 500 250C500 111.929 388.071 0 250 0C111.929 0 0 111.929 0 250C0 388.071 111.929 500 250 500Z'
        fill='#FFC600'
      />
      <path
        d='M312.848 259.79H268.24V423.222H200.651V259.79H168.503V202.357H200.651V165.191C200.651 138.608 213.273 96.9912 268.84 96.9912L318.907 97.2057V152.955H282.576C276.614 152.955 268.24 155.936 268.24 168.611V202.411H318.746L312.837 259.79H312.848Z'
        fill='white'
      />
    </svg>
  )
}
export const Linkedin = (props) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={20}
      height={20}
      fill='none'
      viewBox='0 0 500 500'
      {...props}>
      <path
        fill='#FFC600'
        d='M250 500c138.071 0 250-111.929 250-250S388.071 0 250 0 0 111.929 0 250s111.929 250 250 250Z'
      />
      <path
        fill='#fff'
        d='M399.378 270.122v103.075h-59.754V277.03c0-24.146-8.63-40.632-30.269-40.632-16.507 0-26.32 11.105-30.657 21.855-1.571 3.842-1.98 9.179-1.98 14.559v100.375h-59.764s.807-162.872 0-179.723h59.764v25.47c-.118.205-.29.398-.398.592h.398v-.592c7.941-12.224 22.102-29.688 53.857-29.688 39.319 0 68.792 25.685 68.792 80.876h.011ZM154.446 106.82c-20.445 0-33.821 13.418-33.821 31.044s12.988 31.066 33.035 31.066h.388c20.843 0 33.799-13.806 33.799-31.066-.398-17.626-12.956-31.044-33.412-31.044h.011Zm-30.27 266.367h59.743V193.453h-59.743v179.734Z'
      />
    </svg>
  )
}

import React from 'react'

export const SignOutIcon = () => {
  return (
    <svg
      width='153'
      height='153'
      viewBox='0 0 127 127'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <circle
        cx='63.1623'
        cy='63.1623'
        r='63.1623'
        fill='#D31E4A'
        fillOpacity='0.5'
      />
      <circle cx='63.1622' cy='63.1622' r='48.7626' fill='#D31E4A' />
      <g clipPath='url(#clip0_2329_604)'>
        <path
          d='M70.6244 40.7634V45.7509C75.3783 47.4317 79.385 50.7389 81.9363 55.0881C84.4876 59.4373 85.4193 64.5484 84.5666 69.518C83.7139 74.4877 81.1319 78.9959 77.2768 82.2459C73.4216 85.4959 68.5417 87.2785 63.4994 87.2785C58.4572 87.2785 53.5772 85.4959 49.7221 82.2459C45.867 78.9959 43.2849 74.4877 42.4323 69.518C41.5796 64.5484 42.5113 59.4373 45.0626 55.0881C47.6139 50.7389 51.6205 47.4317 56.3744 45.7509V40.7634C50.3078 42.4832 45.0678 46.3391 41.6215 51.6197C38.1752 56.9003 36.7551 63.2492 37.6232 69.4948C38.4913 75.7405 41.5891 81.4614 46.345 85.6019C51.1008 89.7424 57.1937 92.0231 63.4994 92.0231C69.8051 92.0231 75.8981 89.7424 80.6539 85.6019C85.4097 81.4614 88.5075 75.7405 89.3757 69.4948C90.2438 63.2492 88.8237 56.9003 85.3774 51.6197C81.931 46.3391 76.6911 42.4832 70.6244 40.7634Z'
          fill='white'
        />
        <path d='M65.8743 35H61.1243V54H65.8743V35Z' fill='white' />
      </g>
      <defs>
        <clipPath id='clip0_2329_604'>
          <rect
            width='57'
            height='57'
            fill='white'
            transform='translate(35 35)'
          />
        </clipPath>
      </defs>
    </svg>
  )
}

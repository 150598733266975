// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bank-selector_container {
  display: flex;
  gap: 1rem;
}

.select-wrapper input.select-dropdown:focus {
  border-bottom: 1px solid #29348f;
  font-family: 'Kufam';
}
.select-wrapper input.select-dropdown {
  border-bottom: 1px solid #29348f;
  font-family: 'Kufam';
}
`, "",{"version":3,"sources":["webpack://./src/components/imputs/banks/styles.banks.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,gCAAgC;EAChC,oBAAoB;AACtB;AACA;EACE,gCAAgC;EAChC,oBAAoB;AACtB","sourcesContent":[".bank-selector_container {\n  display: flex;\n  gap: 1rem;\n}\n\n.select-wrapper input.select-dropdown:focus {\n  border-bottom: 1px solid #29348f;\n  font-family: 'Kufam';\n}\n.select-wrapper input.select-dropdown {\n  border-bottom: 1px solid #29348f;\n  font-family: 'Kufam';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

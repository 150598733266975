import React, { memo } from "react";

function RestroomIcon({ color = "#29348F", style = {} }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      style={style}
    >
      <path
        d="M5.34083 3.29761C3.34071 3.29761 3.31321 3.29761 1.6494 3.29761C0.758347 3.29761 0.0298732 4.02251 0.0253935 4.91346L8.7518e-06 9.95516C-0.00188509 10.3342 0.303825 10.6429 0.682775 10.6449C0.683941 10.6449 0.685143 10.6449 0.686345 10.6449C1.06377 10.6449 1.37057 10.3399 1.37253 9.96211L1.39788 4.92041C1.39788 4.91983 1.39788 4.91928 1.39788 4.9187C1.39876 4.84386 1.45994 4.78373 1.53479 4.78416C1.60963 4.7846 1.67009 4.84546 1.67009 4.9203L1.67038 16.4566C1.67038 16.9114 2.03909 17.2801 2.49391 17.2801C2.94872 17.2801 3.31744 16.9114 3.31744 16.4566V9.87427H3.67301V16.4566C3.67301 16.9114 4.04169 17.2801 4.4965 17.2801C4.95131 17.2801 5.31999 16.9114 5.31999 16.4566C5.31999 5.57087 5.30543 11.7961 5.30506 4.93036C5.30506 4.85311 5.36603 4.78966 5.44324 4.78664C5.52045 4.78362 5.5863 4.84204 5.59227 4.91903V4.9191L5.58761 9.95796C5.58724 10.3369 5.89423 10.6445 6.27322 10.6449C6.27347 10.6449 6.27362 10.6449 6.27387 10.6449C6.65253 10.6449 6.95977 10.338 6.9601 9.95927L6.96476 4.91761C6.96483 4.91619 6.96483 4.91488 6.9648 4.91346C6.96032 4.02251 6.23181 3.29761 5.34083 3.29761Z"
        fill={color}
      />
      <path
        d="M3.49509 2.84419C4.28049 2.84419 4.91719 2.20749 4.91719 1.42209C4.91719 0.636693 4.28049 0 3.49509 0C2.70969 0 2.073 0.636693 2.073 1.42209C2.073 2.20749 2.70969 2.84419 3.49509 2.84419Z"
        fill={color}
      />
      <path
        d="M18.6085 8.8895C18.5959 8.85359 17.348 5.29422 17.1516 4.72283C16.8355 3.80311 16.3056 3.33679 15.5765 3.33679C14.5558 3.33679 14.2224 3.33679 13.2036 3.33679C12.4745 3.33679 11.9445 3.80315 11.6285 4.72283C11.4321 5.29419 10.1842 8.85363 10.1716 8.8895C10.0471 9.24453 10.234 9.63331 10.5891 9.75779C10.9445 9.88235 11.333 9.69497 11.4574 9.34035C11.4977 9.22548 12.2485 7.13937 12.6673 5.93911C12.6235 6.29741 12.7498 5.55386 11.6936 11.0071C11.6482 11.2413 11.8287 11.458 12.0651 11.458C12.2118 11.458 12.3878 11.458 12.5849 11.458V16.3994C12.5849 16.8508 12.9509 17.2168 13.4024 17.2168C13.8539 17.2168 14.2199 16.8509 14.2199 16.3994V11.4579C14.3374 11.4579 14.4553 11.4579 14.5729 11.4579V16.3994C14.5729 16.8508 14.9388 17.2168 15.3903 17.2168C15.8418 17.2168 16.2078 16.8509 16.2078 16.3994V11.4579C16.4049 11.4579 16.581 11.4579 16.7277 11.4579C16.9652 11.4579 17.1445 11.2411 17.0992 11.007C16.0526 5.60343 16.1679 6.23098 16.1322 5.93904C16.5544 7.14859 17.2832 9.22719 17.3228 9.34027C17.4474 9.69559 17.8364 9.88213 18.1911 9.75772C18.5461 9.63324 18.733 9.24453 18.6085 8.8895Z"
        fill={color}
      />
      <path
        d="M14.3963 2.8869C15.176 2.8869 15.8082 2.25478 15.8082 1.47501C15.8082 0.695238 15.176 0.0631104 14.3963 0.0631104C13.6165 0.0631104 12.9844 0.695238 12.9844 1.47501C12.9844 2.25478 13.6165 2.8869 14.3963 2.8869Z"
        fill={color}
      />
      <path
        d="M8.73208 0.91626C8.44185 0.91626 8.20654 1.15153 8.20654 1.4418V15.838C8.20654 16.1282 8.44182 16.3635 8.73208 16.3635C9.02235 16.3635 9.25763 16.1283 9.25763 15.838V1.4418C9.25763 1.15153 9.02232 0.91626 8.73208 0.91626Z"
        fill={color}
      />
    </svg>
  );
}

export default memo(RestroomIcon);

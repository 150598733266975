export const tickets = [
  {
    id: 1,
    title: 'Sol y Mar',
    status: 'onboard',
    logoUrl: '/assets/globalExpress.svg',
    date: '11 Marzo 2024',
    startTime: '10:00 AM',
    endTime: '2:00 PM',
    startLocation: { short: 'CCS', long: 'La Bandera' },
    endLocation: { short: 'Val', long: 'Big Low' },
    availability: '100',
    services: [
      { path: 'wifi', text: 'Wifi' },
      { path: 'toilet', text: 'Baño' },
      { path: 'stairs', text: '2 Pisos' },
      { path: 'bag', text: '32Ks' },
      { path: 'air', text: 'aire' },
    ],
    passengers: [
      {
        name: 'González, María',
        // luggage: '32kg',
        // seat: 'Piso 1 / V-15',
        age: 29,
      },
      {
        name: 'González, Rafael',
        // luggage: 'N/A',
        // seat: 'N/A',
        age: 2,
      },
      {
        name: 'González, Orlando',
        // luggage: '32kg',
        // seat: 'Piso 1 / V-15',
        age: 30,
      },
    ],
  },
  {
    id: 2,
    title: 'Sol y Mar',
    status: 'active',
    logoUrl: '/assets/globalExpress.svg',
    date: '10 Marzo 2024',
    startTime: '10:00 AM',
    endTime: '2:00 PM',
    startLocation: { short: 'CCS', long: 'La Bandera' },
    endLocation: { short: 'Val', long: 'Big Low' },
    availability: '100',
    services: [
      { path: 'wifi', text: 'Wifi' },
      { path: 'toilet', text: 'Baño' },
      { path: 'stairs', text: '2 Pisos' },
      { path: 'bag', text: '32Ks' },
      { path: 'air', text: 'aire' },
    ],
    passengers: [
      {
        name: 'González, María',
        // luggage: '32kg',
        // seat: 'Piso 1 / V-15',
        age: 29,
      },
      {
        name: 'González, Rafael',
        // luggage: 'N/A',
        // seat: 'N/A',
        age: 2,
      },
      {
        name: 'González, Orlando',
        // luggage: '32kg',
        // seat: 'Piso 1 / V-15',
        age: 30,
      },
    ],
  },
  {
    id: 3,
    title: 'Sol y Mar',
    status: 'expired',
    logoUrl: '/assets/globalExpress.svg',
    date: '09 Marzo 2024',
    startTime: '10:00 AM',
    endTime: '2:00 PM',
    startLocation: { short: 'CCS', long: 'La Bandera' },
    endLocation: { short: 'Val', long: 'Big Low' },
    availability: '100',
    services: [
      { path: 'wifi', text: 'Wifi' },
      { path: 'toilet', text: 'Baño' },
      { path: 'stairs', text: '2 Pisos' },
      { path: 'bag', text: '32Ks' },
      { path: 'air', text: 'aire' },
    ],
    passengers: [
      {
        name: 'González, María',
        // luggage: '32kg',
        // seat: 'Piso 1 / V-15',
        age: 29,
      },
      {
        name: 'González, Rafael',
        // luggage: 'N/A',
        // seat: 'N/A',
        age: 2,
      },
      {
        name: 'González, Orlando',
        // luggage: '32kg',
        // seat: 'Piso 1 / V-15',
        age: 30,
      },
    ],
  },
  {
    id: 4,
    title: 'Sol y Mar',
    status: 'reserved',
    logoUrl: '/assets/globalExpress.svg',
    date: '09 Marzo 2024',
    startTime: '10:00 AM',
    endTime: '2:00 PM',
    startLocation: { short: 'CCS', long: 'La Bandera' },
    endLocation: { short: 'Val', long: 'Big Low' },
    availability: '100',
    services: [
      { path: 'wifi', text: 'Wifi' },
      { path: 'toilet', text: 'Baño' },
      { path: 'stairs', text: '2 Pisos' },
      { path: 'bag', text: '32Ks' },
      { path: 'air', text: 'aire' },
    ],
    passengers: [
      {
        name: 'González, María',
        // luggage: '32kg',
        // seat: 'Piso 1 / V-15',
        age: 29,
      },
      {
        name: 'González, Rafael',
        // luggage: 'N/A',
        // seat: 'N/A',
        age: 2,
      },
      {
        name: 'González, Orlando',
        // luggage: '32kg',
        // seat: 'Piso 1 / V-15',
        age: 30,
      },
    ],
  },
  {
    id: 5,
    title: 'Sol y Mar',
    status: 'toConfirm',
    logoUrl: '/assets/globalExpress.svg',
    date: '09 Marzo 2024',
    startTime: '10:00 AM',
    endTime: '2:00 PM',
    startLocation: { short: 'CCS', long: 'La Bandera' },
    endLocation: { short: 'Val', long: 'Big Low' },
    availability: '100',
    services: [
      { path: 'wifi', text: 'Wifi' },
      { path: 'toilet', text: 'Baño' },
      { path: 'stairs', text: '2 Pisos' },
      { path: 'bag', text: '32Ks' },
      { path: 'air', text: 'aire' },
    ],
    passengers: [
      {
        name: 'González, María',
        // luggage: '32kg',
        // seat: 'Piso 1 / V-15',
        age: 29,
      },
      {
        name: 'González, Rafael',
        // luggage: 'N/A',
        // seat: 'N/A',
        age: 2,
      },
      {
        name: 'González, Orlando',
        // luggage: '32kg',
        // seat: 'Piso 1 / V-15',
        age: 30,
      },
    ],
  },
  {
    id: 6,
    title: 'Sol y Mar',
    status: 'pending',
    logoUrl: '/assets/globalExpress.svg',
    date: '07 Marzo 2024',
    startTime: '10:00 AM',
    endTime: '2:00 PM',
    startLocation: { short: 'CCS', long: 'La Bandera' },
    endLocation: { short: 'Val', long: 'Big Low' },
    availability: '100',
    services: [
      { path: 'wifi', text: 'Wifi' },
      { path: 'toilet', text: 'Baño' },
      { path: 'stairs', text: '2 Pisos' },
      { path: 'bag', text: '32Ks' },
      { path: 'air', text: 'aire' },
    ],
    passengers: [
      {
        name: 'González, María',
        // luggage: '32kg',
        // seat: 'Piso 1 / V-15',
        age: 29,
      },
      {
        name: 'González, Rafael',
        // luggage: 'N/A',
        // seat: 'N/A',
        age: 2,
      },
      {
        name: 'González, Orlando',
        // luggage: '32kg',
        // seat: 'Piso 1 / V-15',
        age: 30,
      },
    ],
  },
]

// BUY TICKETS
export const buyTickets = [
  {
    id: 1,
    title: 'Sol y Mar',
    line: 'Occidente',
    price: 1230,
    priceRef: 25,
    date: '06 febrero 2024',
    startTime: '10:00 AM',
    endTime: '2:00 PM',
    startLocation: {
      short: 'CCS',
      long: 'La Bandera',
      edo: 'Caracas',
    },
    endLocation: { short: 'Mer', long: 'Big Low', edo: 'Merida' },
    availability: 30,
    services: [
      { path: 'wifi', text: 'Wifi' },
      { path: 'toilet', text: 'Baño' },
      { path: 'stairs', text: '2 Pisos' },
      { path: 'bag', text: '32Ks' },
      { path: 'bed', text: 'Buscama' },
      { path: 'air', text: 'Aire' },
    ],
  },
  {
    id: 2,
    title: 'Sol y Mar',
    line: 'Flamingo',
    price: 2050,
    priceRef: 30,
    date: '06 febrero 2024',
    startTime: '9:00 PM',
    endTime: '5:00 AM',
    startLocation: {
      short: 'CCS',
      long: 'La Bandera',
      edo: 'Caracas',
    },
    endLocation: { short: 'Val', long: 'Big Low', edo: 'Valencia' },
    availability: 50,
    services: [
      { path: 'wifi', text: 'Wifi' },
      // { path: 'toilet', text: 'Baño' },
      // { path: 'stairs', text: '2 Pisos' },
      // { path: 'bag', text: '32Ks' },
      { path: 'air', text: 'aire' },
    ],
  },
  {
    id: 3,
    title: 'Sol y Mar',
    line: 'Los Andes',
    price: 1500,
    priceRef: 26,
    date: '06 febrero 2024',
    startTime: '5:00 AM',
    endTime: '1:00 PM',
    startLocation: {
      short: 'CCS',
      long: 'La Bandera',
      edo: 'Caracas',
    },
    endLocation: { short: 'Val', long: 'Big Low', edo: 'Valencia' },
    availability: 32,
    services: [
      // { path: 'wifi', text: 'Wifi' },
      { path: 'toilet', text: 'Baño' },
      { path: 'stairs', text: '2 Pisos' },
      // { path: 'bag', text: '32Ks' },
      { path: 'air', text: 'aire' },
    ],
  },
  {
    id: 4,
    title: 'Sol y Mar',
    line: 'Occidente',
    price: 1230,
    priceRef: 25,
    date: '02 febrero 2024',
    startTime: '10:00 AM',
    endTime: '2:00 PM',
    startLocation: {
      short: 'CCS',
      long: 'La Bandera',
      edo: 'Caracas',
    },
    endLocation: { short: 'Mir', long: 'Big Low', edo: 'Miranda' },
    availability: 100,
    services: [
      // { path: 'wifi', text: 'Wifi' },
      // { path: 'toilet', text: 'Baño' },
      { path: 'stairs', text: '1 Piso' },
      // { path: 'bag', text: '32Ks' },
      // { path: 'air', text: 'aire' },
    ],
  },
  {
    id: 5,
    title: 'Sol y Mar',
    line: 'Flamingo',
    price: 900,
    priceRef: 15,
    date: '02 febrero 2024',
    startTime: '10:00 PM',
    endTime: '2:00 AM',
    startLocation: {
      short: 'CCS',
      long: 'La Bandera',
      edo: 'Caracas',
    },
    endLocation: { short: 'Val', long: 'Big Low', edo: 'Valencia' },
    availability: 50,
    services: [
      // { path: 'wifi', text: 'Wifi' },
      { path: 'toilet', text: 'Baño' },
      // { path: 'stairs', text: '2 Pisos' },
      // { path: 'bag', text: '32Ks' },
      { path: 'air', text: 'aire' },
    ],
  },
  {
    id: 6,
    title: 'Sol y Mar',
    line: 'Los Andes',
    price: 5020,
    priceRef: 50,
    date: '03 marzo 2024',
    startTime: '9:00 AM',
    endTime: '9:00 PM',
    startLocation: {
      short: 'Mer',
      long: 'La Bandera',
      edo: 'Merida',
    },
    endLocation: { short: 'Val', long: 'Big Low', edo: 'Valencia' },
    availability: 30,
    services: [
      { path: 'wifi', text: 'Wifi' },
      { path: 'toilet', text: 'Baño' },
      { path: 'stairs', text: '1 Piso' },
      // { path: 'bag', text: '32Ks' },
      { path: 'air', text: 'aire' },
    ],
  },

  {
    id: 7,
    title: 'Sol y Mar',
    line: 'Occidente',
    price: 1230,
    priceRef: 25,
    date: '06 febrero 2024',
    startTime: '10:00 AM',
    endTime: '2:00 PM',
    startLocation: {
      short: 'CCS',
      long: 'La Bandera',
      edo: 'Caracas',
    },
    endLocation: { short: 'Val', long: 'Big Low', edo: 'Valencia' },
    availability: 30,
    services: [
      { path: 'wifi', text: 'Wifi' },
      { path: 'toilet', text: 'Baño' },
      { path: 'stairs', text: '2 Pisos' },
      { path: 'bag', text: '32Ks' },
      { path: 'bed', text: 'Buscama' },
      { path: 'air', text: 'Aire' },
    ],
  },
]

import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { Box, Button } from '@mui/material'

import { busiAPi } from '../../utils/urls'

import { EditIcon } from '../../components/icons/Edit'
import { ButtonBlue } from '../../components/buttons'
import { useCountDown } from '../../hooks/useCountDown'
import Forms2Fa from '../../components/imputs/forms-2fa'

import { useStore, usePersistStore } from '../../store'

import axios from 'axios'
import './styles.css'
import { EditPhoneAlert } from '../../components/bottom-panel/EditPhone'

export function ValidationCodePage() {
  const navigate = useNavigate()
  const { userData, setUserData } = usePersistStore()
  const { password } = useStore()
  const { state } = useLocation()
  // const { state } = useLocation()

  const { onClickReset, timer } = useCountDown()

  const [showEditPhone, setShowEditPhone] = useState(false)
  const [disableButton, setDisableButton] = useState(true)
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    const allValues = Object.values(errors)

    if (allValues.length === 0) return
    const areRequired = allValues.every((item) => item.type === 'required')

    if (areRequired) {
      setError('Este campo es requerido')
    } else {
      setError(undefined)
    }
  }, [errors])

  useEffect(() => {
    onClickReset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // console.log({ timer })
    if (timer === '00:01') {
      setDisableButton(false)
    }
  }, [timer])

  function goBack() {
    navigate('/login', {
      state: { wantToBuy: state?.wantToBuy },
    })
    // navigate(state?.previousPath)
  }

  const reSentCode = async () => {
    onClickReset()

    try {
      const { data } = await axios.post(
        `${busiAPi}/auth/passengers/resend-code`,
        { email: userData.email },
      )

      // console.log({ data })
      if (data.success) {
        setDisableButton(true)
      }
    } catch (error) {
      console.log({ error })
      const mainError = error?.response?.data?.error

      if (mainError?.errorCode === 1002) {
        setError('El código ingresado es inválido')
        return
      }

      setError('Lo sentimos, algo salio mal, intente más tarde')
    }
  }

  const onSubmit = async (data) => {
    const otpCode = `${data['code-1']}${data['code-2']}${data['code-3']}${data['code-4']}${data['code-5']}${data['code-6']}`
    setLoading(true)

    try {
      const { data: info } = await axios.put(
        `${busiAPi}/auth/passengers/confirm`,
        { email: userData.email, otpCode, password },
      )

      if (info.success) {
        localStorage.setItem('token', info.data.token)
        localStorage.setItem('refreshToken', info.data.refreshToken)
        navigate('/tutorial', {
          state: { wantToBuy: state?.wantToBuy },
        })
      }
    } catch (error) {
      setLoading(false)
      const mainError = error?.response?.data?.error
      console.log({ error, mainError })

      if (mainError?.errorCode === 1002) {
        setError('El código ingresado es inválido')
        return
      }

      setError('Lo sentimos, algo salio mal, intente más tarde')
    }
  }

  return (
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
      }}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='pages-user-validation'
        style={{ width: '600px' }}>
        <div className='pages-user-validation-top' onClick={goBack}>
          <i className='material-icons back'>arrow_back</i>
        </div>

        <div className='pages-user-validation-middle'>
          {/* <h1 className='pages-user-validation-middle-title'>Reestablece tu contraseña</h1> */}

          <p className='pages-reset_subtitle'>
            Ingresa el <b>código de verificación</b> de 6 dígitos enviado al{' '}
            {userData.phone.includes('58') && (
              <>
                <span>
                  0
                  {userData.phone.includes('+58-')
                    ? userData.phone.slice(4)
                    : userData.phone.slice(3)}
                </span>{' '}
                o a{' '}
              </>
            )}
            <span>{userData.email}</span>
          </p>

          <>
            <div style={{ marginTop: '0.5rem' }}>
              <>
                <Forms2Fa
                  registerValidation={register}
                  setValue={setValue}
                  error={error}
                />
                <br />
                <ButtonBlue
                  onClick={() => reSentCode()}
                  disabled={disableButton}
                  buttonStyle={{
                    width: '109px',
                    minWidth: '109px',
                    height: '26px',
                    borderRadius: '5px',
                  }}
                  spanStyle={{
                    fontSize: '8px',
                    fontWeight: '600',
                    height: '11px',
                  }}>
                  Reenviar código
                </ButtonBlue>
              </>
            </div>

            <Box style={{ marginTop: '30px' }}>
              <p className='pages-reset-ask_code_time'>{timer}</p>
              <p className='pages-reset-ask_code_text'>
                Puedes volver a pedir otro código en 30 min.
              </p>
            </Box>

            <Box style={{ marginTop: '5px' }}>
              <Button
                variant='outlined'
                color='pink'
                style={{ gap: '9px' }}
                onClick={() => setShowEditPhone(true)}>
                <EditIcon />
                <p
                  style={{
                    color: '#B01F8C',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '18.2px',
                    textAlign: 'left',
                    margin: 0,
                    textTransform: 'none',
                    height: '14px',
                  }}>
                  Editar número de teléfono
                </p>
              </Button>
            </Box>
          </>
        </div>

        <div style={{ margin: 'auto' }}>
          <ButtonBlue type='submit' disabled={loading}>
            Finalizar
          </ButtonBlue>
        </div>
      </form>

      <EditPhoneAlert
        show={showEditPhone}
        setShowEditPhone={setShowEditPhone}
        userData={userData}
        setUserData={setUserData}
      />
    </Box>
  )
}

import { InputControlMaterial } from '../index'
import { useController } from 'react-hook-form'

import './styles.validation.css'

export function ValidationCode({
  label = 'Código de verificación',
  error = '',
  control,
  nameId = 'refNumber',
  minValueLength = 4,
  maxValueLength = 9,
  type = 'number',
}) {
  const { field } = useController({
    name: nameId,
    control,
    rules: {
      required: 'Este campo es requerido',
      pattern: {
        value: /^[0-9]\d*(\d+)?$/i,
        message: 'Solo deben ser numeros',
      },
      minLength: {
        value: minValueLength,
        message: `Minimo ${minValueLength}`,
      },
      maxLength: {
        value: maxValueLength,
        message: `Maximo ${maxValueLength}`,
      },
    },
  })

  const handleChange = (e) => {
    // const regex = /^[1-9]\d*(\d+)?$/i
    const value = e.target.value

    const discard = !['e', 'E', '-', '.', ','].some((char) =>
      value.includes(char),
    )

    if (discard && type === 'number') {
      const parse = parseInt(value)

      if (!Number.isNaN(parse)) {
        field.onChange(parse)
      } else if (Number.isNaN(parse) && value === '') {
        field.onChange('')
      }
    } else {
      field.onChange(value)
    }
  }

  const handePress = (e) => {
    var key = e.keyCode
    // Only allow numbers to be entered

    if (key !== 8) {
      if (key < 48 || key > 57) {
        e.preventDefault()
      }
    }
  }

  return (
    <div className='input-field'>
      <InputControlMaterial
        field={field}
        label={label}
        type={type}
        inputmode='decimal'
        onChange={handleChange}
        onKeyDown={handePress}
      />

      {error && <p className='error_message'>{error}</p>}
    </div>
  )
}

export function ValidationDescountAmount({
  label = 'Código de verificación',
  error = '',
  control,
  nameId = 'refNumber',
  minValueLength = 4,
  maxValueLength = 9,
  type = 'number',
}) {
  const { field } = useController({
    name: nameId,
    control,
    rules: {
      required: 'Este campo es requerido',
      pattern: {
        value: /^[0-9]+([,]|[.][0-9]+)?$/i,
        message: 'Solo se acepta punto para indicar decimales',
      },
      minLength: {
        value: minValueLength,
        message: `Minimo ${minValueLength}`,
      },
      maxLength: {
        value: maxValueLength,
        message: `Maximo ${maxValueLength}`,
      },
    },
  })

  const handleChange = (e) => {
    // const regex = /^[1-9]\d*(\d+)?$/i
    const value = e.target.value

    const discard = !['e', 'E', '-', '.', ','].some((char) =>
      value.includes(char),
    )

    if (discard && type === 'number') {
      const parse = parseInt(value)

      if (!Number.isNaN(parse)) {
        field.onChange(parse)
      } else if (Number.isNaN(parse) && value === '') {
        field.onChange('')
      }
    } else {
      field.onChange(value)
    }
  }

  // const handePress = (e) => {
  //   var key = e.keyCode
  //   // Only allow numbers to be entered

  //   if (key !== 8) {
  //     if (key < 48 || key > 57) {
  //       e.preventDefault()
  //     }
  //   }
  // }

  return (
    <div className='input-field'>
      <InputControlMaterial
        field={field}
        label={label}
        type={type}
        inputmode='decimal'
        onChange={handleChange}
        // onKeyDown={handePress}
      />

      {error && <p className='error_message'>{error}</p>}
    </div>
  )
}

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import Snackbar from '@mui/material/Snackbar'
import Slide from '@mui/material/Slide'
import { Alert, IconButton } from '@mui/material'
import { Alert as AlertSheet } from '../../components/bottom-panel/Alert'

import { ButtonBlue } from '../../components/buttons'
import Forms2Fa from '../../components/imputs/forms-2fa'
import FormsPassword from '../../components/imputs/forms-password'

import { useCountDown } from '../../hooks/useCountDown'
import { busiAPi } from '../../utils/urls'
import { usePersistStore } from '../../store'
import axios from 'axios'

import './styles.reset.css'

export default function ResetPasswordPage() {
  const navigate = useNavigate()
  const { userData, setUserData } = usePersistStore()

  const [status, setStatus] = useState('code')
  const [error, setError] = useState()
  const [otp, setOtp] = useState()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const sendCode = async () => {
      try {
        const { data } = await axios.post(
          `${busiAPi}/auth/passengers/forgot-password`,
          { documentId: userData.ci },
        )

        if (data.success) {
          setOpen(true)
        }
      } catch (error) {
        console.log({ error })
        setError('Estamos teniendo problemas, intenta más tarde')
      }
    }

    sendCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleClose = () => setOpen(false)

  return status === 'code' ? (
    <>
      <EnterCode
        navigate={navigate}
        setStatus={setStatus}
        setOtp={setOtp}
        userData={userData}
        setUserData={setUserData}
        open={open}
        errorCode={error}
      />

      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        action={
          <IconButton aria-label='close' color='inherit' onClick={handleClose}>
            x
          </IconButton>
        }>
        <Alert
          onClose={handleClose}
          severity='success'
          variant='standard'
          sx={{ width: '100%' }}>
          Se envio tu codigo
        </Alert>
      </Snackbar>
    </>
  ) : (
    status === 'password' && (
      <ResetPassword
        otp={otp}
        setStatus={setStatus}
        userData={userData}
        navigate={navigate}
      />
    )
  )
}

const EnterCode = ({
  setOtp,
  errorCode = undefined,
  setStatus,
  userData,
  setUserData,
  navigate,
}) => {
  const { onClickReset, timer } = useCountDown()

  // const [showEditPhone, setShowEditPhone] = useState(false)

  const [error, setError] = useState()
  const [disableButton, setDisableButton] = useState(true)
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    // watch,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    onClickReset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const allValues = Object.values(errors)

    if (allValues.length === 0) return
    const areRequired = allValues.every((item) => item.type === 'required')

    if (areRequired) {
      setError('Este campo es requerido')
    } else {
      setError(undefined)
    }
  }, [errors])

  useEffect(() => {
    // console.log({ timer })
    if (timer === '00:01') {
      setDisableButton(false)
    }
  }, [timer])

  // FUNCTIONS

  const onSubmit = async (data) => {
    const otpCode = `${data['code-1']}${data['code-2']}${data['code-3']}${data['code-4']}${data['code-5']}${data['code-6']}`
    setLoading(true)

    if (otpCode) {
      setOtp(otpCode)
      setLoading(false)
      setStatus('password')
    }
  }

  const sentCode = async () => {
    onClickReset()
    try {
      const { data } = await axios.post(
        `${busiAPi}/auth/passengers/resend-code`,
        { email: userData.email },
      )

      if (data.success) {
        setDisableButton(true)
      }
    } catch (error) {
      console.log({ error })
      setError('Estamos teniendo problemas, intenta más tarde')
    }
  }

  function goBack() {
    navigate('/login')
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className='pages-reset_container'>
        <div className='pages-reset_top' onClick={goBack}>
          <i className='material-icons back'>arrow_back</i>
        </div>

        <div className='pages-reset_middle'>
          <h1 className='pages-reset_title'>Reestablece tu contraseña</h1>
          <p className='pages-reset_subtitle'>
            Ingresa el <b>código de verificación</b> de 6 dígitos enviado al{' '}
            {userData.phone.includes('58') && (
              <>
                <span>
                  0
                  {userData.phone.includes('+58-')
                    ? userData.phone.slice(4)
                    : userData.phone.slice(3)}
                </span>{' '}
                o a{' '}
              </>
            )}
            <span>{userData.email}</span>
          </p>

          <div style={{ marginTop: '0.5rem' }}>
            <Forms2Fa
              registerValidation={register}
              setValue={setValue}
              error={error || errorCode}></Forms2Fa>

            <br />

            <ButtonBlue
              onClick={() => sentCode()}
              buttonStyle={{
                width: '109px',
                minWidth: '109px',
                height: '26px',
                borderRadius: '5px',
              }}
              spanStyle={{
                fontSize: '8px',
                fontWeight: '600',
                height: '11px',
              }}
              disabled={disableButton}>
              Reenviar código
            </ButtonBlue>
          </div>

          <div>
            <p className='pages-reset-ask_code_time'>{timer}</p>
            <p className='pages-reset-ask_code_text'>
              Puedes volver a pedir otro código en 3 min.
            </p>
          </div>

          {/* <Box style={{ marginTop: '5px' }}>
            <Button
              variant='outlined'
              color='pink'
              style={{ gap: '9px' }}
              onClick={() => setShowEditPhone(true)}>
              <EditIcon />
              <p
                style={{
                  color: '#B01F8C',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '18.2px',
                  textAlign: 'left',
                  margin: 0,
                  textTransform: 'none',
                  height: '14px',
                }}>
                Editar número de teléfono
              </p>
            </Button>
          </Box> */}
        </div>

        <div style={{ margin: 'auto' }}>
          <ButtonBlue type='submit' disabled={loading}>
            Continuar
          </ButtonBlue>
        </div>
      </form>

      {/* <EditPhoneAlert
        show={showEditPhone}
        setShowEditPhone={setShowEditPhone}
        userData={userData}
        setUserData={setUserData}
      /> */}
    </>
  )
}

const ResetPassword = ({ setStatus, otp, navigate, userData }) => {
  const {
    register,
    handleSubmit,
    // watch,
    setError,
    formState: { errors },
  } = useForm()
  const [err, setErr] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)

  function goBack() {
    setStatus('code')
  }

  const onSubmit = async (data) => {
    const isEqual = data.password === data.confirm_password
    setLoading(true)

    if (!isEqual) {
      setError('confirm_password', {
        type: 'custom',
        message: 'Los campos deben ser iguales',
      })

      setLoading(false)
      return
    }

    try {
      const { data: info } = await axios.post(
        `${busiAPi}/auth/passengers/confirm-forgot-password`,
        { documentId: userData.ci, otpCode: otp, password: data.password },
      )

      if (info?.success) {
        setLoading(false)
        setShow(true)
        // navigate('/login')
      }
    } catch (error) {
      setLoading(false)
      console.log({ error })
      setErr('Estamos teniendo problemas, intenta más tarde')
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='pages-reset_container'>
      <div className='pages-reset_top' onClick={goBack}>
        <i className='material-icons back'>arrow_back</i>
      </div>

      <section className='pages-reset_middle'>
        <h1 className='pages-reset_title'>Ingresa tu nueva contraseña</h1>

        <FormsPassword
          registerValidation={{
            ...register('password', {
              required: 'Este campo es requerido',
              pattern: {
                message: 'Contraseña no valida',
                value:
                  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,30}$/,
              },
            }),
          }}
          showDetail={true}
          error={errors.password?.message}
        />

        <FormsPassword
          label='Confrmar contraseña'
          registerValidation={{
            ...register('confirm_password', {
              required: 'Este campo es requerido',
              pattern: {
                message: 'Contraseña no valida',
                value:
                  /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,30}$/,
              },
            }),
          }}
          error={errors.confirm_password?.message}
        />
        {err && <p className='error_message'>{err}</p>}
      </section>

      <div style={{ margin: 'auto' }}>
        <ButtonBlue type='submit' disabled={loading}>
          Continuar
        </ButtonBlue>
      </div>

      <AlertSheet
        isShow={show}
        setShow={setShow}
        type='success'
        title='¡Cambio de clave exitoso!'
        buttonText='Finalizar'
        onClickButton={() => navigate('/login')}>
        <p>Hemos guardado tu nueva contraseña</p>
      </AlertSheet>
    </form>
  )
}

import { useController } from 'react-hook-form'
import { useEffect, useRef } from 'react'
import { CalendarIcon } from '../../icons/Calendar'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { MobileDatePicker } from '@mui/x-date-pickers'
import { esES } from '@mui/x-date-pickers/locales'
import { Box, Divider, styled } from '@mui/material'

import dayjs from 'dayjs'

import 'dayjs/locale/es-mx'
import './style.calendar.css'

const CalendarPicker = styled(MobileDatePicker)({
  '.MuiInput-root::before': {
    borderBottom: '1px solid transparent',
  },
  '.MuiInput-root::after': {
    borderBottom: '2px solid transparent',
  },
  '.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
    borderBottom: '1px solid transparent',
  },
  '.MuiIconButton-root': {
    display: 'none',
  },
})

export const Calendar = ({
  setValue,
  max,
  min,
  control,
  containerStyle = {},
  iconPosition = 'right',
}) => {
  const { field } = useController({
    control,
    name: 'calendar',
    rules: { required: 'Este campo es obligatorio' },
  })
  const goPicker = useRef(null)

  const today = dayjs()
  const eightTeenYearsOld = dayjs().subtract(18, 'years')
  const fourYearsOld = dayjs().subtract(4, 'years')
  const sixtyYearsOld = dayjs().subtract(60, 'years')

  useEffect(() => {
    setValue('calendar', today)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box
      component='section'
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '15px',
        padding: '10px',
        height: '45px',
        borderRadius: '9px',
        background: '#e7e9f3',
        ...containerStyle,
      }}
      className='calendar_item_container'>
      {iconPosition === 'left' && (
        <Box
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
          className='icon-divider_container'>
          <CalendarIcon
            onClick={() => {
              goPicker.current.click()
            }}
          />

          <Divider
            orientation='vertical'
            style={{ borderColor: '#29348f', minHeight: '25px' }}
          />
        </Box>
      )}

      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale='es-mx'
        localeText={
          esES.components.MuiLocalizationProvider.defaultProps.localeText
        }>
        <CalendarPicker
          // onChange={field.onChange}
          // inputRef={field.ref}
          ref={goPicker}
          name={field.name}
          value={field.value ?? today}
          onBlur={field.onBlur}
          onChange={(newValue) => setValue('calendar', newValue)}
          format='DD/MM/YYYY'
          minDate={
            min === 'today'
              ? today
              : min === '18'
              ? eightTeenYearsOld
              : min === '60'
              ? sixtyYearsOld
              : min === '4'
              ? fourYearsOld
              : null
          }
          maxDate={
            max === 'today'
              ? today
              : max === '18'
              ? eightTeenYearsOld
              : max === '60'
              ? sixtyYearsOld
              : max === '4'
              ? fourYearsOld
              : null
          }
          localeText={
            esES.components.MuiLocalizationProvider.defaultProps.localeText
          }
          slotProps={{
            openPickerIcon: {
              color: 'primary',
            },
            textField: {
              fullWidth: true,
              variant: 'standard',
              InputLabelProps: {
                style: {
                  color: '#29348F',
                  fontFamily: 'Kufam',
                },
              },
              InputProps: {
                style: {
                  color: '#29348F',
                },
              },
            },
          }}
          InputAdornmentProps={{ display: 'none' }}
        />
      </LocalizationProvider>

      {iconPosition === 'right' && (
        <Box
          style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            gap: '5px',
          }}
          className='icon-divider_container'>
          <Divider
            orientation='vertical'
            style={{ borderColor: '#29348f', minHeight: '25px' }}
          />

          <CalendarIcon
            onClick={() => {
              goPicker.current.click()
            }}
          />
        </Box>
      )}
    </Box>
  )
}

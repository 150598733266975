import { Button } from '@mui/material'
import './styles.button.css'

export function ButtonBlue({
  onClick,
  buttonStyle = {},
  spanStyle = {},
  children = '',
  type = 'button',
  disabled = false,
  design = 'primary',
}) {
  const regularStyles = {
    width: '285px',
    height: '44px',
    borderRadius: '10px',
    border: disabled || design === 'red' ? 'none' : `1px solid #29348f`,
    background: disabled
      ? '#a4a1a1'
      : design === 'secondary'
      ? 'transparent'
      : design === 'red'
      ? '#e7194f'
      : '#29348f',
  }

  const spanlocalStyles = {
    color: design === 'secondary' ? '#29348f' : '#fff',
    fontSize: '12px',
    fontFamily: 'Kufam',
    textAlign: 'center',
    height: '18px',
    textTransform: 'capitalize',
  }

  const buttonstyle = () => {
    return { ...regularStyles, ...buttonStyle }
  }

  const spanstyle = () => {
    return { ...spanlocalStyles, ...spanStyle }
  }
  return (
    <>
      <Button
        type={type}
        variant={design === 'secondary' ? 'outlined' : 'text'}
        // className={`blue_buttom ${design} ${disabled ? 'disabled' : ''}`}
        style={buttonstyle()}
        disabled={disabled}
        onClick={onClick}>
        <span style={spanstyle()}>{children}</span>
      </Button>

      {/* <button
        type={type}
        className={`blue_buttom ${design} ${disabled ? 'disabled' : ''}`}
        style={buttonStyle}
        disabled={disabled}
        onClick={onClick}>
        {children}
      </button> */}
    </>
  )
}

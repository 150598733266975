import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BottomNavigation, BottomNavigationAction } from '@mui/material'
// import PersonIcon from '@mui/icons-material/Person'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import { TicketIcon } from '../icons/Ticket'
import { SearchIcon } from '../icons/Search'

import './styles.navbar.css'

export function NavMenu() {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const [value, setValue] = useState()

  useEffect(() => {
    const tabs = pathname.includes('profile')
      ? 2
      : pathname.includes('ticket') ||
        pathname.includes('all') ||
        pathname.includes('seat')
      ? 1
      : 0

    setValue(tabs)
    // setValue(pathname.includes('search') || pathname.includes('buy') ? 0 : 1)
  }, [pathname])

  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={(event, newValue) => {
        // setValue(newValue)
        navigate(
          newValue === 2
            ? '/main/profile'
            : newValue === 1
            ? '/main/ticket'
            : '/main/search',
        )
      }}
      style={{ background: 'transparent', height: '66px' }}>
      <BottomNavigationAction
        style={{ color: value === 0 ? '#FFC600' : '#FFC60080', gap: '3px' }}
        label='Buscador'
        icon={
          <SearchIcon
            width='21'
            height='21'
            fill={value === 0 ? '#FFC600' : '#FFC60080'}
          />
        }
      />

      <BottomNavigationAction
        style={{ color: value === 1 ? '#FFC600' : '#FFC60080', gap: '3px' }}
        label='Mis boletos'
        icon={<TicketIcon fill={value === 1 ? '#FFC600' : '#FFC60080'} />}
      />

      <BottomNavigationAction
        style={{ color: value === 2 ? '#FFC600' : '#FFC60080', gap: '3px' }}
        label='Perfil'
        icon={<PersonOutlineIcon color={value === 2 ? 'gold' : '#FFC60080'} />}
      />
    </BottomNavigation>
  )
}

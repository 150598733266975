import React, { useState } from 'react'
// import { Check } from '../../icons/Check'

import './styles.check.css'

export const CheckboxButton = ({
  label,
  id,
  registerValidation,
  setValue,
  check = false,
}) => {
  const [isChecked, setIsChecked] = useState(check)

  return (
    <div className={`checkbox_container ${isChecked ? 'check' : ''}`}>
      <label htmlFor={id}>{label}</label>

      <input
        {...registerValidation}
        style={{ opacity: 0, visibility: 'hidden', display: 'none' }}
        type='checkbox'
        id={id}
        onChange={(e) => {
          setIsChecked(!isChecked)
          setValue(id, e.target.checked)
        }}
      />
      {/* <Check fill={isChecked ? 'white' : '#29348F'} /> */}
    </div>
  )
}

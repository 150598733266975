import { useState } from 'react'
import { Clipboard } from '@capacitor/clipboard'

import Snackbar from '@mui/material/Snackbar'
import Slide from '@mui/material/Slide'

import { Alert, IconButton } from '@mui/material'

export const Copy = ({ fill = '#29348F', text = '' }) => {
  const [open, setOpen] = useState(false)

  const handleCopy = async () => {
    await Clipboard.write({
      string: text,
    })

    setOpen(true)
  }

  const handleClose = () => setOpen(false)

  return (
    <>
      <svg
        onClick={handleCopy}
        xmlns='http://www.w3.org/2000/svg'
        width='12'
        height='14'
        viewBox='0 0 12 14'
        fill='none'>
        <path
          d='M9.6 2.96683L6.5484 0H1.8C1.32261 0 0.864773 0.184374 0.527208 0.512563C0.189642 0.840752 0 1.28587 0 1.75V11.6667H9.6V2.96683ZM1.2 10.5V1.75C1.2 1.59529 1.26321 1.44692 1.37574 1.33752C1.48826 1.22812 1.64087 1.16667 1.8 1.16667H6V3.5H8.4V10.5H1.2ZM12 5.25V14H3V12.8333H10.8V4.08333L12 5.25Z'
          fill={fill}
        />
      </svg>

      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={handleClose}
        TransitionComponent={Slide}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        action={
          <IconButton aria-label='close' color='inherit' onClick={handleClose}>
            x
          </IconButton>
        }>
        <Alert
          onClose={handleClose}
          severity='success'
          variant='standard'
          sx={{ width: '100%' }}>
          {text} Copied
        </Alert>
      </Snackbar>
    </>
  )
}

import React from 'react'

export const SortIcon = ({ handleClick }) => {
  return (
    <svg
      onClick={() => handleClick()}
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='13'
      viewBox='0 0 17 13'
      fill='none'>
      <path
        d='M0.53125 2.61971H9.1811C9.41969 3.54023 10.2917 4.22483 11.3284 4.22483C12.3651 4.22483 13.2372 3.54023 13.4758 2.61971H16.4688C16.7621 2.61971 17 2.39257 17 2.11243C17 1.83229 16.7621 1.60515 16.4688 1.60515H13.4758C13.2372 0.684634 12.3651 0 11.3284 0C10.2917 0 9.41963 0.684634 9.18106 1.60515H0.53125C0.237867 1.60515 0 1.83229 0 2.11243C0 2.39257 0.237867 2.61971 0.53125 2.61971ZM11.3284 1.01455C11.9624 1.01455 12.4782 1.50706 12.4782 2.1124C12.4782 2.71777 11.9624 3.21027 11.3284 3.21027C10.6945 3.21027 10.1787 2.71777 10.1787 2.1124C10.1787 1.50706 10.6945 1.01455 11.3284 1.01455ZM0.53125 7.00728H3.52425C3.76284 7.9278 4.63486 8.6124 5.67159 8.6124C6.70833 8.6124 7.58034 7.9278 7.81894 7.00728H16.4688C16.7621 7.00728 17 6.78014 17 6.5C17 6.21986 16.7621 5.99272 16.4688 5.99272H7.8189C7.58031 5.0722 6.70829 4.38757 5.67156 4.38757C4.63482 4.38757 3.76281 5.0722 3.52421 5.99272H0.53125C0.237867 5.99272 0 6.21986 0 6.5C0 6.78014 0.237834 7.00728 0.53125 7.00728ZM5.67156 5.40212C6.30554 5.40212 6.82132 5.89463 6.82132 6.5C6.82132 7.10534 6.30554 7.59784 5.67156 7.59784C5.03758 7.59784 4.5218 7.10534 4.5218 6.5C4.5218 5.89463 5.03758 5.40212 5.67156 5.40212ZM16.4688 10.3803H13.4758C13.2372 9.45977 12.3651 8.77514 11.3284 8.77514C10.2917 8.77514 9.41966 9.45977 9.18106 10.3803H0.53125C0.237867 10.3803 0 10.6074 0 10.8876C0 11.1677 0.237867 11.3948 0.53125 11.3948H9.1811C9.41969 12.3154 10.2917 13 11.3284 13C12.3652 13 13.2372 12.3154 13.4758 11.3948H16.4688C16.7621 11.3948 17 11.1677 17 10.8876C17 10.6074 16.7622 10.3803 16.4688 10.3803ZM11.3284 11.9854C10.6945 11.9854 10.1787 11.4929 10.1787 10.8876C10.1787 10.2822 10.6945 9.78969 11.3284 9.78969C11.9624 9.78969 12.4782 10.2822 12.4782 10.8876C12.4782 11.4929 11.9624 11.9854 11.3284 11.9854Z'
        fill='#222B79'
      />
    </svg>
  )
}
export const FilterIcon = ({ handleClick }) => {
  return (
    <svg
      onClick={() => handleClick()}
      xmlns='http://www.w3.org/2000/svg'
      width='15'
      height='13'
      viewBox='0 0 15 13'
      fill='none'>
      <path
        d='M12.75 0H2.25C1.1895 0 0.65925 -4.30483e-08 0.33 0.297559C4.47035e-08 0.595118 0 1.07468 0 2.03308V2.53142C0 3.28037 0 3.65521 0.195 3.96577C0.39 4.27632 0.74475 4.46988 1.4565 4.85555L3.64125 6.04001C4.11825 6.29857 4.3575 6.42785 4.5285 6.57085C4.884 6.86769 5.103 7.21725 5.202 7.64697C5.25 7.85209 5.25 8.09331 5.25 8.57504V10.5034C5.25 11.1599 5.25 11.4885 5.439 11.7442C5.628 12.0006 5.964 12.127 6.6345 12.3797C8.04375 12.9099 8.748 13.1749 9.249 12.873C9.75 12.5719 9.75 11.8821 9.75 10.5027V8.57432C9.75 8.09331 9.75 7.85209 9.798 7.64625C9.89219 7.22531 10.1295 6.84663 10.4722 6.57013C10.6425 6.42785 10.8818 6.29857 11.3588 6.03929L13.5435 4.85483C14.2545 4.46988 14.6108 4.27705 14.805 3.96649C15 3.65593 15 3.28037 15 2.53069V2.03236C15 1.07468 15 0.595118 14.67 0.297559C14.3415 -4.30483e-08 13.8112 0 12.75 0Z'
        fill='#29348F'
      />
    </svg>
  )
}

import React from 'react'

export const TicketIcon = ({
  width = '22',
  height = '21',
  fill = '#29348F',
  stroke = '#29348F',
}) => {
  return (
    <>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width={width}
        height={height}
        viewBox='0 0 22 21'
        fill='none'>
        <path
          d='M19.7083 20.028H2.29167C1.0285 20.028 0 18.7641 0 17.2117V14.9587C0 14.6478 0.205333 14.3954 0.458333 14.3954H0.916667C1.92775 14.3954 2.75 13.3849 2.75 12.1424C2.75 10.8999 1.92775 9.88936 0.916667 9.88936H0.458333C0.205333 9.88936 0 9.63702 0 9.32611V7.07307C0 5.52073 1.0285 4.25677 2.29167 4.25677H19.7083C20.9715 4.25677 22 5.52073 22 7.07307V9.32611C22 9.63702 21.7947 9.88936 21.5417 9.88936H21.0833C20.0723 9.88936 19.25 10.8999 19.25 12.1424C19.25 12.744 19.4407 13.3095 19.7872 13.7353C20.1328 14.1611 20.5929 14.3954 21.0824 14.3954H21.5408C21.7938 14.3954 21.9991 14.6478 21.9991 14.9587V17.2117C22 18.7641 20.9715 20.028 19.7083 20.028ZM0.916667 15.522V17.2117C0.916667 18.1434 1.53358 18.9015 2.29167 18.9015H19.7083C20.4664 18.9015 21.0833 18.1434 21.0833 17.2117V15.522C20.3491 15.522 19.6579 15.1705 19.1391 14.5329C18.6193 13.8941 18.3333 13.0447 18.3333 12.1424C18.3333 10.2791 19.5672 8.76285 21.0833 8.76285V7.07307C21.0833 6.14144 20.4664 5.38329 19.7083 5.38329H2.29167C1.53358 5.38329 0.916667 6.14144 0.916667 7.07307V8.76285C2.43283 8.76285 3.66667 10.2791 3.66667 12.1424C3.66667 14.0057 2.43283 15.522 0.916667 15.522Z'
          fill={fill}
          strokeWidth='0.4'
        />
        <path
          d='M7.73438 6.38803C7.44975 6.38803 7.21875 6.14933 7.21875 5.85521V4.78959C7.21875 4.49547 7.44975 4.25677 7.73438 4.25677C8.019 4.25677 8.25 4.49547 8.25 4.78959V5.85521C8.25 6.14933 8.019 6.38803 7.73438 6.38803Z'
          fill={fill}
          strokeWidth='0.4'
        />
        <path
          d='M7.73438 16.1918C7.44975 16.1918 7.21875 15.9297 7.21875 15.6068V13.6283C7.21875 13.3054 7.44975 13.0433 7.73438 13.0433C8.019 13.0433 8.25 13.3042 8.25 13.6283V15.6079C8.25 15.9309 8.019 16.1918 7.73438 16.1918ZM7.73438 11.2427C7.44975 11.2427 7.21875 10.9806 7.21875 10.6577V8.67802C7.21875 8.3551 7.44975 8.09302 7.73438 8.09302C8.019 8.09302 8.25 8.3551 8.25 8.67802V10.6577C8.25 10.9806 8.019 11.2427 7.73438 11.2427Z'
          fill={fill}
          strokeWidth='0.4'
        />
        <path
          d='M7.73438 20.028C7.44975 20.028 7.21875 19.7893 7.21875 19.4952V18.4296C7.21875 18.1355 7.44975 17.8968 7.73438 17.8968C8.019 17.8968 8.25 18.1355 8.25 18.4296V19.4952C8.25 19.7893 8.019 20.028 7.73438 20.028Z'
          fill={fill}
          strokeWidth='0.4'
        />
        <path
          d='M3.25212 4.78261C3.09677 4.78261 2.95061 4.69783 2.89399 4.56041C2.81976 4.38334 2.92 4.18644 3.1182 4.12081L15.2856 0.0618267C15.8771 -0.136439 16.572 0.160276 16.774 0.692859L18.1537 4.3307C18.2211 4.50982 18.1139 4.7033 17.9142 4.76415C17.716 4.825 17.4971 4.7286 17.429 4.55016L16.0493 0.912319C15.982 0.734564 15.7509 0.637482 15.5527 0.701747L3.38527 4.76073C3.34165 4.77577 3.2965 4.78261 3.25212 4.78261Z'
          fill={fill}
          strokeWidth='0.4'
        />
      </svg>
    </>
  )
}

export const TicketGrayIcon = ({ width = '37', height = '33' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 37 33'
      fill='none'>
      <path
        d='M32.062 32.5659H4.61186C2.621 32.5659 1 30.5738 1 28.1272V24.5762C1 24.0862 1.32362 23.6885 1.72237 23.6885H2.44474C4.03829 23.6885 5.33423 22.0959 5.33423 20.1375C5.33423 18.1791 4.03829 16.5865 2.44474 16.5865H1.72237C1.32362 16.5865 1 16.1888 1 15.6988V12.1478C1 9.7012 2.621 7.70911 4.61186 7.70911H32.062C34.0528 7.70911 35.6738 9.7012 35.6738 12.1478V15.6988C35.6738 16.1888 35.3502 16.5865 34.9515 16.5865H34.2291C32.6355 16.5865 31.3396 18.1791 31.3396 20.1375C31.3396 21.0856 31.6401 21.9769 32.1862 22.648C32.7309 23.3192 33.4561 23.6885 34.2276 23.6885H34.95C35.3488 23.6885 35.6724 24.0862 35.6724 24.5762V28.1272C35.6738 30.5738 34.0528 32.5659 32.062 32.5659ZM2.44474 25.464V28.1272C2.44474 29.5955 3.41705 30.7904 4.61186 30.7904H32.062C33.2568 30.7904 34.2291 29.5955 34.2291 28.1272V25.464C33.0718 25.464 31.9825 24.91 31.1648 23.9051C30.3456 22.8984 29.8949 21.5597 29.8949 20.1375C29.8949 17.2009 31.8395 14.811 34.2291 14.811V12.1478C34.2291 10.6795 33.2568 9.48459 32.062 9.48459H4.61186C3.41705 9.48459 2.44474 10.6795 2.44474 12.1478V14.811C4.83435 14.811 6.77897 17.2009 6.77897 20.1375C6.77897 23.0742 4.83435 25.464 2.44474 25.464Z'
        fill='#6C6C6C'
        fillOpacity='0.2'
      />
      <path
        d='M32.3415 22.522L32.3413 22.5218L35.6724 28.1272H35.8724V28.1271V24.5762C35.8724 24.0162 35.4958 23.4885 34.95 23.4885H34.2276C33.5241 23.4885 32.8533 23.1526 32.3415 22.522ZM2.64474 28.1272V25.6587C5.08557 25.5304 6.97897 23.0646 6.97897 20.1375C6.97897 17.2104 5.08557 14.7446 2.64474 14.6163V12.1478C2.64474 10.7494 3.56407 9.68459 4.61186 9.68459H32.062C33.1098 9.68459 34.0291 10.7494 34.0291 12.1478V14.6163C31.5882 14.7446 29.6949 17.2104 29.6949 20.1375C29.6949 21.6009 30.1586 22.9854 31.0096 24.0313C31.8138 25.0195 32.8799 25.5984 34.0291 25.6587V28.1272C34.0291 29.5256 33.1098 30.5904 32.062 30.5904H4.61186C3.56407 30.5904 2.64474 29.5256 2.64474 28.1272Z'
        stroke='#6C6C6C'
        strokeOpacity='0.2'
        strokeWidth='0.4'
      />
      <path
        d='M13.19 11.0681C12.7414 11.0681 12.3773 10.6919 12.3773 10.2284V8.54886C12.3773 8.08532 12.7414 7.70911 13.19 7.70911C13.6386 7.70911 14.0027 8.08532 14.0027 8.54886V10.2284C14.0027 10.6919 13.6386 11.0681 13.19 11.0681Z'
        fill='#6C6C6C'
        fillOpacity='0.2'
      />
      <path
        d='M12.1773 10.2284C12.1773 10.7961 12.6248 11.2681 13.19 11.2681C13.7552 11.2681 14.2027 10.7961 14.2027 10.2284V8.54886C14.2027 7.98109 13.7552 7.50911 13.19 7.50911C12.6248 7.50911 12.1773 7.98109 12.1773 8.54886V10.2284Z'
        stroke='#6C6C6C'
        strokeOpacity='0.2'
        strokeWidth='0.4'
      />
      <path
        d='M13.19 26.5196C12.7414 26.5196 12.3773 26.1066 12.3773 25.5976V22.4794C12.3773 21.9704 12.7414 21.5574 13.19 21.5574C13.6386 21.5574 14.0027 21.9686 14.0027 22.4794V25.5994C14.0027 26.1084 13.6386 26.5196 13.19 26.5196ZM13.19 18.7194C12.7414 18.7194 12.3773 18.3063 12.3773 17.7974V14.6773C12.3773 14.1684 12.7414 13.7553 13.19 13.7553C13.6386 13.7553 14.0027 14.1684 14.0027 14.6773V17.7974C14.0027 18.3063 13.6386 18.7194 13.19 18.7194Z'
        fill='#6C6C6C'
        fillOpacity='0.2'
      />
      <path
        d='M12.1773 25.5976C12.1773 26.1925 12.608 26.7196 13.19 26.7196C13.7715 26.7196 14.2027 26.1949 14.2027 25.5994V22.4794C14.2027 21.8823 13.7718 21.3574 13.19 21.3574C12.608 21.3574 12.1773 21.8844 12.1773 22.4794V25.5976ZM12.1773 17.7974C12.1773 18.3923 12.608 18.9194 13.19 18.9194C13.772 18.9194 14.2027 18.3923 14.2027 17.7974V14.6773C14.2027 14.0824 13.772 13.5553 13.19 13.5553C12.608 13.5553 12.1773 14.0824 12.1773 14.6773V17.7974Z'
        stroke='#6C6C6C'
        strokeOpacity='0.2'
        strokeWidth='0.4'
      />
      <path
        d='M13.19 32.5659C12.7414 32.5659 12.3773 32.1897 12.3773 31.7261V30.0466C12.3773 29.5831 12.7414 29.2068 13.19 29.2068C13.6386 29.2068 14.0027 29.5831 14.0027 30.0466V31.7261C14.0027 32.1897 13.6386 32.5659 13.19 32.5659Z'
        fill='#6C6C6C'
        fillOpacity='0.2'
      />
      <path
        d='M12.1773 31.7261C12.1773 32.2939 12.6248 32.7659 13.19 32.7659C13.7552 32.7659 14.2027 32.2939 14.2027 31.7261V30.0466C14.2027 29.4788 13.7552 29.0068 13.19 29.0068C12.6248 29.0068 12.1773 29.4788 12.1773 30.0466V31.7261Z'
        stroke='#6C6C6C'
        strokeOpacity='0.2'
        strokeWidth='0.4'
      />
      <path
        d='M6.12586 8.53779C5.88102 8.53779 5.65066 8.40417 5.56141 8.18759C5.44442 7.90851 5.60241 7.59818 5.91479 7.49474L25.0916 1.09744C26.0239 0.78496 27.119 1.25261 27.4374 2.092L29.612 7.82554C29.7182 8.10785 29.5493 8.41279 29.2345 8.50869C28.9221 8.60459 28.5772 8.45266 28.4699 8.17143L26.2953 2.43789C26.1891 2.15773 25.8249 2.00472 25.5125 2.10601L6.33572 8.50331C6.26697 8.52701 6.19581 8.53779 6.12586 8.53779Z'
        fill='#6C6C6C'
        fillOpacity='0.2'
      />
      <path
        d='M29.2931 8.69993C29.7001 8.57577 29.9523 8.16236 29.7992 7.75516L29.799 7.75461L27.6244 2.02108L27.4374 2.092L25.0283 0.907722L25.0282 0.907752C25.0282 0.907772 25.0281 0.907792 25.028 0.907812L5.85192 7.30488C5.85187 7.30489 5.85182 7.30491 5.85177 7.30493C5.44593 7.43941 5.20857 7.86227 5.37671 8.26433C5.50209 8.56788 5.81447 8.73779 6.12586 8.73779C6.21592 8.73779 6.30892 8.72397 6.39992 8.69273C6.40025 8.69261 6.40058 8.6925 6.40091 8.69238L25.5742 2.29626C25.5745 2.29618 25.5747 2.29611 25.5749 2.29603C25.8027 2.22259 26.046 2.3445 26.1083 2.50875L26.1083 2.50882L28.2829 8.24235L28.283 8.24274C28.4328 8.63529 28.894 8.82238 29.2931 8.69993ZM29.2931 8.69993C29.2931 8.69991 29.2932 8.6999 29.2932 8.69989L29.2345 8.50869L29.2928 8.70001C29.2929 8.69998 29.293 8.69996 29.2931 8.69993Z'
        stroke='#6C6C6C'
        strokeOpacity='0.2'
        strokeWidth='0.4'
      />
    </svg>
  )
}

import { get } from '../api/axiosHelper'
import { useQuery } from '@tanstack/react-query'

export const useGetBanksAcounts = (options = {}) => {
  return useQuery({
    queryKey: ['bank-account'],
    queryFn: () =>
      get(
        'setting/terminal/bank-account',
        options.requestHeaders,
        options.requestParams,
      ),
    retryDelay: 20000,
  })
}

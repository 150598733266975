import React from 'react'

export function WifiIcon() {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 11 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M2.91042 5.34118L1.94792 4.32941C2.39861 3.86667 2.92768 3.50008 3.53513 3.22965C4.14257 2.95922 4.79753 2.82384 5.5 2.82353C6.20278 2.82353 6.85789 2.96078 7.46533 3.23529C8.07278 3.5098 8.6017 3.88235 9.05208 4.35294L8.08958 5.34118C7.75347 4.99608 7.36389 4.72549 6.92083 4.52941C6.47778 4.33333 6.00417 4.23529 5.5 4.23529C4.99583 4.23529 4.52222 4.33333 4.07917 4.52941C3.63611 4.72549 3.24653 4.99608 2.91042 5.34118ZM0.9625 3.34118L0 2.35294C0.702778 1.61569 1.52396 1.03922 2.46354 0.623529C3.40313 0.207843 4.41528 0 5.5 0C6.58472 0 7.59688 0.207843 8.53646 0.623529C9.47604 1.03922 10.2972 1.61569 11 2.35294L10.0375 3.34118C9.44931 2.73726 8.76746 2.26463 7.99196 1.92329C7.21646 1.58196 6.38581 1.41145 5.5 1.41176C4.61389 1.41176 3.78308 1.58243 3.00758 1.92376C2.23208 2.2651 1.55039 2.73757 0.9625 3.34118ZM5.5 8L7.11562 6.32941C6.90938 6.11765 6.66875 5.9509 6.39375 5.82918C6.11875 5.70745 5.82083 5.64675 5.5 5.64706C5.17917 5.64706 4.88125 5.70792 4.60625 5.82965C4.33125 5.95137 4.09063 6.11796 3.88438 6.32941L5.5 8Z'
        fill='#29348F'
      />
    </svg>
  )
}

import { InputControlMaterial } from '../index'
import { useController } from 'react-hook-form'

import './styles.validation.css'

export function ValidationCode({
  label = 'Código de verificación',
  error = '',
  control,
}) {
  const { field } = useController({
    name: 'refNumber',
    control,
    rules: {
      required: 'Este campo es requerido',
      pattern: {
        value: /^[1-9]\d*(\d+)?$/i,
        message: 'Solo deben ser numeros',
      },
      minLength: {
        value: 4,
        message: 'Minimo 4',
      },
    },
  })

  const handleChange = (e) => {
    // const regex = /^[1-9]\d*(\d+)?$/i
    const value = e.target.value

    const bum = !['e', 'E', '-', '.', ','].some((char) => value.includes(char))

    if (bum) {
      const parse = parseInt(value)

      if (!Number.isNaN(parse)) {
        field.onChange(parse)
      } else if (Number.isNaN(parse) && value === '') {
        field.onChange('')
      }
    }
    // if (value !== '' && regex.test(value)) {
    //   field.onChange(parseInt(value))
    // } else {
    //   field.onChange(undefined)
    // }
  }

  const handePress = (e) => {
    var key = e.keyCode
    // Only allow numbers to be entered

    if (key !== 8) {
      if (key < 48 || key > 57) {
        e.preventDefault()
      }
    }
  }

  return (
    <div className='input-field'>
      <InputControlMaterial
        field={field}
        label={label}
        type='number'
        onChange={handleChange}
        onKeyDown={handePress}
      />

      {error && <p className='error_message'>{error}</p>}
    </div>
  )
}

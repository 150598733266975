import { busiAPi } from '../../../utils/urls'

import AsyncSelect from 'react-select/async'
import makeAnimated from 'react-select/animated'

import { get } from '../../../api/axiosHelper'
import './styles.banks.css'

const animatedComponents = makeAnimated()

export function BanksSelect({ registerValidation, setValue }) {
  const PromiseData = async () => {
    const info = await get(`${busiAPi}/setting/banks`)

    const data = info.data.map(({ bankName, bankCode }) => {
      return { value: bankName, label: `${bankCode}-${bankName}` }
    })

    if (data.length > 0) {
      localStorage.setItem('banks', JSON.stringify(data))
      return data
    } else {
      let banks = localStorage.getItem('banks')
      banks = banks ? JSON.parse(banks) : []
      console.log({ banks })
      return banks ?? []
    }
  }

  return (
    <>
      <AsyncSelect
        {...registerValidation}
        placeholder='Seleccionar Banco'
        cacheOptions
        defaultOptions
        loadOptions={PromiseData}
        isSearchable={false}
        components={animatedComponents}
        styles={{
          container: (baseStyles) => ({
            ...baseStyles,
            width: '100%',
            boxShadow: 'none',
            outline: 'none',
            zIndex: '20px',
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 'none',
            borderRadius: '0px',
            borderBottom: '1px solid #29348F',
            boxShadow: 'none',
          }),
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            padding: 0,
            height: '32px',
          }),
          input: (baseStyles) => ({
            ...baseStyles,
            padding: 0,
            margin: 0,
          }),
          indicatorSeparator: (baseStyles) => ({
            ...baseStyles,
            display: 'none',
          }),
          dropdownIndicator: (baseStyles) => ({
            ...baseStyles,
            padding: '2px',
            color: '#29348F',
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            color: '#29348F',
          }),
          menuList: (baseStyles) => ({
            ...baseStyles,
            zIndex: 66,
            // position: 'relative',
            // display: 'inline',
          }),
          option: (baseStyles) => ({
            ...baseStyles,
            color: '#29348F',
            zIndex: 66,
          }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            color: '#29348F',
          }),
        }}
        onChange={(data) => {
          setValue('banks', data.value)
        }}
      />

      {/* <section className='bank-selector_container'>
      <div style={{ width: '100%' }} className='input-field'>
        <select defaultValue={''}>
          {data.data.map(({ bankName, bankCode }) => {
            return <option value={bankCode}>{bankName}</option>
          })}
        </select>
      </div>
    </section> */}
    </>
  )
}

import { useEffect, useState } from 'react'
import { Alert } from '..'

import './styles.nextdate.css'

export const NextDateAlert = ({
  isShow,
  setShowNotFound,
  nextDates = [],
  setValue,
  handleTrigger,
}) => {
  const [option, setOption] = useState(0)

  useEffect(() => {
    const dateSelected = nextDates[0]

    setValue('calendar', dateSelected?.dateServer)
  }, [])

  const handleChange = (e) => {
    setOption(e.target.value)
  }

  const handleSelection = (i) => {
    const dateSelected = nextDates[i]

    setValue('calendar', dateSelected.dateServer)
    setOption(i)
  }

  return (
    <Alert
      type='warning'
      isShow={isShow}
      setShow={setShowNotFound}
      title='¡No existen viajes para la fecha seleccionada!'
      buttonText='Buscar'
      onClickButton={() => handleTrigger()}>
      <section className='select-next-date_container'>
        <p className='subtitle'>
          Te mostraremos las opciones de boletos para la fecha más cercana:
        </p>

        <section className='date_card_container'>
          {nextDates.slice(0, 3).map((date, i) => {
            return (
              <div
                key={i + 1}
                className='date_card'
                onClick={() => handleSelection(i)}>
                <input
                  name='dates'
                  type='radio'
                  value={i}
                  checked={option === i}
                  onChange={handleChange}
                />

                <p>{date?.dateUser}</p>
              </div>
            )
          })}
        </section>
      </section>
    </Alert>
  )
}

import { useState } from 'react'
import { ButtonBlue } from '../../buttons'

import { FormsPhoneNumber } from '../../imputs/forms-phone-number'
import { ModalAlert } from '../index'

import { useForm } from 'react-hook-form'
import { busiAPi } from '../../../utils/urls'
import { isValidPhoneNumber } from 'libphonenumber-js/mobile'
import axios from 'axios'

export const EditPhoneAlert = ({
  show,
  setShowEditPhone,
  userData,
  setUserData,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm()

  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)

  // const phoneWatch = watch(`phone`)
  const codePhoneWatch = watch(`code`)

  const onSubmit = async (formData) => {
    const phoneNumber = `${formData.code}-${formData.phone}`
    // const phoneNumber = `58-${formData.code.slice(1)}-${formData.phone}`

    try {
      setLoading(true)
      const { data: info } = await axios.post(`${busiAPi}/passenger`, {
        email: userData.email,
        phoneNumber,
      })

      if (info.success) {
        setUserData({ ...userData, phone: phoneNumber })
        setLoading(false)
        setShowEditPhone(false)
      }
    } catch (error) {
      setLoading(false)
      const mainError = error?.response?.data?.error

      if (mainError?.errorCode?.code === '23505') {
        setError('Ese numero de telefono ya le pertenece a un usuario')
        return
      }
      if (mainError?.errorCode === 1002) {
        setError('El código ingresado es inválido')
        return
      }

      setError('Lo sentimos, algo salio mal, intente más tarde')
    }
  }

  return (
    <ModalAlert show={show} setShow={setShowEditPhone} showButton={false}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          width: '97%',
          display: 'flex',
          flexDirection: 'column',
          gap: '96px',
          marginTop: '25px',
        }}>
        <div>
          <FormsPhoneNumber
            registerCode={{
              ...register('code', { required: false }),
            }}
            registerValidation={{
              ...register('phone', {
                required: 'Este campo es requerido',
                // maxLength: {
                //   value: 7,
                //   message: 'El número ingresado debe tener 7 dígitos',
                // },
                minLength: {
                  value: 7,
                  // message: 'El número ingresado debe tener 7 dígitos',
                  message: 'Minimo debe tener 7 dígitos',
                },
                valueAsNumber: true,
                validate: (val) => {
                  return (
                    isValidPhoneNumber(`${codePhoneWatch}${val}`) ||
                    'Número no valido'
                  )
                },
              }),
            }}
            error={errors.phone?.message}
            setValue={setValue}
          />
          {error && <p className='error_message'>{error}</p>}
        </div>

        <ButtonBlue type='submit' disabled={loading}>
          Guardar
        </ButtonBlue>
      </form>
    </ModalAlert>
  )
}

import React, { memo } from "react";

function SeatIcon({ color = "#29348F", style = {} }) {
  return (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="14"
      viewBox="0 0 11 14"
      fill="none"
    >
      <path
        d="M10.708 0.354844C10.4998 0.126052 10.2127 0 9.89955 0H9.03883C8.4045 0 7.7991 0.521106 7.68946 1.16168L7.63518 1.47847C7.58061 1.79724 7.65405 2.09917 7.84202 2.32866C8.02998 2.55815 8.3073 2.6845 8.62299 2.6845H9.73483C9.88909 2.6845 10.0383 2.65291 10.1769 2.59685L10.0588 3.63189C9.94502 3.58288 9.82014 3.55678 9.6889 3.55678H8.42036C7.87692 3.55678 7.34314 4.0002 7.23037 4.5452L6.10881 9.96647L0.817112 9.5766C0.531153 9.55562 0.292427 9.65498 0.144417 9.85009C0.0057855 10.0328 -0.034741 10.278 0.0302265 10.5405L0.645457 13.0241C0.778745 13.5622 1.32776 14 1.86921 14H6.30547H6.37317H8.63135C9.18002 14 9.67355 13.5448 9.73142 12.9854L10.5995 4.59468C10.6044 4.54671 10.605 4.49945 10.6034 4.45266L10.9005 1.85074C10.9424 1.73867 10.9691 1.61956 10.9775 1.49601L10.9971 1.20638C11.0189 0.886091 10.9162 0.583695 10.708 0.354844Z"
        fill={color}
      />
    </svg>
  );
}

export default memo(SeatIcon);

import { Box, Dialog, Slide, SwipeableDrawer, styled } from '@mui/material'
import { forwardRef } from 'react'

export const SwipeableDrawerStyles = styled(SwipeableDrawer)({
  '.MuiPaper-root': {
    background: 'transparent',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'inherit',
  },
})

export const DialogStyles = styled(Dialog)({
  '.MuiPaper-root': {
    color: '#29348f',
    padding: 20,
    borderRadius: '25px ',
    backgroundColor: '#f6f6f6',
  },
})

export const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

export const ModalAlert = ({
  show = false,
  setShow,
  keepOpen = false,
  title,
  children,
  showButton = true,
  buttonText = 'Continuar',
  disabledButton = false,
  onClickButton,
}) => {
  return (
    <SwipeableDrawerStyles
      anchor='bottom'
      open={show}
      onClose={() => setShow(keepOpen)}
      onOpen={() => setShow(true)}
      disableSwipeToOpen={true}
      keepMounted={false}>
      <Box
        component='section'
        style={{
          fontFamily: 'Kufam',
          color: '#29348f',
          zIndex: 20,
          width: '94%',
          padding: 22,
          paddingBottom: 'max(50px, env(safe-area-inset-bottom))',
          borderRadius: '25px 25px 0px 0px',
          backgroundColor: '#f6f6f6',
          shadowColor: '#000',
          shadowOffset: { width: 0, height: -3 },
          shadowOpacity: 0.25,
          shadowRadius: 4,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        {children}
      </Box>
    </SwipeableDrawerStyles>
  )
}

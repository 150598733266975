// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.terms-conditions_container {
  display: flex;
  flex-direction: column;

  .terms-conditions_text {
    color: #29348f;
    font-family: Kufam;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 24px; 200% */
    text-decoration-line: underline;

    text-align: start;
  }

  .input-confirm-terms_container {
    display: flex;
    align-items: center;
    margin: 12px 0px;
    gap: 5px;

    & input {
      accent-color: ghostwhite;
      width: 15px;
      height: 15px;
    }

    .confirm-read_text {
      color: #29348f;
      font-family: Kufam;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: initial;
      height: 14px;
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/TermsConditions/AcceptCheck/styles.acceptcheck.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;;EAEtB;IACE,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,4BAA4B;IAC5B,+BAA+B;;IAE/B,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,QAAQ;;IAER;MACE,wBAAwB;MACxB,WAAW;MACX,YAAY;IACd;;IAEA;MACE,cAAc;MACd,kBAAkB;MAClB,eAAe;MACf,kBAAkB;MAClB,gBAAgB;MAChB,oBAAoB;MACpB,YAAY;IACd;EACF;AACF","sourcesContent":[".terms-conditions_container {\n  display: flex;\n  flex-direction: column;\n\n  .terms-conditions_text {\n    color: #29348f;\n    font-family: Kufam;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 700;\n    /* line-height: 24px; 200% */\n    text-decoration-line: underline;\n\n    text-align: start;\n  }\n\n  .input-confirm-terms_container {\n    display: flex;\n    align-items: center;\n    margin: 12px 0px;\n    gap: 5px;\n\n    & input {\n      accent-color: ghostwhite;\n      width: 15px;\n      height: 15px;\n    }\n\n    .confirm-read_text {\n      color: #29348f;\n      font-family: Kufam;\n      font-size: 12px;\n      font-style: normal;\n      font-weight: 400;\n      line-height: initial;\n      height: 14px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

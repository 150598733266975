import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Checkbox, FormControlLabel, styled } from '@mui/material'

import './styles.acceptcheck.css'

const FormWithLabel = styled(FormControlLabel)({
  '& .MuiFormControlLabel-label': {
    fontSize: '12px',
    fontFamily: 'Kufam',
    textAlign: 'left',
  },
})

export const AcceptTermsCheckbox = ({
  getValue,
  label = 'He leído y acepto los terminos y condiciones de transporte de Expresos Occidente',
  termsPostion = 'up',
  link = 'https://www.busitransporte.com/expresosoccidente',
  showCheck = true,
  title = 'Condiciones de transporte',
}) => {
  const [confirm, setConfirm] = useState(false)

  useEffect(() => {
    if (getValue) {
      getValue(confirm)
    }
  }, [confirm, getValue])

  return (
    <section className='terms-conditions_container'>
      {termsPostion === 'up' && (
        <Link className='terms-conditions_text' to={link} target='_blank'>
          {title}
        </Link>
      )}

      {showCheck && (
        <div className='input-confirm-terms_container'>
          <FormWithLabel
            control={
              <Checkbox
                checked={confirm}
                onChange={() => setConfirm(!confirm)}
                inputProps={{ 'aria-label': 'controlled' }}
                size='small'
              />
            }
            labelPlacement='end'
            label={label}
          />

          {/* <input
            type='checkbox'
            id='require'
            checked={confirm}
            onChange={() => setConfirm(!confirm)}
          />

          <label className='confirm-read_text' htmlFor='require'>
            {label}
          </label> */}
        </div>
      )}

      {termsPostion === 'down' && (
        <Link className='terms-conditions_text' to={link} target='_blank'>
          {title}
        </Link>
      )}
    </section>
  )
}

import { useState, Children } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'

import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'

import './styles.tutorial.css'

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
}

const CustomDot = ({ onClick, ...rest }) => {
  const {
    // onMove,
    index,
    active,
    carouselState: { currentSlide, deviceType },
  } = rest

  const carouselItems = [
    <img
      src={
        active
          ? '/assets/select-dot-selected.svg'
          : '/assets/select-dot-unselected.svg'
      }
      alt='dot'
    />,
    <img
      src={
        active
          ? '/assets/select-dot-selected.svg'
          : '/assets/select-dot-unselected.svg'
      }
      alt='dot'
    />,
    <img
      src={
        active
          ? '/assets/select-dot-selected.svg'
          : '/assets/select-dot-unselected.svg'
      }
      alt='dot'
    />,
  ]
  // onMove means if dragging or swiping in progress.
  // active is provided by this lib for checking if the item is active or not.
  return (
    <button className='button_dots' onClick={() => onClick()}>
      {Children.toArray(carouselItems)[index]}
    </button>
  )
}

export function TutorialPage() {
  const [selectedPage, setSelectedPage] = useState(1)

  const { state } = useLocation()
  const navegate = useNavigate()

  const navegation = () => {
    if (state?.wantToBuy) {
      navegate('/main/buy')
    } else {
      navegate('/main/search')
    }
  }

  console.log({ state })
  return (
    <Carousel
      responsive={responsive}
      containerClass='carousel-container'
      arrows={false}
      showDots={true}
      dotListClass='carousel-dot-list'
      customDot={<CustomDot />}>
      <FirstPage
        setSelectedPage={setSelectedPage}
        selectedPage={selectedPage}
        navegation={navegation}
      />

      <SecondPage
        setSelectedPage={setSelectedPage}
        selectedPage={selectedPage}
        navegation={navegation}
      />

      <ThirdPage
        setSelectedPage={setSelectedPage}
        selectedPage={selectedPage}
        navegation={navegation}
      />
    </Carousel>
  )
}

const FirstPage = ({ navegation }) => {
  return (
    <section className='pages-tutorial page_1'>
      <div className='omit_container'>
        <span onClick={() => navegation()}>omitir</span>
      </div>

      <div className='pages-tutorial-top'>
        <h2 className='pages-tutorial-title-highlight'>¡Registrate y Viaja!</h2>
        <p className='pages-tutorial-title'>
          Compra boletos en segundos desde tu celular
        </p>
      </div>

      <div className='pages-tutorial_img_container'>
        <img
          src='/assets/images/phone.png'
          className='pages-tutorial_img'
          alt='phone'
        />
      </div>
    </section>
  )
}

const SecondPage = ({ navegation }) => {
  return (
    <section className='pages-tutorial page_2'>
      <div className='omit_container'>
        <span onClick={() => navegation()}>omitir</span>
      </div>

      <div className='pages-tutorial-top'>
        <h2 className='pages-tutorial-title-highlight'>Explora las opciones</h2>
        <p className='pages-tutorial-title'>
          Encuentra el boleto perfecto para tu viaje.
        </p>
      </div>

      <div className='pages-tutorial_img_container'>
        <img
          src='/assets/images/hightway.svg'
          className='pages-tutorial_img img_2'
          alt='hightway'
        />
      </div>
    </section>
  )
}

const ThirdPage = ({ navegation }) => {
  return (
    <section className='pages-tutorial page_3'>
      <div className='omit_container'>
        <span style={{ display: 'none' }}>omitir</span>
      </div>

      <div className='pages-tutorial-top'>
        <h2 className='pages-tutorial-title-highlight'>
          ¡Viaja con facilidad!
        </h2>
        <p className='pages-tutorial-title'>
          Paga de manera segura y obtén tu boleto digital al instante.
        </p>
      </div>

      <div className='pages-tutorial_img_container'>
        <img
          src='/assets/images/bus.png'
          className='pages-tutorial_img'
          alt='bus'
        />
      </div>

      <div className='pages-tutorial_button_container'>
        <button className='pages-tutorial_button' onClick={() => navegation()}>
          Comenzar a usar Busi
        </button>
      </div>
    </section>
  )
}

import { useState } from 'react'
import { Box } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { Bus, BusLayout } from '../../components/busLayout'
import { Bus as BusLoading } from '../../components/ui/Loader/Bus'
import { GoBack } from '../../components/ui/GoBack'
import { useStore } from '../../store'

import { useForm } from 'react-hook-form'

// import dayjs from 'dayjs'

import { Radio } from '../../components/ui/Radio'
import { ButtonBlue } from '../../components/buttons'

import {
  useGetAvailableSeats,
  // useGetSelectSeats,
} from '../../hooks/useGetSeats'
import { TicketGrayIcon } from '../../components/icons/Ticket'
import axios from 'axios'
import { busiAPi } from '../../utils/urls'
import { SnackAlert } from '../../components/ui/SnackBar'

import './style.seat.css'

export const ChooseSeatPage = () => {
  const navegate = useNavigate()
  const [searchParams] = useSearchParams()
  const ticketsNumber = searchParams.get('ticketsNumber')

  const { destination, selectedTicket } = useStore(
    ({ destination, selectedTicket }) => ({ destination, selectedTicket }),
  )

  const {
    data: availableData,
    isLoading,
    isError,

    refetch,
  } = useGetAvailableSeats({
    ticketId: selectedTicket?.id,
  })
  // const { data: seletedData } = useGetSelectSeats({
  //   ticketId: selectedTicket?.id,
  // })

  const {
    handleSubmit,
    register,
    setValue,
    setError,
    formState: { errors },
    watch,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onChange',
    defaultValues: {
      floor: '1',
    },
  })

  const [selectedSeat, setSelectedSeat] = useState(0)
  const [open, setOpen] = useState(false)
  const watchFloor = watch('floor')

  const onSubmit = async (data) => {
    if (data?.spots.length < ticketsNumber) {
      setError('spots', {
        type: 'custom',
        message: 'Debes seleccionar todos los asientos',
      })
    }

    if (data?.spots?.length > 0) {
      const { data: info } = await axios.post(
        `${busiAPi}/ticket/${selectedTicket?.id}/selectSeats`,
        { seats: data.spots },
        { headers: { Authorization: localStorage.getItem('token') } },
      )
      if (info.success) {
        setOpen(true)
        refetch()
        navegate(`/main/ticket/detail/${selectedTicket.id}`, {
          state: { chooseSeats: true },
        })
      }
    }
  }

  if (isLoading) {
    return <BusLoading />
  }

  if (isError) {
    return (
      <section
        className='ticket-container_error'
        onClick={() => navegate(`/main/ticket/detail/${selectedTicket.id}`)}>
        <TicketGrayIcon />
        <p className='no_data_text'>Tenemos un problema intenta más tarde</p>
      </section>
    )
  }

  return (
    <Box
      component='section'
      style={{
        borderRadius: '20px 20px 0px 0px',
        background: '#f0eeee',
        boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.25)',
        position: 'relative',
        bottom: '18px',
        padding: '16px',
        paddingTop: '25px',
        height: 'calc(100% + 18px)',
        width: '100%',
        overflow: 'scroll',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <GoBack
        onHandleClick={() =>
          navegate(`/main/ticket/detail/${selectedTicket.id}`)
        }
        title='Selecciona tus asientos'
      />

      <form onSubmit={handleSubmit(onSubmit)} style={{ maxWidth: '600px' }}>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            flexWrap: 'nowrap',
          }}>
          <p
            style={{
              width: 'max-content',
              height: '19px',
              fontSize: '14px',
              textWrap: 'nowrap',
            }}
            className='location_text'>
            {destination?.from.split(' ').at(-1)} ({destination.shortFrom})
          </p>

          <hr className='divider-dash_search' />

          <p
            style={{
              width: 'max-content',
              height: '19px',
              fontSize: '14px',
              textWrap: 'nowrap',
            }}
            className='location_text'>
            {destination?.to.split(' ').at(-1)} ({destination.shortTo})
          </p>
        </Box>

        <p
          style={{
            textAlign: 'start',
            fontFamily: 'Kufam',
            fontSize: '14px',
            fontWeight: '700',
          }}
          className='search-bar_date_text'>
          {destination.date}
        </p>

        <Box
          component='section'
          style={{
            display: 'flex',
            // gridTemplateColumns: '0.7fr 1fr',
            gap: '10px',
            overflowY: 'scroll',
            height: 'calc(100vh - 200px)',
            // height: 'calc(100vh - 350px)',
            marginTop: '30px',
          }}>
          <Box
            component='section'
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'center',
              maxHeight: '420px',
              padding: '8px 0px',
            }}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}>
              <Radio
                labelRight='Piso 1'
                registerValidation={{
                  ...register('floor', {
                    required: 'Este campo es requerido',
                  }),
                }}
                value='1'
                id='one'
                setValue={setValue}
              />

              {availableData?.data.length > 1 && (
                <>
                  <hr
                    className='divider-vertical_dash_search'
                    style={{ marginLeft: '7px' }}
                  />

                  <Radio
                    labelRight='Piso 2'
                    registerValidation={{
                      ...register('floor', {
                        required: 'Este campo es requerido',
                      }),
                    }}
                    value='2'
                    id='two'
                    setValue={setValue}
                  />
                </>
              )}
            </Box>

            <Box>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <Box
                  style={{
                    width: '16px',
                    height: '16px',
                    borderRadius: '4px',
                    background: '#3BD4AE',
                  }}
                />
                <p className='parragraph_legend'>Asientos seleccionados</p>
              </Box>

              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <Box
                  style={{
                    width: '16px',
                    height: '16px',
                    borderRadius: '4px',
                    background: '#FFFFFF',
                  }}
                />
                <p className='parragraph_legend'>Asientos disponibles</p>
              </Box>

              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                <Box
                  style={{
                    width: '16px',
                    height: '16px',
                    borderRadius: '4px',
                    background: '#D31E4A',
                  }}
                />
                <p className='parragraph_legend'>Asientos ocupados</p>
              </Box>
            </Box>
          </Box>

          <Bus seats={ticketsNumber} selected={selectedSeat}>
            {availableData.data && (
              <BusLayout
                info={availableData.data}
                avaliableSeats={ticketsNumber}
                setSeatNumber={setSelectedSeat}
                seatsNumber={selectedSeat}
                watchFloor={watchFloor}
                setSpots={setValue}
              />
            )}
          </Bus>
        </Box>

        <Box
          component='section'
          style={{ display: 'flex', justifyContent: 'center' }}>
          <Box style={{ position: 'absolute', bottom: '18px' }}>
            <p
              className='error_message'
              style={{
                textAlign: 'center',
                fontSize: '16px',
                marginBottom: '3px',
              }}>
              {errors?.spots?.message}
            </p>
            <ButtonBlue type='submit'>Guardar selección de asientos</ButtonBlue>
          </Box>
        </Box>
      </form>

      <SnackAlert
        open={open}
        handleClose={() => setOpen(false)}
        text='Asientos seleccionados!'
      />
    </Box>
  )
}

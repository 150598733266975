import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { ButtonBlue } from '../../components/buttons'

import { CheckboxButton } from '../../components/ui/Checkbox'
import { Radio } from '../../components/ui/Radio'

import { busiAPi } from '../../utils/urls'

import AsyncSelect from 'react-select/async'

// import { SelectImput } from '../../components/imputs/select'
// import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import { get } from '../../api/axiosHelper'

import './styles.buy.css'

const animatedComponents = makeAnimated()

export const FilterModal = ({
  setShow,
  filters,
  setFilterData,
  handleClean,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    // formState: { errors },
  } = useForm()

  // #region DEFAULT VALUES
  useEffect(() => {
    if (filters) {
      setValue('time', filters.time)
      setValue('aire', filters.aire)
      setValue('wifi', filters.wifi)
      setValue('encava', filters.encava)
      setValue('buscama', filters.buscama)
    }
  }, [filters])

  // const lineFilter = async () => {
  //   const info = await get(`${busiAPi}/line`, {
  //     headers: { Authorization: localStorage.getItem('token') },
  //   })

  //   const data = info.data.busLines.map(({ name }) => {
  //     return { value: name, label: name }
  //   })

  //   if (filters) {
  //     return data.filter(({ value }) => value === filters.line)
  //   } else {
  //     return data
  //   }
  // }

  const PromiseData = async () => {
    const info = await get(`${busiAPi}/line`, {
      headers: { Authorization: localStorage.getItem('token') },
    })

    const data = info.data.busLines.map(({ name }) => {
      return { value: name, label: name }
    })

    return data
  }

  const onSubmit = (data) => {
    setFilterData({
      ...data,
      anyService: data.aire || data.wifi || data.encava || data.buscama,
    })
  }

  return (
    <section className='modal-sort_container'>
      <div className='overlay' onClick={() => setShow(false)}></div>

      <form className='card' onSubmit={handleSubmit(onSubmit)}>
        <p className='modal_subtitle'>Línea</p>

        {/* <SelectImput
          registerValidation={{
            ...register('line', {
              required: false,
              value: '',

            }),
          }}
        /> */}
        <AsyncSelect
          {...register('line', {
            required: false,
          })}
          placeholder='Línea'
          cacheOptions
          defaultOptions
          loadOptions={PromiseData}
          isSearchable={false}
          defaultValue={
            filters?.line
              ? { value: filters?.line, label: filters?.line }
              : null
          }
          components={animatedComponents}
          styles={{
            container: (baseStyles) => ({
              ...baseStyles,
              width: '100%',
              boxShadow: 'none',
              outline: 'none',
            }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              border: 'none',
              borderRadius: '0px',
              borderBottom: '1px solid #29348F',
              boxShadow: 'none',
            }),
            valueContainer: (baseStyles) => ({
              ...baseStyles,
              padding: 0,
            }),
            input: (baseStyles) => ({
              ...baseStyles,
              padding: 0,
              margin: 0,
            }),
            indicatorSeparator: (baseStyles) => ({
              ...baseStyles,
              display: 'none',
            }),
            option: (baseStyles) => ({
              ...baseStyles,
              color: '#29348F',
            }),
            dropdownIndicator: (baseStyles) => ({
              ...baseStyles,
              padding: '2px',
              color: '#29348F',
            }),
            placeholder: (baseStyles) => ({
              ...baseStyles,
              color: '#29348F',
            }),
            singleValue: (baseStyles) => ({
              ...baseStyles,
              color: '#29348F',
            }),
          }}
          onChange={(data) => {
            setValue('line', data.value)
          }}
        />

        {/* <p className='modal_subtitle'>Ordenar de</p> */}
        {/* <section className='section_container'>
      <Radio
        registerValidation={{
          ...register('sort', {
            required: false,
          }),
        }}
        labelRight='Menor precio'
        value='less'
        id='less'
      />

      <Radio
        registerValidation={{
          ...register('sort', {
            required: false,
          }),
        }}
        labelRight='Mayor precio'
        value='more'
        id='more'
      />
    </section> */}

        <p className='modal_subtitle'>Hora de salida</p>
        <section className='section_container'>
          <Radio
            registerValidation={{
              ...register('time', {
                required: false,
              }),
            }}
            labelRight='AM'
            value='am'
            id='am'
            setValue={setValue}
          />

          <Radio
            registerValidation={{
              ...register('time', {
                required: false,
              }),
            }}
            labelRight='PM'
            value='pm'
            id='pm'
            setValue={setValue}
          />
        </section>

        {/* <p className='modal_subtitle'>Cantidad de pisos</p>
    <section className='section_container'>
      <Radio
        registerValidation={{
          ...register('floor', {
            required: false,
          }),
        }}
        labelRight='1'
        value='one'
        id='floorOne'
      />

      <Radio
        registerValidation={{
          ...register('floor', {
            required: false,
          }),
        }}
        labelRight='2'
        value='two'
        id='floorTwo'
      />
    </section> */}

        {/* <p className='modal_subtitle'>Peso de equipaje</p>
        <section className='section_container'>
          <Radio
            registerValidation={{
              ...register('kg', {
                required: false,
              }),
            }}
            labelRight='25kg o menos'
            value='lessKg'
            id='lessKg'
          />

          <Radio
            registerValidation={{
              ...register('kg', {
                required: false,
              }),
            }}
            labelRight='Más de 25kg'
            value='moreKg'
            id='moreKg'
          />
        </section> */}

        <p className='modal_subtitle'>Características del bus</p>

        <section className='modal-sort_bus_container'>
          <CheckboxButton
            registerValidation={{
              ...register('aire', {
                required: false,
              }),
            }}
            label='Aire acondicionado'
            id='aire'
            setValue={setValue}
            check={filters?.aire}
            // value='aire'
          />

          <CheckboxButton
            registerValidation={{
              ...register('wifi', {
                required: false,
              }),
            }}
            label='Wifi'
            id='wifi'
            setValue={setValue}
            check={filters?.wifi}
            // value='wifi'
          />

          <CheckboxButton
            registerValidation={{
              ...register('buscama', {
                required: false,
              }),
            }}
            label='Buscama'
            id='buscama'
            setValue={setValue}
            check={filters?.buscama}
            // value='buscama'
          />

          <CheckboxButton
            registerValidation={{
              ...register('encava', {
                required: false,
              }),
            }}
            label='Encava'
            id='encava'
            setValue={setValue}
            check={filters?.encava}
            // value='encava'
          />

          {/* <CheckboxButton
        registerValidation={{
          ...register('bath', {
            required: false,
          }),
        }}
        label='Baño'
        id='bath'
        setValue={setValue}
        // value='baño'
      /> */}
        </section>

        <div className='modal-sort_action_buttons'>
          <ButtonBlue type='submit'>Aplicar filtros</ButtonBlue>
          <ButtonBlue
            onClick={() => handleClean()}
            type='button'
            design='secondary'>
            Limpiar filtros
          </ButtonBlue>
        </div>
      </form>
    </section>
  )
}

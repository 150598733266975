import { ArrowBack } from '../../icons/ArrowBack'

export const GoBack = ({ title, onHandleClick }) => {
  return (
    <div
      onClick={onHandleClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        padding: '15px',
        paddingLeft: '0px',
        gap: '15px',
        maxWidth: '600px',
        width: '100%',
      }}>
      <ArrowBack />

      <h2 style={{ margin: 0, fontSize: '18px', textWrap: 'balance' }}>
        {title}
      </h2>
    </div>
  )
}

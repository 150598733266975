import { get } from '../api/axiosHelper'
import { useQuery } from '@tanstack/react-query'

export const useCheckRateReview = (options = {}) => {
  return useQuery({
    queryKey: ['review'],
    queryFn: () => get('trip/without-review', options),
    retryDelay: 20000,
  })
}

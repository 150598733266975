// import { useState } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Slide from '@mui/material/Slide'
import { Alert, IconButton } from '@mui/material'

export const SnackAlert = ({
  open = false,
  handleClose,
  severity = 'success',
  text = 'Se envio tu codigo',
}) => {
  // const [open, setOpen] = useState(false)
  // const handleClose = () => setOpen(false)

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      TransitionComponent={Slide}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      action={
        <IconButton aria-label='close' color='inherit' onClick={handleClose}>
          x
        </IconButton>
      }>
      <Alert
        onClose={handleClose}
        severity={severity}
        variant='standard'
        sx={{ width: '100%' }}>
        {text}
      </Alert>
    </Snackbar>
  )
}

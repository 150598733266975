import React, { useEffect } from 'react'
import { Bus } from '../../components/ui/Loader/Bus'
import { useNavigate } from 'react-router-dom'

export const NotFound = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/main/search')
  }, [])

  return <Bus />
}

import { createBrowserRouter } from 'react-router-dom'

// import RoutesPage from "./pages/RoutesPage"
// import QrPage from './QRPage'
// import PaymentsPointsPage from './PaymentPointsPage'
// import PointTravel from './PointTravel'
// import PointTravelPayment from './PointTravelPayment'
// import PointTravelPaymentDetails from './PointTravelPaymentDetails'
// import ChooseSeatPage from './pages/ChooseSeat'

import Root from './pages/RootPage'
import MainPage from './pages/MainPage'
import { LoginPage } from './pages/LoginPage'
import { SingUpPage } from './pages/SignUpPage'
import { ValidationCodePage } from './pages/ValidationCodePage'
import { TutorialPage } from './pages/TutorialPage'
import { Profile } from './pages/Profile'
import { TicketsPage } from './pages/TicketsPage'
import { SearchPage } from './pages/Search'
import { SearchDetail } from './pages/SearchDetails'
import { PaymentPage } from './pages/Payment'
import { ReservePage } from './pages/Reserve'
import { ChooseSeatPage } from './pages/ChooseSeat'

import QualificationPage from './pages/QualificationPage'
import TicketDetailPage from './pages/TicketDetailPage'
import AllTicketsPage from './pages/AllTickets'
import BuyTicketsPage from './pages/BuyTickets'
import ResetPasswordPage from './pages/ResetPassword'
import BuyDetailPage from './pages/BuyTicketsDetail'

// import { AppUrlListener } from './utils/AppUrlListener'
import { NotFound } from './pages/NotFound'

export const routers = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'reset',
        element: <ResetPasswordPage />,
      },
      {
        path: 'registration',
        element: <SingUpPage />,
      },
      {
        path: 'validate',
        element: <ValidationCodePage />,
      },
      {
        path: 'tutorial',
        element: <TutorialPage />,
      },
      {
        path: 'qualification',
        element: <QualificationPage />,
      },
      {
        path: 'seat',
        element: <ChooseSeatPage />,
      },
      {
        path: 'main',
        element: <MainPage />,
        children: [
          // {
          //   path: 'routes',
          //   element: <RoutesPage />,
          // },
          // {
          //   path: 'qr',
          //   element: <QrPage />,
          // },
          {
            path: 'profile',
            element: <Profile />,
          },
          {
            path: 'search',
            element: <SearchPage />,
          },
          {
            path: 'search/detail/:lineId',
            element: <SearchDetail />,
          },
          {
            path: 'ticket',
            element: <TicketsPage />,
          },
          {
            path: 'ticket/detail/:ticketId',
            element: <TicketDetailPage />,
          },
          {
            path: 'all',
            element: <AllTicketsPage />,
          },
          {
            path: 'buy',
            element: <BuyTicketsPage />,
          },
          {
            path: 'buy/detail/:ticketId',
            element: <BuyDetailPage />,
          },
          {
            path: 'payment',
            element: <PaymentPage />,
          },
          {
            path: 'reserve',
            element: <ReservePage />,
          },
          // {
          //   path: 'payments-points',
          //   element: <PaymentsPointsPage />,
          // },
        ],
      },

      { path: '*', element: <NotFound /> },
      // {
      //   path: 'point-travel',
      //   element: <PointTravel />,
      //   children: [],
      // },
      // {
      //   path: 'travel-payment',
      //   element: <PointTravelPayment />,
      // },
      // {
      //   path: 'travel-payment-details',
      //   element: <PointTravelPaymentDetails />,
      // },
    ],
  },
])

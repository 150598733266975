// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.date-input_container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #29348f;
  /* margin-top: 12px; */
}

.date-input {
  margin: 0px !important;
  border-bottom: none !important;
  font-size: 16px !important;
  color: #29348f;
  /* border-bottom: 1px solid #29348f; */
  border-radius: 0;
  font-family: Kufam;

  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
  height: 30px;
}

.input-field .prefix {
  right: 0;
}

.forgotten-date {
  color: #3db3e3;
  font-family: Kufam;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.unstyled {
  -webkit-appearance: none;
}
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/imputs/forms-birth-date/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gCAAgC;EAChC,sBAAsB;AACxB;;AAEA;EACE,sBAAsB;EACtB,8BAA8B;EAC9B,0BAA0B;EAC1B,cAAc;EACd,sCAAsC;EACtC,gBAAgB;EAChB,kBAAkB;;EAElB,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;EAC5B,YAAY;AACd;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,wBAAwB;AAC1B;AACA;;EAEE,aAAa;EACb,wBAAwB;AAC1B","sourcesContent":[".date-input_container {\n  display: flex;\n  align-items: center;\n  border-bottom: 1px solid #29348f;\n  /* margin-top: 12px; */\n}\n\n.date-input {\n  margin: 0px !important;\n  border-bottom: none !important;\n  font-size: 16px !important;\n  color: #29348f;\n  /* border-bottom: 1px solid #29348f; */\n  border-radius: 0;\n  font-family: Kufam;\n\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px; /* 200% */\n  height: 30px;\n}\n\n.input-field .prefix {\n  right: 0;\n}\n\n.forgotten-date {\n  color: #3db3e3;\n  font-family: Kufam;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n\n.unstyled {\n  -webkit-appearance: none;\n}\ninput[type='date']::-webkit-inner-spin-button,\ninput[type='date']::-webkit-calendar-picker-indicator {\n  display: none;\n  -webkit-appearance: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

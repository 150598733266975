import { TextField, styled } from '@mui/material'

const CssTextField = styled(TextField)({
  '.MuiInput-root::before': {
    borderBottom: '1px solid transparent',
  },
  '.MuiInput-root::after': {
    borderBottom: '2px solid #29348F',
  },
  '.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
    borderBottom: '1px solid transparent',
  },
})

export const TextAreaMaterial = ({
  register,
  label = '',
  type = 'text',
  gray = false,
}) => {
  return (
    <CssTextField
      {...register}
      multiline
      fullWidth
      label={label}
      autoComplete='off'
      variant='standard'
      type={type}
      InputLabelProps={{
        style: {
          color: gray ? '#898989' : '#29348F',
          fontFamily: 'Kufam',
        },
      }}
      InputProps={{
        style: {
          color: gray ? '#898989' : '#29348F',
          border: 'none',
          borderBottom: gray ? '1px solid #898989' : '1px solid #29348F',
          fontFamily: 'Kufam',
        },
      }}
    />
  )
}

import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'

export const usePersistStore = create(
  persist(
    (set) => ({
      userData: {
        name: undefined,
        lastName: undefined,
        ci: undefined,
        email: undefined,
        bornDate: undefined,
        status: undefined,
        gender: undefined,
        phone: undefined,
      },
      // token: undefined,
      // setToken: (data) => set({ token: data }),
      setUserData: (data) => set({ userData: data }),
    }),
    {
      name: 'busi_passenger_user', // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    },
  ),
)

export const useStore = create((set) => ({
  trips: [],
  selectedTrip: {},
  tickets: [],
  selectedTicket: {},
  selectedLine: {},
  destination: {
    to: undefined,
    from: undefined,
    date: undefined,
    shortTo: undefined,
    shortFrom: undefined,
    isConnection: undefined,
  },
  passengers: {
    general: 0,
    child: 0,
    elder: 0,
    total: 0,
  },
  unregPassengersData: [],
  ownerTravel: true,
  password: undefined,
  wsLink: undefined,

  setWsLink: (data) => set({ wsLink: data }),
  setPassword: (data) => set({ password: data }),
  setDestination: (data) => set({ destination: data }),
  setTickets: (data) => set({ tickets: data }),
  setSelectedTickets: (data) => set({ selectedTicket: data }),
  setTrips: (data) => set({ trips: data }),
  setSelectedTrip: (data) => set({ selectedTrip: data }),
  setSelectedLine: (data) => set({ selectedLine: data }),
  setPassengers: (data) => set({ passengers: data }),
  setOwnerTravel: (data) => set({ ownerTravel: data }),
  setUnregPassengersData: (data) => set({ unregPassengersData: data }),
}))

export const useAmountsStorage = create((set) => ({
  totalUsd: 0,
  totalUsdDiscount: 0,
  totalBs: 0,
  totalBsDiscount: 0,
  dayRate: 0,
  //
  perTotalBs: 0,
  perTotalUsd: 0,
  completedBookedUsd: 0,
  completedBookedBs: 0,
  setTotalUsd: (data) => set({ totalUsd: data }),
  setTotalUsdDiscount: (data) => set({ totalUsdDiscount: data }),
  setTotalBs: (data) => set({ totalBs: data }),
  setTotalBsDiscount: (data) => set({ totalBsDiscount: data }),
  setDayRate: (data) => set({ dayRate: data }),
  //
  setCompletedBookedUsd: (data) => set({ completedBookedUsd: data }),
  setCompletedBookedBs: (data) => set({ completedBookedBs: data }),
  setPerTotalBs: (data) => set({ perTotalBs: data }),
  setPerTotalUsd: (data) => set({ perTotalUsd: data }),
}))

export const useMeasureStorage = create((set) => ({
  mainHeight: 0,
  headerHeight: 0,
  footerHeight: 0,

  setMainHeight: (data) => set({ mainHeight: data }),
  setHeaderHeight: (data) => set({ headerHeight: data }),
  setFooterHeight: (data) => set({ footerHeight: data }),
}))

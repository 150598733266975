import { Outlet } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

import './styles.css'
import { AppUrlListener } from '../../utils/AppUrlListener'

export default function Root() {
  const navigate = useNavigate()

  useEffect(() => {
    // params === '#/' && navigate('/login')
    navigate('/main/search')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <main className='main'>
      <AppUrlListener />
      <Outlet></Outlet>
    </main>
  )
}

import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

// import { SearchBar } from '../../components/SearchBar'
import { CardActionArea, Box, useMediaQuery } from '@mui/material'
import { SearchResult } from '../../components/SearchBar/SearchResult'
import { useGetExchains } from '../../hooks/useExchain'
import { Ticket } from '../../components/Tickets'

import { FilterIcon } from '../../components/icons/Sort'

import { Loader } from '../../components/ui/Loader'

import { buyTickets } from '../../utils/mocks/tickects'
import { useStore } from '../../store'
import { TicketGrayIcon } from '../../components/icons/Ticket'
import { FilterModal } from './FilterModal'

import { WarningModal } from '../../components/bottom-panel/Alert/WarningInfo'
import { Alert } from '../../components/bottom-panel/Alert'
import { InternationalYellowCard } from '../../components/ui/Cards/Info'

import dayjs from 'dayjs'

// import customParseFormat from 'dayjs/plugin/customParseFormat'

import './styles.buy.css'
import { useWantToLogin } from '../../hooks/useWantLogin'

const BuyTickets = () => {
  const { data: res } = useGetExchains()
  const minW800 = useMediaQuery('(min-width:800px)')

  const navegate = useNavigate()
  const { state, pathname } = useLocation()

  const { wantToLogin } = useWantToLogin()

  const { destination, trips, setTrips } = useStore(
    ({ destination, trips, setTrips }) => ({
      destination,
      trips,
      setTrips,
    }),
  )

  const [show, setShow] = useState(false)
  const [showIntertional, setShowInternational] = useState(false)
  const [isWarning, setIsWarning] = useState(false)
  const [isPerSeat, setIsPerSeat] = useState(false)

  const [tickets, setTickets] = useState(buyTickets)

  const [ticketId, setTicketId] = useState('')
  const [ticketName, setTicketName] = useState('')
  const [ticketUrl, setTicketUrl] = useState('')

  const [filters, setFilter] = useState(undefined)
  const [loadingFilters, setLoadingFilters] = useState(undefined)

  // FILTER OPTIONS
  // const [less, setLess] = useState(true)
  // const [am, setAm] = useState(true)
  // const [floorOne, setFloorOne] = useState(false)
  // // const [lessKg, setLessKg] = useState(false)
  // const [aire, setAire] = useState(false)
  // const [wifi, setWifi] = useState(false)
  // const [buscama, setBuscama] = useState(false)
  // const [bath, setBath] = useState(false)

  // #region EFFECTS
  useEffect(() => {
    setLoadingFilters(true)

    // SORT LOGIC

    // let filteredTickets = buyTickets.filter((item) => {
    //   const date = dayjs(destination.date, 'DD/MM/YYYY')

    //   const matchDate = Intl.DateTimeFormat('es-VE', {
    //     year: 'numeric',
    //     month: 'long',
    //     day: '2-digit',
    //   })
    //     .format(date.toDate())
    //     .replaceAll('de ', '')

    //   if (destination.from !== undefined) {
    //     return (
    //       item.startLocation.edo === destination.from &&
    //       item.endLocation.edo === destination.to &&
    //       matchDate === item.date
    //     )
    //   } else {
    //     return true
    //   }
    // })

    let filteredTickets = trips.map((tickets) => {
      const date = dayjs(destination.date, 'DD/MM/YYYY')
      const dateServer = dayjs(destination.date, 'DD/MM/YYYY').format(
        'MM-DD-YYYY',
      )
      const dateForm = dayjs(destination.date, 'DD/MM/YYYY').format(
        'YYYY-MM-DD',
      )

      const matchDate = Intl.DateTimeFormat('es-VE', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      })
        .format(date.toDate())
        .replaceAll('de ', '')

      //region SERVICES ARRAY
      let busServices = []

      if (
        tickets.petFriendly ||
        tickets?.services?.find((item) => item.path === 'pet')
      ) {
        busServices.push({ path: 'pet', text: 'Mascotas' })
      }

      if (tickets.busType === 'Buscama') {
        busServices.push({ path: 'bus', text: 'Buscama' })
      }
      if (tickets.busType === 'Encava') {
        busServices.push({ path: 'bus', text: 'Encava' })
      }

      if (
        tickets.wifi ||
        tickets?.services?.find((item) => item.path === 'wifi')
      ) {
        busServices.push({ path: 'wifi', text: 'Wifi' })
      }
      if (
        tickets.ac ||
        tickets?.services?.find((item) => item.path === 'air')
      ) {
        busServices.push({ path: 'air', text: 'Aire' })
      }

      if (
        tickets.lugaggeWeight ||
        tickets?.services?.find((item) => item.path === 'bag')
      ) {
        busServices.push({ path: 'bag', text: `${tickets.lugaggeWeight}kg` })
      }

      if (
        tickets?.isPerSeat ||
        tickets?.services?.find((item) => item.path === 'perSeat')
      ) {
        setIsPerSeat(true)
        busServices.push({ path: 'perSeat', text: 'Por puesto' })
      }

      //region CHANGE HOUR
      const departureTime = tickets.startTime
        ? tickets.startTime
        : Intl.DateTimeFormat('es-VE', {
            hour: 'numeric',
            minute: 'numeric',
          })
            .format(
              dayjs(
                `${dateForm}:${tickets.departureETA}`,
                'YYYY-MM-DD:HH:mm:ss',
              ).toDate(),
            )
            .toLocaleUpperCase()

      const arrivalTime = tickets.endTime
        ? tickets.endTime
        : Intl.DateTimeFormat('es-VE', {
            hour: 'numeric',
            minute: 'numeric',
          })
            .format(
              dayjs(
                `${dateForm}:${tickets.arrivalETA}`,
                'YYYY-MM-DD:HH:mm:ss',
              ).toDate(),
            )
            .toLocaleUpperCase()

      // CHANCE PRICE

      const { ac, wifi, arrivalETA, departureETA, ...item } = tickets

      return {
        ...item,
        date: matchDate,
        dateServer: dateServer,
        services: busServices,
        startTime: departureTime,
        endTime: arrivalTime,
      }
    })

    setTrips(filteredTickets)

    // SORT LESS OR MAYOR PRICE
    // const lessToMayor = filteredTickets.sort((a, b) => a.price - b.price)

    // const mayortoLess = filteredTickets.sort((a, b) => b.price - a.price)

    // const floor = buyTickets.filter((item) => {
    //   const services = item.services.map((item) => item.text)
    //   const bum = true
    //     ? services.includes('1 Piso')
    //     : services.includes('2 Pisos')
    //   return bum
    // })

    // FILTER LINE
    filteredTickets = filters?.line
      ? filteredTickets.filter((item) => {
          return (
            filters.line.toLocaleLowerCase() ===
            item.busLine.toLocaleLowerCase()
          )
        })
      : filteredTickets

    // FILTER TIME
    filteredTickets = filters?.time
      ? filteredTickets.filter((item) => {
          return filters.time === 'am'
            ? item.startTime.includes('A.')
            : item.startTime.includes('P.')
        })
      : filteredTickets

    // FILTER CHARACTERISTICS
    filteredTickets = filters?.anyService
      ? filteredTickets.filter((item) => {
          const services = item.services.map((item) =>
            item?.text?.toLocaleLowerCase(),
          )

          const isAir = filters.aire && services.includes('aire')
          const isWifi = filters.wifi && services.includes('wifi')
          const isEncava = filters.encava && services.includes('encava')
          const isBuscama = filters.buscama && services.includes('buscama')

          const isAvalible = isAir || isWifi || isEncava || isBuscama
          // console.log({ isAvalible, isAir, isBed })
          return isAvalible
        })
      : filteredTickets

    setTickets(filteredTickets)
    setLoadingFilters(false)
    setShow(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [destination.date, destination.from, destination.to, filters])

  // #region FUNTIONS
  const unitPrice = (priceUsd, exchainName = 'usd', discount = 0) => {
    const rateUsd = 36.45

    // GET PRICE IN BS FOR A GENERAL PASSENGER
    if (res?.data && exchainName === 'bs') {
      const usdBcv = res?.data?.find(({ key }) => key === 'EXCHANGE_RATE_BS')

      if (usdBcv) {
        const bsVale = Number(usdBcv.value) * priceUsd

        return new Intl.NumberFormat('es-VE', {
          currency: 'USD',
        }).format(bsVale.toFixed(2))
      } else {
        return rateUsd * priceUsd
      }
    }

    return priceUsd - discount
  }

  const handleShow = () => {
    setShow(true)
  }

  const handleClean = () => {
    setFilter(undefined)
  }

  const handleClickModal = () => {
    setIsWarning(false)
    navegate(`/main/buy/detail/${ticketId}`, {
      state: { previousPath: state?.previousPath },
    })
  }

  const handleClickTicket = (ticket) => {
    setTicketId(ticket.subRouteId)
    setTicketName(ticket.busLine)
    setTicketUrl(ticket.termUrl)

    if (wantToLogin) {
      setTimeout(() => {
        setIsWarning(true)
      }, 550)
    } else {
      navegate('/login', {
        state: { previousPath: pathname, wantToBuy: true },
      })
    }
  }

  if (loadingFilters) {
    return <Loader />
  }

  if (minW800) {
    return (
      <section
        className='page-buy_main'
        style={{
          height: 'calc(100% - 134px)',
          minHeight: '600px',
          bottom: 0,
          borderRadius: 0,
          background: 'transparent',
        }}>
        <SearchResult previousPath={state?.previousPath} />

        <section className='page-buy_body' style={{ paddingTop: '126px' }}>
          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: destination.isConnection
                ? 'space-between'
                : 'end',
              paddingRight: '14px',
              paddingLeft: '14px',
            }}>
            {destination.isConnection && (
              <div onClick={() => setShowInternational(true)}>
                <InternationalYellowCard
                  title='Más sobre conexiones internacionales'
                  noMargin={true}
                />
              </div>
            )}

            <div className='page-buy_sort'>
              <p onClick={() => setShow(true)}>Filtros</p>
              <FilterIcon handleClick={handleShow} />
            </div>
          </Box>

          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: '100%',
            }}>
            <Box className='page-buy_ticket_container'>
              {tickets.length === 0 ? (
                <section className='page-buy_no_items'>
                  <TicketGrayIcon />
                  <p className='no_data_text'>No tienes boletos</p>
                </section>
              ) : (
                tickets.map((ticket, i) => {
                  return (
                    <CardActionArea
                      color='primary'
                      key={i}
                      onClick={() => handleClickTicket(ticket)}>
                      <Ticket
                        price={unitPrice(ticket.priceUsd, 'bs')}
                        priceRef={unitPrice(ticket.priceUsd)}
                        title={ticket.busLine}
                        date={ticket.date}
                        startTime={ticket.startTime}
                        // endTime={ticket.endTime}
                        startLocation={{
                          short: ticket.originNameshort,
                          long: ticket.originName,
                        }}
                        endLocation={{
                          short: ticket.destinyNameShort,
                          long: ticket.destinyName,
                        }}
                        availability={ticket.availableSeats}
                        services={ticket.services}
                      />
                    </CardActionArea>
                  )
                })
              )}
            </Box>
          </Box>
        </section>

        {show && (
          <FilterModal
            setShow={setShow}
            filters={filters}
            setFilterData={setFilter}
            handleClean={handleClean}
          />
        )}

        <WarningModal
          termsLabel={`He leído y acepto los terminos y condiciones de transporte de ${ticketName}`}
          termUrl={ticketUrl}
          isWarning={isWarning}
          setIsWarning={setIsWarning}
          onClick={handleClickModal}
        />

        <Alert
          type='warning'
          isShow={showIntertional}
          setShow={setShowInternational}
          onClickButton={() => setShowInternational(false)}
          title='Boleto con conexión'>
          <p style={{ textAlign: 'center' }}>
            Al comprar un boleto con conexión deberás abordar dos autobuses para
            llegar hasta tu destino final.
          </p>
          <p style={{ textAlign: 'center' }}>
            El costo del boleto incluye ambos traslados.
          </p>
        </Alert>
      </section>
    )
  }

  return (
    <section className='page-buy_main'>
      <SearchResult previousPath={state?.previousPath} />

      <section className='page-buy_body'>
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: destination.isConnection ? 'space-between' : 'end',
            paddingRight: '14px',
            paddingLeft: '14px',
          }}>
          {destination.isConnection && (
            <div onClick={() => setShowInternational(true)}>
              <InternationalYellowCard
                title='Más sobre conexiones internacionales'
                noMargin={true}
              />
            </div>
          )}

          <div className='page-buy_sort'>
            <p onClick={() => setShow(true)}>Filtros</p>
            <FilterIcon handleClick={handleShow} />
          </div>
        </Box>

        <Box
          style={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
          <Box className='page-buy_ticket_container'>
            {tickets.length === 0 ? (
              <section className='page-buy_no_items'>
                <TicketGrayIcon />
                <p className='no_data_text'>No tienes boletos</p>
              </section>
            ) : (
              tickets.map((ticket, i) => {
                return (
                  <CardActionArea
                    color='primary'
                    key={i}
                    onClick={() => handleClickTicket(ticket)}>
                    <Ticket
                      price={unitPrice(ticket?.priceUsd, 'bs')}
                      priceRef={unitPrice(ticket?.priceUsd)}
                      title={ticket.busLine}
                      date={ticket.date}
                      startTime={ticket.startTime}
                      // endTime={ticket.endTime}
                      startLocation={{
                        short: ticket.originNameshort,
                        long: ticket.originName,
                      }}
                      endLocation={{
                        short: ticket.destinyNameShort,
                        long: ticket.destinyName,
                      }}
                      availability={ticket.availableSeats}
                      services={ticket.services}
                    />
                  </CardActionArea>
                )
              })
            )}
          </Box>
        </Box>
      </section>

      {show && (
        <FilterModal
          setShow={setShow}
          filters={filters}
          setFilterData={setFilter}
          handleClean={handleClean}
        />
      )}

      <WarningModal
        termsLabel={`He leído y acepto los terminos y condiciones de transporte de ${ticketName}`}
        termUrl={ticketUrl}
        isWarning={isWarning}
        setIsWarning={setIsWarning}
        onClick={handleClickModal}
        type={isPerSeat ? 'perSeat' : 'normal'}
      />

      <Alert
        type='warning'
        isShow={showIntertional}
        setShow={setShowInternational}
        onClickButton={() => setShowInternational(false)}
        title='Boleto con conexión'>
        <p style={{ textAlign: 'center' }}>
          Al comprar un boleto con conexión deberás abordar dos autobuses para
          llegar hasta tu destino final.
        </p>
        <p style={{ textAlign: 'center' }}>
          El costo del boleto incluye ambos traslados.
        </p>
      </Alert>
    </section>
  )
}

export default BuyTickets

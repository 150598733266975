export const CaretDownIcon = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'>
      <path
        d='M18.7098 8.21001C18.6169 8.11628 18.5063 8.04189 18.3844 7.99112C18.2625 7.94035 18.1318 7.91422 17.9998 7.91422C17.8678 7.91422 17.7371 7.94035 17.6152 7.99112C17.4934 8.04189 17.3828 8.11628 17.2898 8.21001L12.7098 12.79C12.6169 12.8837 12.5063 12.9581 12.3844 13.0089C12.2625 13.0597 12.1318 13.0858 11.9998 13.0858C11.8678 13.0858 11.7371 13.0597 11.6152 13.0089C11.4934 12.9581 11.3828 12.8837 11.2898 12.79L6.70982 8.21001C6.61685 8.11628 6.50625 8.04189 6.38439 7.99112C6.26253 7.94035 6.13183 7.91422 5.99982 7.91422C5.8678 7.91422 5.7371 7.94035 5.61524 7.99112C5.49338 8.04189 5.38278 8.11628 5.28982 8.21001C5.10356 8.39737 4.99902 8.65083 4.99902 8.91501C4.99902 9.1792 5.10356 9.43265 5.28982 9.62001L9.87982 14.21C10.4423 14.7718 11.2048 15.0874 11.9998 15.0874C12.7948 15.0874 13.5573 14.7718 14.1198 14.21L18.7098 9.62001C18.8961 9.43265 19.0006 9.1792 19.0006 8.91501C19.0006 8.65083 18.8961 8.39737 18.7098 8.21001Z'
        fill='#29348F'
      />
    </svg>
  )
}

export const GrayCarretIcon = () => {
  return (
    <svg
      className='caret'
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'>
      <path
        d='M18.7098 8.20998C18.6169 8.11625 18.5063 8.04186 18.3844 7.99109C18.2625 7.94032 18.1318 7.91418 17.9998 7.91418C17.8678 7.91418 17.7371 7.94032 17.6152 7.99109C17.4934 8.04186 17.3828 8.11625 17.2898 8.20998L12.7098 12.79C12.6169 12.8837 12.5063 12.9581 12.3844 13.0089C12.2625 13.0596 12.1318 13.0858 11.9998 13.0858C11.8678 13.0858 11.7371 13.0596 11.6152 13.0089C11.4934 12.9581 11.3828 12.8837 11.2898 12.79L6.70982 8.20998C6.61685 8.11625 6.50625 8.04186 6.38439 7.99109C6.26253 7.94032 6.13183 7.91418 5.99982 7.91418C5.8678 7.91418 5.7371 7.94032 5.61524 7.99109C5.49338 8.04186 5.38278 8.11625 5.28982 8.20998C5.10356 8.39734 4.99902 8.6508 4.99902 8.91498C4.99902 9.17917 5.10356 9.43262 5.28982 9.61998L9.87982 14.21C10.4423 14.7718 11.2048 15.0873 11.9998 15.0873C12.7948 15.0873 13.5573 14.7718 14.1198 14.21L18.7098 9.61998C18.8961 9.43262 19.0006 9.17917 19.0006 8.91498C19.0006 8.6508 18.8961 8.39734 18.7098 8.20998Z'
        fill='#898989'
      />
    </svg>
  )
}

import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { useForm } from 'react-hook-form'
import { ButtonBlue } from '../../components/buttons'
import { Rating } from 'react-simple-star-rating'
import { Alert } from '../../components/bottom-panel/Alert'

import Select from 'react-select'

// import { Navigate } from 'react-router-dom'
import './styles.qualification.css'
import { TextAreaMaterial } from '../../components/imputs/TextArea'
// import { post } from '../../api/axiosHelper'
import axios from 'axios'
import { busiAPi } from '../../utils/urls'

const stylesToSelect = {
  container: (baseStyles) => ({
    ...baseStyles,
    width: '100%',
    boxShadow: 'none',
    outline: 'none',
    zIndex: '20px',
  }),
  control: (baseStyles, state) => ({
    ...baseStyles,
    border: 'none',
    borderRadius: '0px',
    borderBottom: '1px solid #29348f',
    boxShadow: 'none',
    background: 'transparent',
  }),
  valueContainer: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
    height: '32px',
  }),
  input: (baseStyles) => ({
    ...baseStyles,
    padding: 0,
    margin: 0,
  }),
  indicatorSeparator: (baseStyles) => ({
    ...baseStyles,
    display: 'none',
  }),
  dropdownIndicator: (baseStyles) => ({
    ...baseStyles,
    padding: '2px',
    color: '#29348f',
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    color: '#29348f',
  }),
  menuList: (baseStyles) => ({
    ...baseStyles,
    zIndex: 66,
    // position: 'relative',
    // display: 'inline',
  }),
  option: (baseStyles) => ({
    ...baseStyles,
    color: '#29348f',
    zIndex: 666,
    fontFamily: 'Kufam',
    fontSize: '15px',
    fontWeight: '500',
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    color: '#29348f',
  }),
}

const options = [
  { value: 'drive', label: 'Línea' },
  { value: 'bus', label: 'Autobús' },
  { value: 'app', label: 'Aplicación' },
]

const QualificationPage = () => {
  const navigate = useNavigate()
  const { state } = useLocation()

  const [rateLine, setRateLine] = useState(0)
  const [rateBus, setRateBus] = useState(0)
  const [rateApp, setRateApp] = useState(0)

  const [showPanel, setShowPanel] = useState(false)
  const [err, setErr] = useState()

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()

  const onSubmit = async (data) => {
    const bodyReview = {
      lineScore: rateLine,
      driverScore: 0,
      busScore: rateBus,
      appScore: rateApp,
      commentType: data.about,
      // comment: data.comment=== ""?null: data.comment,
      comment: data.comment,
      tripId: state.tripId,
    }

    try {
      const { data: info } = await axios.post(
        `${busiAPi}/review/passenger`,
        bodyReview,
        { headers: { Authorization: localStorage.getItem('token') } },
      )

      if (info.success) {
        setShowPanel(true)
      }
    } catch (error) {
      console.log({ error })
      const errorMessage = error?.response?.data?.error?.message
      if (
        errorMessage === 'No puedes calificar un viaje que no has realizado'
      ) {
        navigate('/main/ticket')
      } else {
        setErr('Tenemos un problema, intenta más tarde')
      }
    }
  }

  return (
    <section className='pages-qualification'>
      <header className='header_container'>
        <h1 className='pages-qualification_title'>
          ¡Tu opinión <br /> es importante!
        </h1>

        <p className='pages-qualification_subtitle'>
          Con esta información podemos mejorar el servicio prestado
        </p>
      </header>

      <form
        className='pages-qualification_body'
        onSubmit={handleSubmit(onSubmit)}>
        {/* <div className='background_image'></div> */}

        <section className='main_data'>
          <div className='pages-qualification-middle-qualification1'>
            <p>Línea:</p>

            <Rating
              onClick={(rate) => setRateLine(rate)}
              transition
              SVGstorkeWidth={1}
              SVGstrokeColor='#FFC600'
              emptyColor='transparent'
              size={35}
            />
          </div>

          <div className='pages-qualification-middle-qualification2'>
            <p>Autobús:</p>

            <Rating
              onClick={(rate) => setRateBus(rate)}
              transition
              SVGstorkeWidth={1}
              SVGstrokeColor='#FFC600'
              emptyColor='transparent'
              size={35}
            />
          </div>

          <div className='pages-qualification-middle-qualification2'>
            <p>Aplicación:</p>

            <Rating
              onClick={(rate) => setRateApp(rate)}
              transition
              SVGstorkeWidth={1}
              SVGstrokeColor='#FFC600'
              emptyColor='transparent'
              size={35}
            />
          </div>

          <div className='qualification_input'>
            <Select
              {...register('about', {
                required: false,
              })}
              placeholder='¿Sobre qué es tu comentario?'
              options={options}
              styles={stylesToSelect}
              onChange={(data) => {
                setValue('about', data.value)
              }}
            />
          </div>

          <div className='qualification_input'>
            <TextAreaMaterial
              register={{
                ...register('comment', {
                  required: false,
                }),
              }}
              label='Comentarios adicionales (opcional)'
            />
          </div>

          {err && (
            <>
              <p className='error_message'>{err}</p>
            </>
          )}
        </section>

        <div className='action_buttom'>
          <ButtonBlue
            type='submit'
            disabled={rateApp === 0 || rateLine === 0 || rateBus === 0}>
            ¡Calificar!
          </ButtonBlue>
        </div>
      </form>

      <Alert
        type='bus'
        isShow={showPanel}
        setShow={setShowPanel}
        onClickButton={() =>
          window.location.replace(
            'https://play.google.com/store/apps/details?id=com.busitransporte.passengers&_gl=1*1ni48su*_ga*MTY5MjA1NDk0LjE3MDgxMDg1MDE.*_ga_V9K47ZG8NP*MTcxMDM1MTkwOC41LjAuMTcxMDM1MTkwOC42MC4wLjA',
          )
        }
        onSecondClickButton={() => navigate('/main/search')}>
        <h2 className='title_bus'>
          Comparte tu opinión para que más pasajeros disfruten{' '}
        </h2>
        <p className='text_bus'>¡Califícanos en la tienda de aplicaciones!</p>
      </Alert>
    </section>
  )
}

export default QualificationPage

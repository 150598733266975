import { useNavigate } from 'react-router-dom'
import { Alert } from '../bottom-panel/Alert'

export const ErrorPage = () => {
  const navegate = useNavigate()
  return (
    <Alert type='error' onClickButton={() => navegate('/main/ticket')}>
      <h1>Tenemos un error</h1>
    </Alert>
  )
}

import { Divider } from '@mui/material'
import { Facebook, Ig, Linkedin, Tiktoks, Twitter } from '../icons/SocialMedia'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'

export const Footer = () => {
  return (
    <footer className='pages-main-bottom' style={{ position: 'relative' }}>
      <section style={{ padding: '12px 20px' }}>
        <section style={{ display: 'flex', gap: 30, flexWrap: 'wrap' }}>
          <div>
            <h3 className='footer_title_text'>Pasajeros</h3>
            <a
              href='https://play.google.com/store/apps/details?id=com.busitransporte.passengers'
              target='_blank'
              rel='noreferrer'
              className='footer_regular_text'>
              Descargar Google Play
            </a>

            <a
              href='https://apps.apple.com/us/app/busi-boletos/id6502417874'
              target='_blank'
              rel='noreferrer'
              className='footer_regular_text'>
              Descargar App Store
            </a>
          </div>

          <div>
            <h3 className='footer_title_text'>Operadores</h3>
            <a
              href='https://play.google.com/store/apps/details?id=com.busitransporte.collectors&hl=es_EC'
              target='_blank'
              rel='noreferrer'
              className='footer_regular_text'>
              Descargar Google Play
            </a>

            <a
              href='https://drive.google.com/file/d/1zlPh8Kq5gBzVSTh-djG9u2D_A2u6sbU9/view'
              target='_blank'
              rel='noreferrer'
              className='footer_regular_text'>
              Android 10 o anteriores
            </a>
          </div>

          <div>
            <h3 className='footer_title_text'>Contáctanos</h3>
            <a
              href='https://api.whatsapp.com/send/?phone=584242229399&text&type=phone_number&app_absent=0'
              target='_blank'
              rel='noreferrer'
              className='footer_regular_text'>
              Servicios de alquiler y corporativo
            </a>
            <a
              href='https://api.whatsapp.com/send/?phone=584242229359&text&type=phone_number&app_absent=0'
              target='_blank'
              rel='noreferrer'
              className='footer_regular_text'>
              Atención al pasajero
            </a>
          </div>

          <div>
            <h3 className='footer_title_text'>Redes sociales</h3>
            <div style={{ display: 'flex', gap: '10px' }}>
              <a
                href='https://www.tiktok.com/@busivzla'
                target='_blank'
                rel='noreferrer'
                // className='footer_regular_text'
              >
                <Tiktoks />
              </a>

              <a
                href='https://www.facebook.com/people/Busivzla/100085129077351/'
                target='_blank'
                rel='noreferrer'>
                <Facebook />
              </a>

              <a
                href='https://www.instagram.com/busivzla/?hl=es'
                target='_blank'
                rel='noreferrer'>
                <Ig />
              </a>

              <a
                href='https://twitter.com/BusiVzla'
                target='_blank'
                rel='noreferrer'>
                <Twitter />
              </a>

              <a
                href='https://www.linkedin.com/company/busitransporte/'
                target='_blank'
                rel='noreferrer'>
                <Linkedin />
              </a>
            </div>
          </div>
        </section>

        <Divider
          style={{ margin: '12px 0px', borderColor: 'white' }}
          variant='middle'
        />

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <p className='footer_regular_text'>
            Busi © Todos los derechos reservados. <br />
            Hecho por el equipo Busi con{' '}
            <FavoriteBorderIcon color='gold' fontSize='10px' />
          </p>
        </div>
      </section>
    </footer>
  )
}

export const SuccessReserveIcon = ({ width = '153', height = '153' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 173 173'
      fill='none'>
      <circle
        cx='86.1623'
        cy='86.1623'
        r='86.1623'
        fill='#F08B22'
        fillOpacity='0.5'
      />
      <circle cx='86.1622' cy='86.1622' r='66.5191' fill='#F08B22' />
      <path
        d='M76.5095 105C75.7142 105 74.9266 104.848 74.1919 104.551C73.4572 104.254 72.7898 103.819 72.2279 103.271L59 90.3875L64.0476 85.471L76.5095 97.6149L108.952 66L114 70.9164L80.7912 103.271C80.2292 103.819 79.5618 104.254 78.8271 104.551C78.0924 104.848 77.3048 105 76.5095 105Z'
        fill='white'
      />
    </svg>
  )
}

export const SuccessIcon = ({ width = '153', height = '153' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 173 173'
      fill='none'>
      <circle
        cx='86.1623'
        cy='86.1623'
        r='86.1623'
        fill='#3BD4AE'
        fillOpacity='0.5'
      />

      <circle cx='86.1622' cy='86.1622' r='66.5191' fill='#3BD4AE' />

      <path
        d='M76.5095 105C75.7142 105 74.9266 104.848 74.1919 104.551C73.4572 104.254 72.7898 103.819 72.2279 103.271L59 90.3875L64.0476 85.471L76.5095 97.6149L108.952 66L114 70.9164L80.7912 103.271C80.2292 103.819 79.5618 104.254 78.8271 104.551C78.0924 104.848 77.3048 105 76.5095 105Z'
        fill='white'
      />
    </svg>
  )
}

export const WarningIcon = ({ width = '153', height = '153' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 125 125'
      fill='none'>
      <circle cx='62.5' cy='62.5' r='62.5' fill='#FFC600' fillOpacity='0.5' />
      <circle cx='62.4998' cy='62.5' r='48.2513' fill='#FFFFFE' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M63.4703 11.606C91.7091 11.606 114.609 34.5062 114.609 62.7449C114.609 90.9837 91.7091 113.884 63.4703 113.884C35.2315 113.884 12.3313 90.9837 12.3313 62.7449C12.3313 34.5062 35.2315 11.606 63.4703 11.606ZM63.4453 86.1654C66.2692 86.1654 68.5592 88.4554 68.5592 91.2793C68.5592 94.1032 66.2692 96.3932 63.4453 96.3932C60.6214 96.3932 58.3314 94.1032 58.3314 91.2793C58.3314 88.4554 60.6214 86.1654 63.4453 86.1654ZM58.2823 79.4621V31.3794C58.2823 29.8775 59.5181 28.6521 61.0096 28.6521H65.931C67.4225 28.6521 68.6583 29.8879 68.6583 31.3794V79.4621C68.6583 80.9536 67.4329 82.1894 65.931 82.1894H61.0096C59.5076 82.1894 58.2823 80.9641 58.2823 79.4621Z'
        fill='#FFC600'
      />
    </svg>
  )
}

export const ErrorIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='153'
      height='153'
      viewBox='0 0 127 127'
      fill='none'>
      <circle
        cx='63.1623'
        cy='63.1623'
        r='63.1623'
        fill='#D31E4A'
        fillOpacity='0.5'
      />
      <circle cx='63.1622' cy='63.1622' r='48.7626' fill='#D31E4A' />
      <mask id='path-3-inside-1_726_1231' fill='white'>
        <path d='M82.8359 45.5829L80.5037 43.2507L63.0433 60.7111L45.5828 43.2507L43.2506 45.5829L60.711 63.0433L43.2506 80.5038L45.5828 82.836L63.0433 65.3756L80.5037 82.836L82.8359 80.5038L65.3755 63.0433L82.8359 45.5829Z' />
      </mask>
      <path
        d='M82.8359 45.5829L80.5037 43.2507L63.0433 60.7111L45.5828 43.2507L43.2506 45.5829L60.711 63.0433L43.2506 80.5038L45.5828 82.836L63.0433 65.3756L80.5037 82.836L82.8359 80.5038L65.3755 63.0433L82.8359 45.5829Z'
        fill='white'
      />
      <path
        d='M82.8359 45.5829L111.12 73.8672L139.404 45.5829L111.12 17.2986L82.8359 45.5829ZM80.5037 43.2507L108.788 14.9664L80.5037 -13.3179L52.2194 14.9664L80.5037 43.2507ZM63.0433 60.7111L34.759 88.9954L63.0433 117.28L91.3275 88.9954L63.0433 60.7111ZM45.5828 43.2507L73.8671 14.9664L45.5828 -13.3179L17.2986 14.9664L45.5828 43.2507ZM43.2506 45.5829L14.9663 17.2986L-13.3179 45.5829L14.9663 73.8672L43.2506 45.5829ZM60.711 63.0433L88.9953 91.3276L117.28 63.0433L88.9953 34.7591L60.711 63.0433ZM43.2506 80.5038L14.9663 52.2195L-13.318 80.5038L14.9663 108.788L43.2506 80.5038ZM45.5828 82.836L17.2986 111.12L45.5828 139.405L73.8671 111.12L45.5828 82.836ZM63.0433 65.3756L91.3275 37.0913L63.0433 8.80703L34.759 37.0913L63.0433 65.3756ZM80.5037 82.836L52.2194 111.12L80.5037 139.405L108.788 111.12L80.5037 82.836ZM82.8359 80.5038L111.12 108.788L139.404 80.5038L111.12 52.2195L82.8359 80.5038ZM65.3755 63.0433L37.0912 34.7591L8.80696 63.0433L37.0912 91.3276L65.3755 63.0433ZM111.12 17.2986L108.788 14.9664L52.2194 71.5349L54.5517 73.8672L111.12 17.2986ZM52.2194 14.9664L34.759 32.4268L91.3275 88.9954L108.788 71.5349L52.2194 14.9664ZM91.3275 32.4268L73.8671 14.9664L17.2986 71.5349L34.759 88.9954L91.3275 32.4268ZM17.2986 14.9664L14.9663 17.2986L71.5349 73.8672L73.8671 71.5349L17.2986 14.9664ZM14.9663 73.8672L32.4268 91.3276L88.9953 34.7591L71.5349 17.2986L14.9663 73.8672ZM32.4268 34.7591L14.9663 52.2195L71.5349 108.788L88.9953 91.3276L32.4268 34.7591ZM14.9663 108.788L17.2986 111.12L73.8671 54.5517L71.5349 52.2195L14.9663 108.788ZM73.8671 111.12L91.3275 93.6598L34.759 37.0913L17.2986 54.5517L73.8671 111.12ZM34.759 93.6598L52.2194 111.12L108.788 54.5517L91.3275 37.0913L34.759 93.6598ZM108.788 111.12L111.12 108.788L54.5517 52.2195L52.2194 54.5517L108.788 111.12ZM111.12 52.2195L93.6598 34.7591L37.0912 91.3276L54.5517 108.788L111.12 52.2195ZM93.6598 91.3276L111.12 73.8672L54.5517 17.2986L37.0912 34.7591L93.6598 91.3276Z'
        fill='white'
        mask='url(#path-3-inside-1_726_1231)'
      />
    </svg>
  )
}

export const Bus = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='125'
      height='125'
      viewBox='0 0 125 125'
      fill='none'>
      <circle cx='62.5' cy='62.5' r='62.5' fill='#29348F' fillOpacity='0.5' />
      <path
        d='M110.751 62.4998C110.751 89.1483 89.1483 110.751 62.4998 110.751C35.8514 110.751 14.2485 89.1483 14.2485 62.4998C14.2485 35.8514 35.8514 14.2485 62.4998 14.2485C89.1483 14.2485 110.751 35.8514 110.751 62.4998Z'
        fill='#202A76'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M63.4703 11.606C91.7091 11.606 114.609 34.5062 114.609 62.7449C114.609 90.9837 91.7091 113.884 63.4703 113.884C35.2315 113.884 12.3313 90.9837 12.3313 62.7449C12.3313 34.5062 35.2315 11.606 63.4703 11.606ZM63.4453 86.1654C66.2692 86.1654 68.5592 88.4554 68.5592 91.2793C68.5592 94.1032 66.2692 96.3932 63.4453 96.3932C60.6214 96.3932 58.3314 94.1032 58.3314 91.2793C58.3314 88.4554 60.6214 86.1654 63.4453 86.1654ZM58.2823 79.4621V31.3794C58.2823 29.8775 59.5181 28.6521 61.0096 28.6521H65.931C67.4225 28.6521 68.6583 29.8879 68.6583 31.3794V79.4621C68.6583 80.9536 67.4329 82.1894 65.931 82.1894H61.0096C59.5076 82.1894 58.2823 80.9641 58.2823 79.4621Z'
        fill='#202A76'
      />
    </svg>
  )
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gender_radio_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  /* margin: 20px 0px; */
  margin-top: 10px;
}

[type='radio']:checked + span:after,
[type='radio'].with-gap:checked + span:after {
  background-color: #29348f;
}
[type='radio']:checked + span:after,
[type='radio'].with-gap:checked + span:before,
[type='radio'].with-gap:checked + span:after {
  border: 2px solid #29348f;
}

[type='radio'] + span:before,
[type='radio'] + span:after {
  left: 80px;
}

[type='radio']:not(:checked) + span,
[type='radio']:checked + span {
  padding-left: unset;
}
`, "",{"version":3,"sources":["webpack://./src/components/imputs/forms-gender/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;EACX,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;;EAEE,yBAAyB;AAC3B;AACA;;;EAGE,yBAAyB;AAC3B;;AAEA;;EAEE,UAAU;AACZ;;AAEA;;EAEE,mBAAmB;AACrB","sourcesContent":[".gender_radio_container {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  /* margin: 20px 0px; */\n  margin-top: 10px;\n}\n\n[type='radio']:checked + span:after,\n[type='radio'].with-gap:checked + span:after {\n  background-color: #29348f;\n}\n[type='radio']:checked + span:after,\n[type='radio'].with-gap:checked + span:before,\n[type='radio'].with-gap:checked + span:after {\n  border: 2px solid #29348f;\n}\n\n[type='radio'] + span:before,\n[type='radio'] + span:after {\n  left: 80px;\n}\n\n[type='radio']:not(:checked) + span,\n[type='radio']:checked + span {\n  padding-left: unset;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useQuery } from '@tanstack/react-query'
import { get } from '../api/axiosHelper'

export const useGetLines = () => {
  return useQuery({
    queryKey: ['getLines'],
    queryFn: () => get('/line'),
  })
}

export const useGetLinesByReview = () => {
  return useQuery({
    queryKey: ['getLinesReview'],
    queryFn: () => get('/line/passenger'),
  })
}

export const useGetOneLineByReview = ({ busLineId }) => {
  return useQuery({
    queryKey: ['getOneLineReview'],
    queryFn: () => get(`/line/${busLineId}/review`),
    retryDelay: 20000,
  })
}

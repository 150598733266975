// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ticket-services {
  display: flex;
  flex-direction: row;

  align-items: flex-end;
  justify-content: center;
  background-color: #f0eeee99;
  border-radius: 10px;
}
.ticket-service-details {
  display: flex;
  flex-direction: column;
  /* width: 45px;
  height: 45px; */
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.ticket-service-details-image {
  min-width: 13px;
  min-height: 13px;
}

.ticket-service-details-text {
  color: #29348f;
  text-align: center;
  font-family: Urbanist;
  font-weight: 700;
  line-height: normal;
  font-size: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Tickets/Services/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;;EAEnB,qBAAqB;EACrB,uBAAuB;EACvB,2BAA2B;EAC3B,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,sBAAsB;EACtB;iBACe;EACf,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,qBAAqB;EACrB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".ticket-services {\n  display: flex;\n  flex-direction: row;\n\n  align-items: flex-end;\n  justify-content: center;\n  background-color: #f0eeee99;\n  border-radius: 10px;\n}\n.ticket-service-details {\n  display: flex;\n  flex-direction: column;\n  /* width: 45px;\n  height: 45px; */\n  align-items: center;\n  justify-content: center;\n  padding: 5px;\n}\n\n.ticket-service-details-image {\n  min-width: 13px;\n  min-height: 13px;\n}\n\n.ticket-service-details-text {\n  color: #29348f;\n  text-align: center;\n  font-family: Urbanist;\n  font-weight: 700;\n  line-height: normal;\n  font-size: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-features-main {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 10px 0;
  padding: 0;
}

.app-features-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.app-features-prompt {
  display: block;
  color: white;
  text-align: center;
  margin-top: 0.5rem;
  height: 16px;
}

.app-features-active {
  filter: brightness(0.5) sepia(1) hue-rotate(12deg) saturate(6);
}
`, "",{"version":3,"sources":["webpack://./src/components/NavMenu/styles.navbar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,6BAA6B;EAC7B,cAAc;EACd,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,8DAA8D;AAChE","sourcesContent":[".app-features-main {\n  display: flex;\n  align-items: center;\n  flex-direction: row;\n  justify-content: space-evenly;\n  margin: 10px 0;\n  padding: 0;\n}\n\n.app-features-image {\n  display: block;\n  margin-left: auto;\n  margin-right: auto;\n}\n\n.app-features-prompt {\n  display: block;\n  color: white;\n  text-align: center;\n  margin-top: 0.5rem;\n  height: 16px;\n}\n\n.app-features-active {\n  filter: brightness(0.5) sepia(1) hue-rotate(12deg) saturate(6);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

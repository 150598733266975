import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Capacitor } from '@capacitor/core'
import {
  // ActionPerformed,
  // PushNotificationSchema,
  PushNotifications,
  // Token,
} from '@capacitor/push-notifications'

// COMPONENTS
import { SearchBar } from '../../components/SearchBar'
import { TicketGrayIcon } from '../../components/icons/Ticket'
import { Bus } from '../../components/ui/Loader/Bus'
import { Alert } from '../../components/bottom-panel/Alert'

// ICONS
import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star'
import { ArrowForwardIcon } from '../../components/icons/ArrowForward'

// HOOKS
import { useGetLinesByReview } from '../../hooks/useLines'
import { useGetAppVersion } from '../../hooks/useSettingsApi'
import { useMeasureStorage, useStore } from '../../store'

// UTILITIES
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { appVersion } from '../../utils/version'

// STYLES
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Rating,
  Stack,
  useMediaQuery,
} from '@mui/material'
import './styles.search.css'
// import { Footer } from '../../components/Footer'

dayjs.extend(isSameOrAfter)

export const SearchPage = () => {
  const navigate = useNavigate()
  const maxW990 = useMediaQuery('(max-width:990px)')
  const minW800 = useMediaQuery('(min-width:800px)')
  const minW584 = useMediaQuery('(min-width:584px)')

  const { setSelectedLine } = useStore(({ setSelectedLine }) => ({
    setSelectedLine,
  }))
  const { mainHeight, headerHeight } = useMeasureStorage()

  const { data: info, isLoading, isError } = useGetLinesByReview()
  const { data: version } = useGetAppVersion()

  const [showPanel, setShowPanel] = useState(false)

  // #region UPDATE APP
  useEffect(() => {
    if (!version || Capacitor.getPlatform() === 'web')
      return setShowPanel(false)

    let incomingVersion = version?.data.value.split('.').join('')

    let localVersion = appVersion.split('.').join('')

    // incomingVersion =
    //   localVersion[1] !== '0' && incomingVersion[1] === '0'
    //     ? incomingVersion.slice(0, -1)
    //     : incomingVersion

    localVersion =
      incomingVersion[1] > localVersion[1]
        ? localVersion.slice(0, -1)
        : localVersion

    const isSame = Number(incomingVersion) <= Number(localVersion)
    // const isSame = version?.data.value <= appVersion

    if (isSame) return setShowPanel(false)

    setShowPanel(true)
  }, [version])

  // #region PUSH NOTIFICATION
  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      PushNotifications.requestPermissions().then((result) => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register()
        } else {
          // Show some error
        }
      })

      PushNotifications.addListener('registration', ({ value }) => {
        // let token = value
        // alert('Push registration success, token: ' + value)
      })

      PushNotifications.addListener('registrationError', (error) => {
        // alert('Error on registration: ' + JSON.stringify(error))
      })

      PushNotifications.addListener(
        'pushNotificationReceived',
        (notification) => {
          // alert('Push received: ' + JSON.stringify(notification))
        },
      )

      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification) => {
          // alert('Push action performed: ' + JSON.stringify(notification))
        },
      )
    }
  }, [])

  const handleDetail = (id, name, logo) => {
    setSelectedLine({ id, name, logo })

    setTimeout(() => {
      navigate(`/main/search/detail/${id}`)
    }, 580)
  }

  if (isLoading) {
    return <Bus />
  }

  if (isError) {
    return (
      <section className='ticket-container_error'>
        <TicketGrayIcon />
        <p className='no_data_text'>Tenemos un problema intenta más tarde</p>
      </section>
    )
  }
  console.log({ maxW990, minW584 })

  if (minW800) {
    return (
      <section
        className='pages-search-main'
        style={{
          height: 'calc(100% - 134px)',
          minHeight: '600px',
          bottom: 0,
          borderRadius: 0,
          background: 'transparent',
        }}>
        <SearchBar />

        <Box style={{ display: 'grid', justifyItems: 'center' }}>
          <Box
            component='section'
            paddingX='22px'
            paddingTop='30px'
            maxWidth={maxW990 ? '600px' : '60%'}
            width='100%'>
            <h2
              style={{
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '23.4px',
                textAlign: 'left',
              }}>
              Nuestros aliados
            </h2>

            <Divider variant='fullWidth' style={{ marginBottom: '16px' }} />

            <Box
              style={{
                height: `calc(100vh - ${mainHeight + headerHeight + 198}px - ${
                  Capacitor.getPlatform() === 'ios' ? 66 : 0
                }px)`,
                overflow: 'scroll',
                paddingBottom: '15px',
                display: 'flex',
                flexWrap: 'wrap',
                gap: '14px',
                justifyContent: 'center',
                minHeight: '430px',
              }}>
              {info.data.map(({ id, name, score, logo }) => {
                return (
                  <Card
                    key={id}
                    style={{
                      borderRadius: '14px',
                      minHeight: '50px',
                      maxHeight: '90px',
                      minWidth: '250px',
                      maxWidth: '260px',
                      width: '100%',
                    }}>
                    <CardActionArea
                      style={{ height: '100%' }}
                      onClick={() => handleDetail(id, name, logo)}>
                      <CardContent
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          // height: '70px',
                        }}>
                        <img
                          style={{
                            width: name.includes('Occidente')
                              ? '95px'
                              : name.includes('Sur')
                              ? '37px'
                              : 'inherit',
                            maxWidth: '95px',
                            maxHeight: '40px',
                          }}
                          src={logo}
                          alt={name}
                        />

                        <Stack direction='row'>
                          <Rating
                            name='lines'
                            value={Number(score)}
                            precision={0.5}
                            readOnly
                            icon={<StarIcon fontSize='inherit' color='gold' />}
                            emptyIcon={
                              <StarBorderIcon fontSize='inherit' color='gold' />
                            }
                          />
                          <ArrowForwardIcon />
                        </Stack>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                )
              })}
            </Box>
          </Box>
        </Box>

        {/* <Footer /> */}

        <Alert
          type='bus'
          isShow={showPanel}
          setShow={setShowPanel}
          buttonText='Actualizar'
          keepOpen={true}
          onClickButton={() =>
            window.location.replace(
              Capacitor.getPlatform() === 'ios'
                ? 'https://apps.apple.com/us/app/busi-boletos/id6502417874'
                : 'https://play.google.com/store/apps/details?id=com.busitransporte.passengers',
              // Capacitor.getPlatform() === 'android'
              //   ? 'https://play.google.com/store/apps/details?id=com.busitransporte.passengers&_gl=1*1ni48su*_ga*MTY5MjA1NDk0LjE3MDgxMDg1MDE.*_ga_V9K47ZG8NP*MTcxMDM1MTkwOC41LjAuMTcxMDM1MTkwOC42MC4wLjA'
              //   : 'https://apps.apple.com/us/app/busi-boletos/id6502417874',
            )
          }
          showSecondButtom={false}>
          <h2 className='title_bus'>¡Actualiza tu app!</h2>
          <p className='text_bus' style={{ textAlign: 'center' }}>
            Descarga la última versión de Busi Boletos para disfrutar de las
            mejoras que traemos para ti.
          </p>
        </Alert>
      </section>
    )
  }

  return (
    <section className='pages-search-main'>
      <SearchBar />

      <Box style={{ display: 'grid', justifyItems: 'center' }}>
        <Box
          component='section'
          paddingX='22px'
          paddingTop='90px'
          maxWidth='600px'
          width='100%'>
          <h2
            style={{
              fontSize: '16px',
              fontWeight: '700',
              lineHeight: '23.4px',
              textAlign: 'left',
            }}>
            Nuestros aliados
          </h2>

          <Divider variant='fullWidth' style={{ marginBottom: '16px' }} />

          <Box
            style={{
              height: `calc(100vh - ${
                mainHeight + headerHeight + 90 - 31
              }px - ${Capacitor.getPlatform() === 'ios' ? 66 : 0}px)`,
              overflow: 'scroll',
              paddingBottom: '15px',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '14px',
              justifyContent: 'center',
            }}>
            {info.data.map(({ id, name, score, logo }) => {
              return (
                <Card
                  key={id}
                  style={{
                    borderRadius: '14px',
                    minHeight: '50px',
                    maxHeight: '90px',
                    minWidth: '250px',
                    maxWidth: minW584 ? '260px' : '400px',
                    width: '100%',
                  }}>
                  <CardActionArea
                    style={{ height: '100%' }}
                    onClick={() => handleDetail(id, name, logo)}>
                    <CardContent
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        // height: '70px',
                      }}>
                      <img
                        style={{
                          width: name.includes('Occidente')
                            ? '95px'
                            : name.includes('Sur')
                            ? '37px'
                            : 'inherit',
                          maxWidth: '95px',
                          maxHeight: '40px',
                        }}
                        src={logo}
                        alt={name}
                      />

                      <Stack direction='row'>
                        <Rating
                          name='lines'
                          value={Number(score)}
                          precision={0.5}
                          readOnly
                          icon={<StarIcon fontSize='inherit' color='gold' />}
                          emptyIcon={
                            <StarBorderIcon fontSize='inherit' color='gold' />
                          }
                        />
                        <ArrowForwardIcon />
                      </Stack>
                    </CardContent>
                  </CardActionArea>
                </Card>
              )
            })}
          </Box>
        </Box>
      </Box>

      <Alert
        type='bus'
        isShow={showPanel}
        setShow={setShowPanel}
        buttonText='Actualizar'
        keepOpen={true}
        onClickButton={() =>
          window.location.replace(
            Capacitor.getPlatform() === 'ios'
              ? 'https://apps.apple.com/us/app/busi-boletos/id6502417874'
              : 'https://play.google.com/store/apps/details?id=com.busitransporte.passengers',
            // Capacitor.getPlatform() === 'android'
            //   ? 'https://play.google.com/store/apps/details?id=com.busitransporte.passengers&_gl=1*1ni48su*_ga*MTY5MjA1NDk0LjE3MDgxMDg1MDE.*_ga_V9K47ZG8NP*MTcxMDM1MTkwOC41LjAuMTcxMDM1MTkwOC42MC4wLjA'
            //   : 'https://apps.apple.com/us/app/busi-boletos/id6502417874',
          )
        }
        showSecondButtom={false}>
        <h2 className='title_bus'>¡Actualiza tu app!</h2>
        <p className='text_bus' style={{ textAlign: 'center' }}>
          Descarga la última versión de Busi Boletos para disfrutar de las
          mejoras que traemos para ti.
        </p>
      </Alert>
    </section>
  )
}

export const ArrowForwardIcon = ({
  onClick,
  fill = '#29348F',
  width = 25,
  height = 25,
}) => {
  return (
    <svg
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 25 25'
      fill='none'>
      <path
        d='M8.54856 5.53613C8.45131 5.63301 8.37421 5.74818 8.32169 5.875C8.26918 6.00182 8.24229 6.13779 8.24259 6.27505C8.24289 6.41232 8.27036 6.54817 8.32342 6.67476C8.37649 6.80135 8.45409 6.91619 8.55176 7.01264L13.3243 11.7646C13.422 11.861 13.4996 11.9759 13.5527 12.1025C13.6057 12.2291 13.6332 12.3649 13.6335 12.5022C13.6338 12.6394 13.6069 12.7754 13.5544 12.9022C13.5019 13.0291 13.4248 13.1442 13.3275 13.2411L8.57557 18.0137C8.47832 18.1105 8.40121 18.2257 8.3487 18.3525C8.29619 18.4794 8.2693 18.6153 8.2696 18.7526C8.2699 18.8899 8.29737 19.0257 8.35043 19.1523C8.40349 19.2789 8.4811 19.3937 8.57877 19.4902C8.774 19.6834 9.03778 19.7916 9.31247 19.791C9.58717 19.7904 9.85047 19.6811 10.0449 19.487L14.8072 14.704C15.3901 14.1179 15.7165 13.3243 15.7147 12.4977C15.7129 11.671 15.3831 10.8789 14.7977 10.2953L10.0147 5.53296C9.81943 5.33972 9.55566 5.23158 9.28096 5.23218C9.00626 5.23277 8.74296 5.34205 8.54856 5.53613Z'
        fill={fill}
      />
    </svg>
  )
}

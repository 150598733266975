import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
// import { Link } from '@mui/material'

import { Ticket } from '../../components/Tickets'
import { ArrowBack } from '../../components/icons/ArrowBack'
import { CaretDownIcon } from '../../components/icons/CaretDown'
import { CaretUpIcon } from '../../components/icons/CaretUp'

import { useStore } from '../../store'

import { ErrorPage } from '../../components/Error'
import { TicketGrayIcon } from '../../components/icons/Ticket'
import { Box, CardActionArea, Slide, useMediaQuery } from '@mui/material'

import './styles.all.css'

const AllTicketsPage = () => {
  const { pathname } = useLocation()
  const minW800 = useMediaQuery('(min-width:800px)')
  const navegate = useNavigate()

  const { tickets, setSelectedTickets } = useStore(
    ({ tickets, setSelectedTickets }) => ({
      tickets,
      setSelectedTickets,
    }),
  )
  const [showHistorical, setShowHistorical] = useState(true)

  const handleNavegate = (ticket) => {
    setSelectedTickets(ticket)
    setTimeout(() => {
      navegate(`/main/ticket/detail/${ticket.id}`, {
        state: { previousPath: pathname },
      })
    }, 550)
  }

  // if (tickets.length === 0) {
  //   return <Loader />
  // }

  // if (isError) {
  //   return (
  //     <section className='ticket-container_error'>
  //       <TicketGrayIcon />
  //       <p className='no_data_text'>Tenemos un problema intenta más tarde</p>
  //     </section>
  //   )
  // }

  if (!tickets) {
    return <ErrorPage />
  }

  if (minW800) {
    return (
      <Slide direction='up' in={true} timeout={200}>
        <Box
          component='section'
          style={{
            boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.25)',
            height: 'calc(100% - 134px)',
            minHeight: '800px',
            bottom: 0,
            borderRadius: 0,
            background: 'transparent',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          className='all-container'>
          <Box style={{ maxWidth: '600px', height: '100%', width: '100%' }}>
            <div
              onClick={() => navegate('/main/ticket')}
              className='all-header_container'>
              <ArrowBack />

              <h2>Todos mis boletos</h2>
            </div>

            <section className='all-tickets_container'>
              {tickets.length === 0 ? (
                <section className='center'>
                  <TicketGrayIcon />
                  <p className='no_data_text'>No tienes boletos.</p>
                </section>
              ) : (
                tickets
                  .filter(
                    ({ status }) =>
                      status !== 'used' &&
                      status !== 'expired' &&
                      status !== 'cancelled' &&
                      status !== 'reassigned' &&
                      status !== 'booked_expired',
                  )
                  .map((ticket) => {
                    return (
                      <CardActionArea
                        color='primary'
                        key={ticket.id + 1}
                        onClick={() => handleNavegate(ticket)}>
                        <Ticket
                          key={ticket.id}
                          status={ticket.status}
                          title={ticket.busLine}
                          logoUrl={ticket.logoUrl}
                          date={ticket.date}
                          startTime={ticket.startTime}
                          // endTime={ticket.endTime}
                          startLocation={ticket.startLocation}
                          endLocation={ticket.endLocation}
                          // availability={ticket.availability}
                          services={ticket.services}
                        />
                      </CardActionArea>
                    )
                  })
              )}
            </section>

            {tickets.filter(
              ({ status }) =>
                status === 'used' ||
                status === 'expired' ||
                status === 'cancelled' ||
                status === 'booked_expired',
            ).length !== 0 && (
              <>
                <div
                  style={{ paddingBottom: showHistorical ? 0 : '15px' }}
                  className='all-tickets-page_historical'
                  onClick={() => setShowHistorical(!showHistorical)}>
                  <p className='text'>Historial de boletos</p>

                  {showHistorical ? <CaretUpIcon /> : <CaretDownIcon />}
                </div>

                {showHistorical && (
                  <>
                    <hr className='divider' />

                    <section className='all-tickets_container_expired'>
                      {tickets
                        .filter(
                          ({ status }) =>
                            status === 'used' ||
                            status === 'expired' ||
                            status === 'cancelled' ||
                            status === 'booked_expired' ||
                            status === 'reassigned',
                        )
                        .map((ticket, i) => {
                          return (
                            <div key={i} onClick={() => handleNavegate(ticket)}>
                              <Ticket
                                status={ticket.status}
                                title={ticket.busLine}
                                logoUrl={ticket.logoUrl}
                                date={ticket.date}
                                startTime={ticket.startTime}
                                endTime={ticket.endTime}
                                startLocation={ticket.startLocation}
                                endLocation={ticket.endLocation}
                                // availability={ticket.availability}
                                services={ticket.services}
                              />
                            </div>
                          )
                        })}
                    </section>
                  </>
                )}
              </>
            )}
          </Box>
        </Box>
      </Slide>
    )
  }

  return (
    <Slide direction='up' in={true} timeout={200}>
      <Box
        component='section'
        style={{
          borderRadius: '20px 20px 0px 0px',
          background: '#f0eeee',
          boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.25)',
          position: 'relative',
          bottom: '18px',
          height: 'calc(100% + 18px)',
          overflow: 'scroll',
          display: 'flex',
          justifyContent: 'center',
        }}
        className='all-container'>
        <Box style={{ maxWidth: '600px', height: '100%', width: '100%' }}>
          <div
            onClick={() => navegate('/main/ticket')}
            className='all-header_container'>
            <ArrowBack />

            <h2>Todos mis boletos</h2>
          </div>

          <section className='all-tickets_container'>
            {tickets.length === 0 ? (
              <section className='center'>
                <TicketGrayIcon />
                <p className='no_data_text'>No tienes boletos.</p>
              </section>
            ) : (
              tickets
                .filter(
                  ({ status }) =>
                    status !== 'used' &&
                    status !== 'expired' &&
                    status !== 'cancelled' &&
                    status !== 'reassigned' &&
                    status !== 'booked_expired',
                )
                .map((ticket) => {
                  return (
                    <CardActionArea
                      color='primary'
                      key={ticket.id}
                      onClick={() => handleNavegate(ticket)}>
                      <Ticket
                        key={ticket.id}
                        status={ticket.status}
                        title={ticket.busLine}
                        logoUrl={ticket.logoUrl}
                        date={ticket.date}
                        startTime={ticket.startTime}
                        // endTime={ticket.endTime}
                        startLocation={ticket.startLocation}
                        endLocation={ticket.endLocation}
                        // availability={ticket.availability}
                        services={ticket.services}
                      />
                    </CardActionArea>
                  )
                })
            )}
          </section>

          {tickets.filter(
            ({ status }) =>
              status === 'used' ||
              status === 'expired' ||
              status === 'cancelled' ||
              status === 'booked_expired',
          ).length !== 0 && (
            <>
              <div
                style={{ paddingBottom: showHistorical ? 0 : '15px' }}
                className='all-tickets-page_historical'
                onClick={() => setShowHistorical(!showHistorical)}>
                <p className='text'>Historial de boletos</p>

                {showHistorical ? <CaretUpIcon /> : <CaretDownIcon />}
              </div>

              {showHistorical && (
                <>
                  <hr className='divider' />

                  <section className='all-tickets_container_expired'>
                    {tickets
                      .filter(
                        ({ status }) =>
                          status === 'used' ||
                          status === 'expired' ||
                          status === 'cancelled' ||
                          status === 'booked_expired' ||
                          status === 'reassigned',
                      )
                      .map((ticket, i) => {
                        return (
                          <div key={i} onClick={() => handleNavegate(ticket)}>
                            <Ticket
                              status={ticket.status}
                              title={ticket.busLine}
                              logoUrl={ticket.logoUrl}
                              date={ticket.date}
                              startTime={ticket.startTime}
                              endTime={ticket.endTime}
                              startLocation={ticket.startLocation}
                              endLocation={ticket.endLocation}
                              // availability={ticket.availability}
                              services={ticket.services}
                            />
                          </div>
                        )
                      })}
                  </section>
                </>
              )}
            </>
          )}
        </Box>
      </Box>
    </Slide>
  )
}

export default AllTicketsPage

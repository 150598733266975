// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reserve_container {
  position: relative;
  bottom: 18px;
  left: 0;
  border-radius: 20px 20px 0px 0px;
  background: #f0eeee;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 15px 25px;
  height: calc(100% + 18px);

  .reserve-header_container {
    display: flex;
    align-items: center;
    padding: 15px;
    padding-left: 0;
    gap: 12px;

    & h2 {
      color: #29348f;
      font-family: Kufam;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      margin: 0;
    }
  }

  .reserve-body_container {
    height: calc(100% - 110px);
    /* height: calc(100vh - 278px); */
    overflow-y: scroll;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Reserve/styles.reserve.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,OAAO;EACP,gCAAgC;EAChC,mBAAmB;EACnB,gDAAgD;EAChD,kBAAkB;EAClB,yBAAyB;;EAEzB;IACE,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,eAAe;IACf,SAAS;;IAET;MACE,cAAc;MACd,kBAAkB;MAClB,eAAe;MACf,kBAAkB;MAClB,gBAAgB;MAChB,mBAAmB;MACnB,SAAS;IACX;EACF;;EAEA;IACE,0BAA0B;IAC1B,iCAAiC;IACjC,kBAAkB;EACpB;AACF","sourcesContent":[".reserve_container {\n  position: relative;\n  bottom: 18px;\n  left: 0;\n  border-radius: 20px 20px 0px 0px;\n  background: #f0eeee;\n  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.25);\n  padding: 15px 25px;\n  height: calc(100% + 18px);\n\n  .reserve-header_container {\n    display: flex;\n    align-items: center;\n    padding: 15px;\n    padding-left: 0;\n    gap: 12px;\n\n    & h2 {\n      color: #29348f;\n      font-family: Kufam;\n      font-size: 18px;\n      font-style: normal;\n      font-weight: 700;\n      line-height: normal;\n      margin: 0;\n    }\n  }\n\n  .reserve-body_container {\n    height: calc(100% - 110px);\n    /* height: calc(100vh - 278px); */\n    overflow-y: scroll;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { useEffect, useRef } from 'react'
import QRCodeStyling from 'qr-code-styling'

const qrCode = new QRCodeStyling({
  width: 150,
  height: 150,
  type: 'svg',

  dotsOptions: {
    color: '#29348f',
    type: 'rounded',
  },
  imageOptions: {
    crossOrigin: 'anonymous',
    margin: 20,
    imageSize: 16,
  },
})

export const CodeQR = ({ ticketId }) => {
  // const [data, setData] = useState('https://qr-code-styling.com')

  const ref = useRef(null)

  useEffect(() => {
    qrCode.append(ref.current)
  }, [])

  useEffect(() => {
    qrCode.update({
      data: ticketId,
    })
  }, [ticketId])

  return <div ref={ref} />
}

import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { ArrowBack } from '../../components/icons/ArrowBack'
import { ButtonBlue } from '../../components/buttons'
import { SendPaymentAlert } from '../../components/bottom-panel/Alert/SendPayment'
import { busiAPi } from '../../utils/urls'

import {
  PagoMovilCard,
  PagoMovilData,
  GetPaymentData,
  RemiderPaymentCard,
} from '../../components/ui/Cards/PagoMovil'

import { useStore, useAmountsStorage } from '../../store'

import axios from 'axios'
import { useMediaQuery } from '@mui/material'
import { Capacitor } from '@capacitor/core'
// import { post } from '../../api/axiosHelper'

// import { Box } from '@mui/material'
import './styles.payment.css'

export const PaymentPage = () => {
  // const [token] = useLocalStorage('token')
  const {
    unregPassengersData,
    selectedTrip,
    // completedBookedUsd,
    ownerTravel,
    selectedTicket,
  } = useStore()
  const minW800 = useMediaQuery('(min-width:800px)')

  const { totalUsd, completedBookedUsd } = useAmountsStorage()

  const navegate = useNavigate()
  const { state } = useLocation()

  const [isCheck, setCheck] = useState('movil')
  const [page, setPage] = useState('choose')
  const [show, setShow] = useState(false)
  const [disabled, setDisabled] = useState(true)

  const [err, setErr] = useState(undefined)
  const [loading, setLoading] = useState(false)

  // INPUTS SETTINGS

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({ mode: 'all', reValidateMode: 'onChange' })

  const phoneWatch = watch('phone')
  // const phoneCodeWatch = watch('code')
  const banksWatch = watch('banks')
  const ciWatch = watch('ci')
  const refNumberWatch = watch('refNumber')
  const codeWatch = watch('ciCode')

  useEffect(() => {
    const isBanks = banksWatch !== undefined && banksWatch !== ''
    const isRefNumber = refNumberWatch !== undefined && refNumberWatch !== ''
    const isPhone = phoneWatch !== undefined && phoneWatch !== ''
    // const isPhoneCode = codeWatch !== undefined && codeWatch !== ''
    const isCi = ciWatch !== undefined && ciWatch !== ''
    // const isCiCode = ciCodeWatch !== undefined && ciCodeWatch !== ''

    if (isBanks && isRefNumber && isPhone && isCi) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }, [phoneWatch, banksWatch, ciWatch, refNumberWatch])

  // region FUNCTIONS
  const goBack = () => {
    if (page === 'choose') {
      navegate(state.previousPath)
    }

    if (page === 'info') {
      setPage('choose')
    }

    // if (page === 'verification') {
    //   setPage('info')
    // }
  }

  const handlePage = () => {
    if (page === 'choose') {
      setPage('info')
    }
    // if (page === 'info') {
    //   setPage('verification')
    // }
    // if (page === 'verification') {
    //   setShow(true)
    // }
  }

  const onSubmit = async (data) => {
    const completedBooked = state?.status === 'booked'
    // const isDetail = state.previousPath.includes('ticket/detail')
    const paymentData = {
      bankName: data.banks,
      phoneNumber: `58-${data.code.slice(1)}-${data.phone}`,
      documentId: `${data.ciCode}-${data.ci}`,
      referenceNumber: data.refNumber?.toString(),
      amount: completedBooked ? completedBookedUsd : totalUsd,
      type: 'mobile',
    }

    const fullBody = {
      routeId: completedBooked ? selectedTicket.routeId : selectedTrip.routeId,
      subRouteId: completedBooked
        ? selectedTicket.subRouteId
        : selectedTrip.subRouteId,
      isPassengerTraveling: completedBooked
        ? selectedTicket.isOwnerTraveling
        : ownerTravel,
      unregisterPassengers: completedBooked
        ? selectedTicket.passengers
        : unregPassengersData,
      paymentData,
      date: completedBooked
        ? selectedTicket.dateByDay.format('MM/DD/YYYY')
        : selectedTrip.dateServer,
      currency: 'Bs',
      price: totalUsd,
      platform: Capacitor.getPlatform(),
    }

    const fullBodyBooked = {
      ticketId: selectedTicket.id,
      paymentData,
      currency: 'Bs',
      platform: Capacitor.getPlatform(),
    }

    try {
      setLoading(true)
      const { data: info } = await axios.post(
        completedBooked
          ? `${busiAPi}/ticket/complete-purchase`
          : `${busiAPi}/ticket`,
        completedBooked ? { ...fullBodyBooked } : { ...fullBody },
        { headers: { Authorization: localStorage.getItem('token') } },
      )

      if (info.success) {
        setLoading(false)
        setShow(true)
      } else {
        setLoading(false)
        setErr('Tenemos un problema, intenta más tarde')
      }
    } catch (error) {
      setLoading(false)
      const errMain = error?.response?.data?.error
      console.log({ errMain })
      if (errMain?.errorCode === 1103) {
        setErr(errMain?.message)
        return
      }
      setErr('Tenemos un problema, intenta más tarde')
    }
  }

  if (minW800) {
    return (
      <section
        className='payment_container'
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          flexDirection: 'column',

          height: 'calc(100% - 134px)',
          minHeight: '600px',
          bottom: 0,
          borderRadius: 0,
          background: 'transparent',
        }}>
        <div
          onClick={goBack}
          className='payment-header_container'
          style={{ maxWidth: '600px', width: '100%' }}>
          <ArrowBack />

          <h2>
            {page === 'choose'
              ? 'Escoger método de pago'
              : page === 'info'
              ? 'Datos para el pago'
              : 'Datos para verificación'}
          </h2>
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ height: '100%', maxWidth: '600px', width: '100%' }}>
          <section className='payment-body_container'>
            {
              page === 'choose' ? (
                <div className='card_radio'>
                  <section
                    className='icon_text_container'
                    style={{ width: '90%' }}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='22'
                      height='28'
                      viewBox='0 0 22 28'
                      fill='none'>
                      <path
                        d='M18.4526 22.9091H3.63116V5.09091H18.4526M18.4526 0H3.63116C1.98598 0 0.66687 1.13273 0.66687 2.54545V25.4545C0.66687 26.1296 0.979178 26.7771 1.53509 27.2545C2.091 27.7318 2.84498 28 3.63116 28H18.4526C19.2388 28 19.9927 27.7318 20.5486 27.2545C21.1046 26.7771 21.4169 26.1296 21.4169 25.4545V2.54545C21.4169 1.87036 21.1046 1.22291 20.5486 0.745546C19.9927 0.268181 19.2388 0 18.4526 0Z'
                        fill='#29348F'
                      />
                    </svg>

                    <label>Pago móvil</label>
                  </section>

                  <input
                    name='pago'
                    id='movil_payment'
                    type='radio'
                    value='movil'
                    checked={isCheck === 'movil'}
                    onChange={() => setCheck('movil')}
                  />
                </div>
              ) : (
                page === 'info' && (
                  <>
                    <PagoMovilCard />

                    <PagoMovilData
                      isCompleteBooked={state?.status === 'booked'}
                    />

                    <RemiderPaymentCard />
                    <GetPaymentData
                      register={register}
                      setValue={setValue}
                      errors={errors}
                      control={control}
                      codeWatch={codeWatch}
                    />

                    {err && (
                      <p
                        className='error_message'
                        style={{ marginTop: '6px', marginLeft: '4px' }}>
                        {err}
                      </p>
                    )}
                  </>
                )
              )
              // : (
              //   <>
              //     <PagoMovilCard />

              //     <GetPaymentData
              //       register={register}
              //       setValue={setValue}
              //       errors={errors}
              //       control={control}
              //       codeWatch={codeWatch}
              //     />
              //     {err && (
              //       <p
              //         className='error_message'
              //         style={{ marginTop: '6px', marginLeft: '4px' }}>
              //         {err}
              //       </p>
              //     )}
              //   </>
              // )
            }
          </section>

          {page === 'info' ? (
            <div className='action_button_container'>
              <ButtonBlue type='submit' disabled={loading || disabled}>
                Continuar
              </ButtonBlue>
            </div>
          ) : (
            <div className='action_button_container'>
              <ButtonBlue onClick={handlePage}>Continuar</ButtonBlue>
            </div>
          )}
        </form>

        <SendPaymentAlert
          isShow={show}
          setShow={setShow}
          type={state?.status === 'booked' ? 'completedBooked' : 'buy'}
          keepOpen
        />
      </section>
    )
  }

  return (
    <section
      className='payment_container'
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        flexDirection: 'column',
      }}>
      <div
        onClick={goBack}
        className='payment-header_container'
        style={{ maxWidth: '600px', width: '100%' }}>
        <ArrowBack />

        <h2>
          {page === 'choose'
            ? 'Escoger método de pago'
            : page === 'info'
            ? 'Datos para el pago'
            : 'Datos para verificación'}
        </h2>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ height: '100%', maxWidth: '600px', width: '100%' }}>
        <section className='payment-body_container'>
          {
            page === 'choose' ? (
              <div className='card_radio'>
                <section
                  className='icon_text_container'
                  style={{ width: '90%' }}>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='22'
                    height='28'
                    viewBox='0 0 22 28'
                    fill='none'>
                    <path
                      d='M18.4526 22.9091H3.63116V5.09091H18.4526M18.4526 0H3.63116C1.98598 0 0.66687 1.13273 0.66687 2.54545V25.4545C0.66687 26.1296 0.979178 26.7771 1.53509 27.2545C2.091 27.7318 2.84498 28 3.63116 28H18.4526C19.2388 28 19.9927 27.7318 20.5486 27.2545C21.1046 26.7771 21.4169 26.1296 21.4169 25.4545V2.54545C21.4169 1.87036 21.1046 1.22291 20.5486 0.745546C19.9927 0.268181 19.2388 0 18.4526 0Z'
                      fill='#29348F'
                    />
                  </svg>

                  <label>Pago móvil</label>
                </section>

                <input
                  name='pago'
                  id='movil_payment'
                  type='radio'
                  value='movil'
                  checked={isCheck === 'movil'}
                  onChange={() => setCheck('movil')}
                />
              </div>
            ) : (
              page === 'info' && (
                <>
                  <PagoMovilCard />

                  <PagoMovilData
                    isCompleteBooked={state?.status === 'booked'}
                  />

                  {/* <RemiderPaymentCard /> */}

                  <GetPaymentData
                    register={register}
                    setValue={setValue}
                    errors={errors}
                    control={control}
                    codeWatch={codeWatch}
                  />

                  {err && (
                    <p
                      className='error_message'
                      style={{ marginTop: '6px', marginLeft: '4px' }}>
                      {err}
                    </p>
                  )}
                </>
              )
            )
            // : (
            //   <>
            //     <PagoMovilCard />

            //     <GetPaymentData
            //       register={register}
            //       setValue={setValue}
            //       errors={errors}
            //       control={control}
            //       codeWatch={codeWatch}
            //     />
            //     {err && (
            //       <p
            //         className='error_message'
            //         style={{ marginTop: '6px', marginLeft: '4px' }}>
            //         {err}
            //       </p>
            //     )}
            //   </>
            // )
          }
        </section>

        {page === 'info' ? (
          <div className='action_button_container'>
            <ButtonBlue type='submit' disabled={loading || disabled}>
              Continuar
            </ButtonBlue>
          </div>
        ) : (
          <div className='action_button_container'>
            <ButtonBlue onClick={handlePage}>Continuar</ButtonBlue>
          </div>
        )}
      </form>

      <SendPaymentAlert
        isShow={show}
        setShow={setShow}
        type={state?.status === 'booked' ? 'completedBooked' : 'buy'}
        keepOpen
      />
    </section>
  )
}

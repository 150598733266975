import { useState } from 'react'
// import EyeIcon from './eye.js'
import { TextField, InputAdornment, IconButton } from '@mui/material'
import { styled } from '@mui/material/styles'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

import './styles.password.css'

const CssTextField = styled(TextField)({
  '.MuiInput-root::before': {
    borderBottom: '1px solid transparent',
  },
  '.MuiInput-root::after': {
    borderBottom: '2px solid #29348F',
  },
  '.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
    borderBottom: '1px solid transparent',
  },
  '.css-953pxc-MuiInputBase-root-MuiInput-root::after': {
    borderBottom: '2px solid #29348F',
  },
  '.css-953pxc-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before':
    {
      borderBottom: '1px solid transparent',
    },
})

export default function FormsPassword({
  id = 'password',
  label = 'Contraseña',
  registerValidation = {},
  showDetail = false,
  error = '',
}) {
  const [showText, setShowText] = useState(false)

  return (
    <div>
      <CssTextField
        {...registerValidation}
        fullWidth
        label={label}
        variant='standard'
        type={showText ? 'text' : 'password'}
        autoComplete='off'
        InputLabelProps={{
          style: {
            color: '#29348F',
            fontFamily: 'Kufam',
          },
        }}
        InputProps={{
          style: {
            color: '#29348F',
            border: 'none',
            borderBottom: '1px solid #29348F', // Cambia el estilo del subrayado
          },
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton onClick={() => setShowText(!showText)}>
                {showText ? (
                  <VisibilityOffIcon
                    fontSize='small'
                    style={{ color: '#29348f' }}
                  />
                ) : (
                  <VisibilityIcon
                    fontSize='small'
                    style={{ color: '#29348f' }}
                  />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {showDetail && (
        <p
          style={{
            color: 'gray',
            fontSize: '10px',
            margin: '0px',
            marginTop: '5px',
          }}>
          La contraseña debe tener al menos 8 caracteres, combinar letras
          mayúsculas y minúsculas e incluir caracteres especiales.
        </p>
      )}

      {error && <p className='error_message'>{error}</p>}
    </div>
  )
}

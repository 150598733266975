import React from 'react'

import { TicketServices } from './Services'
import { TicketContainer } from './Container'
import { Info } from './Info'
import { Status } from './Status'

import { Box } from '@mui/material'

import './styles.css'

const Availability = ({ count }) => {
  return (
    <Box
      // className='ticket-availability'
      style={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#dadce8',
        borderRadius: '5px',
        textAlign: 'center',
        padding: '8px',
        gap: '3px',
      }}>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='11'
        height='13'
        viewBox='0 0 11 14'
        fill='none'>
        <path
          d='M10.708 0.354844C10.4998 0.126052 10.2127 0 9.89955 0H9.03883C8.4045 0 7.7991 0.521106 7.68946 1.16168L7.63518 1.47847C7.58061 1.79724 7.65405 2.09917 7.84202 2.32866C8.02998 2.55815 8.3073 2.6845 8.62299 2.6845H9.73483C9.88909 2.6845 10.0383 2.65291 10.1769 2.59685L10.0588 3.63189C9.94502 3.58288 9.82014 3.55678 9.6889 3.55678H8.42036C7.87692 3.55678 7.34314 4.0002 7.23037 4.5452L6.10881 9.96647L0.817112 9.5766C0.531153 9.55562 0.292427 9.65498 0.144417 9.85009C0.0057855 10.0328 -0.034741 10.278 0.0302265 10.5405L0.645457 13.0241C0.778745 13.5622 1.32776 14 1.86921 14H6.30547H6.37317H8.63135C9.18002 14 9.67355 13.5448 9.73142 12.9854L10.5995 4.59468C10.6044 4.54671 10.605 4.49945 10.6034 4.45266L10.9005 1.85074C10.9424 1.73867 10.9691 1.61956 10.9775 1.49601L10.9971 1.20638C11.0189 0.886091 10.9162 0.583695 10.708 0.354844Z'
          fill='#29348F'
        />
      </svg>

      <p
        style={{
          color: '#29348f',
          fontFamily: 'Kufam',
          fontSize: '10px',
          fontStyle: 'normal',
          fontWeight: '700',
          lineHeight: 'normal',
          margin: '0',
        }}>
        {count > 0 ? `Disponibles` : 'No Disponibles'}{' '}
      </p>
    </Box>
  )
}

/**
 *
 * @param {object} props
 * @param {('active' | 'confirmed' | 'reserved' |  'used', 'boarded')} props.status - props to show ticket status.
 * @param {date} props.date - props to show ticket date.
 * @param {string} props.logoUrl - props to url to the image.
 * @param {string} props.startTime - props to url to the image.
 * @param {string} props.endTime - props to url to the image.
 * @param {object} props.startLocation - props to url to the image.
 * @param {object} props.startLocation - props to url to the image.
 * @param {object} props.endLocation - props to url to the image.
 * @param {Array} props.services - props to url to the image.
 * @returns {React.JSX.Element}
 */
export function Ticket({
  status = 'active',
  title = '',
  date = '',
  startTime = '',
  endTime = '',
  startLocation = { short: '', long: '' },
  endLocation = { short: '', long: '' },
  price = null,
  priceRef = null,
  availability = '',
  services = [],
}) {
  const isStatusBorded = status === 'boarded'
  const isStatusExpired = status === 'used'

  return (
    <TicketContainer type={isStatusBorded ? 'boarded' : 'middle'}>
      {isStatusBorded ? null : (
        <Box
          // className='ticket-header'
          style={{
            width: '100%',
            maxHeight: '25%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}>
          {/* <img
              className={`ticket-brand ${isStatusExpired ? 'used' : ''}`}
              src={logoUrl}
              alt='Ticket Brand'
            /> */}
          <h1
            // className={`ticket-title ${isStatusExpired ? 'used' : ''}`}
            style={{
              color: isStatusExpired ? '#898989' : '#29348f',
              fontFamily: 'Kufam',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: 'normal',
              margin: '0',
              maxWidth: '185px',
            }}>
            {title}
          </h1>

          {price || priceRef ? (
            <section>
              <p className='ticket-price'>Ref {priceRef} (p/p)</p>

              <p className='ticket-price_ref'>Bs {price}</p>
              {/* {priceRef && price >= 25 && (
                <p className='ticket-price_ref'>Ref {priceRef}</p>
              )} */}
            </section>
          ) : (
            <Status status={status} />
          )}
        </Box>
      )}

      <Box
        // className='test-borded'
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        <Info
          date={date}
          startTime={startTime}
          endTime={endTime}
          startLocation={startLocation}
          endLocation={endLocation}
          isStatusBorded={isStatusBorded}
          isStatusExpired={isStatusExpired}
        />

        {isStatusBorded ? <Status status={status} /> : null}
      </Box>

      {isStatusBorded ? null : (
        <Box
          className={`ticket-bus-services ${status}`}
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            minWidth: '50px',
            width: '100%',
            height: 'fit-content',
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: 'flex-end',
          }}>
          <TicketServices services={services} />

          {availability ? <Availability count={availability} /> : null}
        </Box>
      )}
    </TicketContainer>
  )
}

import { RouterProvider } from 'react-router-dom'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

import {
  ThemeProvider as MuiThemeProvider,
  createTheme,
} from '@mui/material/styles'
import { ThemeProvider } from '@emotion/react'

import { routers } from './routes'
import './App.css'

// Create a client
const queryClient = new QueryClient()

const theme = createTheme({
  palette: {
    primary: {
      main: '#29348F',
    },
    pink: {
      main: '#B01F8C',
    },
    gold: {
      main: '#FFC600',
    },
    whatsapp: { main: '#25d366' },
    white: { main: '#fff' },
  },
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <RouterProvider router={routers} />

          {/* TODO: hide this for prod / QA enviroments */}
          {/* <ReactQueryDevtools initialIsOpen={false} position='top-right' /> */}
        </ThemeProvider>
      </MuiThemeProvider>
    </QueryClientProvider>
  )
}

export default App

import React from 'react'

export const SearchIcon = ({
  width = '18',
  height = '18',
  fill = '#222B79',
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={width}
      height={height}
      viewBox='0 0 13 13'
      fill='none'>
      <path
        d='M12.7859 11.7646L9.93109 8.90872C10.7871 7.79332 11.1867 6.39411 11.0489 4.99491C10.9111 3.59571 10.2463 2.3013 9.18918 1.37426C8.13208 0.447225 6.76192 -0.0430234 5.35665 0.00296603C3.95137 0.0489554 2.6162 0.627739 1.62198 1.62191C0.627768 2.61608 0.0489577 3.95118 0.00296617 5.3564C-0.0430254 6.76161 0.447246 8.13171 1.37433 9.18875C2.30141 10.2458 3.59587 10.9106 4.99514 11.0484C6.3944 11.1862 7.79368 10.7866 8.90913 9.93063L11.7664 12.7884C11.8335 12.8555 11.9132 12.9087 12.0008 12.945C12.0885 12.9813 12.1825 13 12.2774 13C12.3723 13 12.4662 12.9813 12.5539 12.945C12.6416 12.9087 12.7212 12.8555 12.7883 12.7884C12.8554 12.7213 12.9087 12.6416 12.945 12.5539C12.9813 12.4663 13 12.3723 13 12.2774C13 12.1825 12.9813 12.0885 12.945 12.0009C12.9087 11.9132 12.8554 11.8335 12.7883 11.7664L12.7859 11.7646ZM1.45489 5.54244C1.45489 4.73399 1.69463 3.94369 2.14381 3.27148C2.59298 2.59927 3.23141 2.07535 3.97836 1.76597C4.72531 1.45659 5.54723 1.37564 6.34019 1.53336C7.13315 1.69108 7.86153 2.08039 8.43322 2.65206C9.00491 3.22372 9.39424 3.95207 9.55197 4.74499C9.70969 5.53791 9.62874 6.35979 9.31935 7.10671C9.00995 7.85362 8.486 8.49202 7.81377 8.94118C7.14153 9.39033 6.35119 9.63007 5.5427 9.63007C4.45889 9.62895 3.41978 9.19793 2.65341 8.4316C1.88704 7.66526 1.456 6.62621 1.45489 5.54244Z'
        fill={fill}
      />
    </svg>
  )
}

import { useCallback, useEffect, useMemo, useState } from 'react'

import SeatIcon from '../icons/SeatIcon'
import DoorStairsIcon from '../icons/DoorStairsIcon'
import RestroomIcon from '../icons/RestroomIcon'
import { Box } from '@mui/material'

import './styles.css'

export const Bus = ({ seats = 1, selected = 0, children }) => {
  return (
    <Box
      component='section'
      className='busContainer'
      style={{ maxHeight: '420px' }}>
      <Box className='busFrontPart'>
        <p>Parte frontal</p>
      </Box>

      {children}

      <Box className='busSeatCounter'>
        <p>
          <SeatIcon style={{ marginRight: '10px' }} />
          {`${selected}/${seats}`}
        </p>
      </Box>
    </Box>
  )
}
/**
 *
 * @param {object} props
 * @param {{floor: Number, right: Number, left: Number, seats:{type: ('seat' | 'floor'), state: String, spot: Number}[]}[]} props.info - props that contains the layout info
 * @param {('seat' | 'floor')} props.info.type - props to display seat or floor.
 * @param {string} props.info.state - props to show a seat state.
 * @param {Number} props.info.number - props to show a seat number.
 * @returns {React.JSX.Element}
 */
export const BusLayout = ({
  info = [],
  layoutColumns = 5,
  avaliableSeats = 0,
  seatsNumber = 0,
  setSeatNumber,
  watchFloor,
}) => {
  const [infoLayout, setInfoLayout] = useState(info)
  const [seatsLayout, setSeatsLayout] = useState([])

  useEffect(() => {
    const bum = infoLayout.find(
      (info) => info.floor === Number(watchFloor),
    ).seats
    setSeatsLayout(bum)
  }, [infoLayout, watchFloor])

  useEffect(() => {
    const seats = infoLayout
      .map((layout) => layout.seats)
      .flat()
      .filter((seat) => seat.state === 'selected').length

    setSeatNumber(seats)
  }, [])

  const selectSeat = (spot) => {
    const layouts = infoLayout.map((floorsInfo) => {
      if (floorsInfo.floor === Number(watchFloor)) {
        floorsInfo.seats.map((seat) => {
          if (seat.spot === spot) {
            if (seat.state === 'available' && avaliableSeats > seatsNumber) {
              seat.state = 'selected'
            } else {
              seat.state = 'available'
            }
          }
          return seat
        })
      }
      return floorsInfo
    })

    const seats = layouts
      .map((layout) => layout.seats)
      .flat()
      .filter((seat) => seat.state === 'selected').length

    setSeatNumber(seats)
    // const selected = floorLayout.map((seatInfo) => {
    //   if (seatInfo.spot === spot) {
    //     if (seatInfo.state === 'available' && avaliableSeats > seatsNumber) {
    //       return { ...seatInfo, state: 'selected' }
    //     } else {
    //       return { ...seatInfo, state: 'available' }
    //     }
    //   }

    //   return seatInfo
    // })

    setInfoLayout(layouts)
  }
  // const selectSeat = useCallback(
  //   (spot) => {
  //     const selected = infoLayout.map((seatInfo) => {
  //       if (seatInfo.spot === spot) {
  //         if (seatInfo.state === 'available' && avaliableSeats > seatsNumber) {
  //           return { ...seatInfo, state: 'selected' }
  //         } else {
  //           return { ...seatInfo, state: 'available' }
  //         }
  //       }

  //       return seatInfo
  //     })

  //     const seats = selected.filter((seat) => seat.state === 'selected').length

  //     setSeatNumber(seats)
  //     setInfoLayout(selected)
  //   },
  //   [],
  // )

  return (
    <div
      className='busLayout'
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${layoutColumns}, 1fr)`,
      }}>
      {seatsLayout?.map((val, index) => (
        <BusSeat
          key={index}
          type={val?.type}
          state={val?.state}
          spot={val?.spot}
          selectSeact={selectSeat}
        />
      ))}
    </div>
  )
}

/**
 *
 * @param {object} props
 * @param {'seat' | 'floor'} props.type - props to display seat or floor.
 * @param {'available' | 'busy' | 'selected'} props.state - props to show a seat state.
 * @param {Number} props.spot - props to show a seat number.
 * @returns {React.JSX.Element}
 */
const BusSeat = ({
  type = 'seat',
  state = 'available',
  spot = null,
  selectSeact = console.log,
}) => {
  const className = useMemo(() => {
    if (type === 'seat') {
      let seatColor = state !== 'available' ? `busSeat-${state}` : ''

      return `busSeat ${seatColor}`
    }

    return `bus-amenity-${type}`
  }, [type, state])

  const icon = useMemo(() => {
    if (type === 'stair') return <DoorStairsIcon />
    if (type === 'restroom') return <RestroomIcon color='#712BB7' />

    return null
  }, [type])

  const handleClick = () => {
    if (state !== 'busy') selectSeact(spot)
  }

  return (
    <div className={className} onClick={handleClick}>
      <p>{spot}</p>
      {icon}
    </div>
  )
}

import { TextField, styled } from '@mui/material'

const CssTextField = styled(TextField)({
  '.MuiInput-root::before': {
    borderBottom: '1px solid transparent',
  },
  '.MuiInput-root::after': {
    borderBottom: '2px solid #29348F',
  },
  '.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
    borderBottom: '1px solid transparent',
  },
})

export const InputMaterial = ({ register, label = '', type = 'text' }) => {
  return (
    <CssTextField
      {...register}
      fullWidth
      label={label}
      variant='standard'
      type={type}
      autoComplete='off'
      autoCorrect='off'
      InputLabelProps={{
        style: {
          color: '#29348F',
          fontFamily: 'Kufam',
        },
      }}
      InputProps={{
        style: {
          color: '#29348F',
          border: 'none',
          borderBottom: '1px solid #29348F',
          fontFamily: 'Kufam',
        },
      }}
    />
  )
}

export const InputControlMaterial = ({
  onChange = undefined,
  onKeyDown = undefined,
  field,
  label = '',
  type = 'text',
}) => {
  return (
    <CssTextField
      onChange={onChange}
      onBlur={field.onBlur}
      onKeyDown={onKeyDown}
      value={field.value}
      name={field.name}
      inputRef={field.ref}
      label={label}
      variant='standard'
      type={type}
      autoComplete='off'
      autoCorrect='off'
      fullWidth
      InputLabelProps={{
        style: {
          color: '#29348F',
          fontFamily: 'Kufam',
        },
      }}
      InputProps={{
        style: {
          color: '#29348F',
          border: 'none',
          borderBottom: '1px solid #29348F',
          fontFamily: 'Kufam',
        },
      }}
    />
  )
}

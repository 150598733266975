import { useEffect, useRef } from 'react'
import { InputMaterial } from '..'

import { TextField, styled } from '@mui/material'

import './styles.2fa.css'
const CssTextField = styled(TextField)({
  '.MuiInput-root::before': {
    borderBottom: '1px solid transparent',
  },
  '.MuiInput-root::after': {
    borderBottom: '2px solid #29348F',
  },
  '.MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before': {
    borderBottom: '1px solid transparent',
  },
})

export default function Forms2Fa({ error, registerValidation, setValue }) {
  const containerRef = useRef()

  useEffect(() => {
    containerRef.current.firstChild.focus()
  }, [])

  const handleChange = (e) => {
    const target = e.target

    if (target.value !== '' && target.tabIndex < 6) {
      containerRef.current?.childNodes[
        target.tabIndex
      ]?.firstChild?.firstChild.focus()
    }
  }

  const handleKeyDown = (e) => {
    const target = e.target

    if (e.key === 'Backspace' && target.value === '' && target.tabIndex > 1) {
      containerRef.current?.childNodes[
        target.tabIndex - 2
      ].firstChild.firstChild.focus()
    }
  }

  return (
    <>
      <section className='two-factor-auth' ref={containerRef}>
        <CssTextField
          {...registerValidation('code-1', {
            required: 'Este campo es requerido',
            maxLength: { value: 1, message: 'Debe ser 1 solo digito' },
          })}
          fullWidth
          autoComplete='off'
          variant='standard'
          type='tel'
          name='pincode-1'
          placeholder='·'
          onChange={(e) => {
            handleChange(e)
            setValue('code-1', e.target.value)
          }}
          onKeyDown={(e) => handleKeyDown(e)}
          InputLabelProps={{
            style: {
              color: '#29348F',
              fontFamily: 'Kufam',
            },
          }}
          inputProps={{ maxLength: 1, tabIndex: 1 }}
          InputProps={{
            style: {
              color: '#29348F',
              border: 'none',
              borderBottom: '1px solid #29348F',
              textAlign: 'center',
            },
          }}
        />

        <CssTextField
          {...registerValidation('code-2', {
            required: 'Este campo es requerido',
          })}
          fullWidth
          autoComplete='off'
          variant='standard'
          type='tel'
          name='pincode-2'
          inputProps={{ maxLength: 1, tabIndex: 2 }}
          placeholder='·'
          onChange={(e) => {
            handleChange(e)
            setValue('code-2', e.target.value)
          }}
          onKeyDown={(e) => handleKeyDown(e)}
          InputLabelProps={{
            style: {
              color: '#29348F',
              fontFamily: 'Kufam',
            },
          }}
          InputProps={{
            style: {
              color: '#29348F',
              border: 'none',
              borderBottom: '1px solid #29348F',
            },
          }}
        />

        <CssTextField
          {...registerValidation('code-3', {
            required: 'Este campo es requerido',
          })}
          fullWidth
          autoComplete='off'
          variant='standard'
          type='tel'
          name='pincode-3'
          inputProps={{ maxLength: 1, tabIndex: 3 }}
          placeholder='·'
          onChange={(e) => {
            handleChange(e)
            setValue('code-3', e.target.value)
          }}
          onKeyDown={(e) => handleKeyDown(e)}
          InputLabelProps={{
            style: {
              color: '#29348F',
              fontFamily: 'Kufam',
            },
          }}
          InputProps={{
            style: {
              color: '#29348F',
              border: 'none',
              borderBottom: '1px solid #29348F',
            },
          }}
        />

        <CssTextField
          {...registerValidation('code-4', {
            required: 'Este campo es requerido',
          })}
          fullWidth
          autoComplete='off'
          variant='standard'
          type='tel'
          name='pincode-4'
          inputProps={{ maxLength: 1, tabIndex: 4 }}
          placeholder='·'
          onChange={(e) => {
            handleChange(e)
            setValue('code-4', e.target.value)
          }}
          onKeyDown={(e) => handleKeyDown(e)}
          InputLabelProps={{
            style: {
              color: '#29348F',
              fontFamily: 'Kufam',
            },
          }}
          InputProps={{
            style: {
              color: '#29348F',
              border: 'none',
              borderBottom: '1px solid #29348F',
            },
          }}
        />
        <CssTextField
          {...registerValidation('code-5', {
            required: 'Este campo es requerido',
          })}
          fullWidth
          autoComplete='off'
          variant='standard'
          type='tel'
          name='pincode-5'
          inputProps={{ maxLength: 1, tabIndex: 5 }}
          placeholder='·'
          onChange={(e) => {
            handleChange(e)
            setValue('code-5', e.target.value)
          }}
          onKeyDown={(e) => handleKeyDown(e)}
          InputLabelProps={{
            style: {
              color: '#29348F',
              fontFamily: 'Kufam',
            },
          }}
          InputProps={{
            style: {
              color: '#29348F',
              border: 'none',
              borderBottom: '1px solid #29348F',
            },
          }}
        />
        <CssTextField
          {...registerValidation('code-6', {
            required: 'Este campo es requerido',
            maxLength: { value: 1, message: 'Debe ser 1 solo digito' },
          })}
          fullWidth
          autoComplete='off'
          variant='standard'
          type='tel'
          name='pincode-6'
          inputProps={{
            maxLength: 1,
            tabIndex: 6,
          }}
          placeholder='·'
          onChange={(e) => {
            handleChange(e)
            setValue('code-6', e.target.value)
          }}
          onKeyDown={(e) => handleKeyDown(e)}
          InputLabelProps={{
            style: {
              color: '#29348F',
              fontFamily: 'Kufam',
            },
          }}
          InputProps={{
            style: {
              color: '#29348F',
              border: 'none',
              borderBottom: '1px solid #29348F',
            },
          }}
        />
      </section>
      {error && <p className='error_message'>{error}</p>}
    </>
  )
}

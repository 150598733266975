import React, { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { SearchBar } from '../../components/SearchBar'
import { GoBack } from '../../components/ui/GoBack'
import { useGetOneLineByReview } from '../../hooks/useLines'

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  Rating,
  Stack,
  useMediaQuery,
} from '@mui/material'
import { TicketGrayIcon } from '../../components/icons/Ticket'
import { Bus } from '../../components/ui/Loader/Bus'
import { useStore } from '../../store'

import StarBorderIcon from '@mui/icons-material/StarBorder'
import StarIcon from '@mui/icons-material/Star'
import { Footer } from '../../components/Footer'

export const SearchDetail = () => {
  const navegate = useNavigate()
  const minW800 = useMediaQuery('(min-width:800px)')
  const { lineId } = useParams()

  const { selectedLine } = useStore(({ selectedLine }) => ({
    selectedLine,
  }))

  // const { data: info } = useGetLinesByReview()
  const {
    data: info,
    isLoading,
    isError,
  } = useGetOneLineByReview({ busLineId: lineId })

  const [openSearch, setOpenSearch] = useState(false)
  const [routes, setRoutes] = useState(undefined)

  const handleSearch = (route) => {
    setRoutes(route)
    setOpenSearch(true)
  }

  if (isLoading) {
    return <Bus />
  }

  if (isError) {
    return (
      <section className='ticket-container_error'>
        <TicketGrayIcon />
        <p className='no_data_text'>Tenemos un problema intenta más tarde</p>
      </section>
    )
  }

  if (minW800) {
    return (
      <section
        className='pages-search-main'
        style={{
          height: 'calc(100% - 134px)',
          minHeight: '800px',
          bottom: 0,
          borderRadius: 0,
          background: 'transparent',
        }}>
        <SearchBar
          title={`Buscar...`}
          openSearch={openSearch}
          setOpenSearch={setOpenSearch}
          values={routes}
          busLineId={selectedLine.id}
        />

        <Box
          component='section'
          style={{
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'center',
            alignItems: 'center',
          }}
          paddingTop='10px'
          paddingX='22px'>
          <GoBack
            title={selectedLine.name}
            onHandleClick={() => navegate('/main/search')}
          />

          <Box style={{ height: 'calc(100% - 34px)', maxWidth: '660px' }}>
            <Card style={{ background: '#29348F', borderRadius: '14px' }}>
              <CardActionArea>
                <CardContent>
                  <figure
                    style={{
                      width: '100%',
                      background: 'white',
                      margin: 0,
                      borderRadius: '17px',
                      padding: '10px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: '18px',
                    }}>
                    <img
                      style={{ maxHeight: '40px' }}
                      src={selectedLine.logo}
                      alt={selectedLine.name}
                    />
                  </figure>

                  <Stack direction='row' justifyContent='space-between'>
                    <p
                      style={{ color: '#F4F4F4', fontSize: '16px', margin: 0 }}>
                      Servicio
                    </p>
                    <Rating
                      name='read-only'
                      value={Number(info.data.averagescore)}
                      precision={0.5}
                      readOnly
                      icon={<StarIcon fontSize='inherit' color='gold' />}
                      emptyIcon={
                        <StarBorderIcon fontSize='inherit' color='gold' />
                      }
                    />
                  </Stack>

                  <Stack direction='row' justifyContent='space-between'>
                    <p
                      style={{ color: '#F4F4F4', fontSize: '16px', margin: 0 }}>
                      Autobúses
                    </p>
                    <Rating
                      name='read-only'
                      value={Number(info.data.busscore)}
                      precision={0.5}
                      readOnly
                      icon={<StarIcon fontSize='inherit' color='gold' />}
                      emptyIcon={
                        <StarBorderIcon fontSize='inherit' color='gold' />
                      }
                    />
                  </Stack>
                </CardContent>
              </CardActionArea>
            </Card>

            <h2
              style={{
                fontSize: '16px',
                marginBottom: '0px',
                marginTop: '12px',
              }}>
              Rutas más vendidas
            </h2>

            <Divider
              variant='fullWidth'
              style={{ marginBottom: '5px', marginTop: '5px' }}
            />

            <p
              style={{
                fontSize: '12px',
                fontWeight: 400,
                textWrap: 'balance',
              }}>
              Conoce las 5 rutas más populares de esta línea. Explora el resto
              de las rutas desde "Buscar...".
            </p>

            <Box
              component='section'
              style={{
                height: 'calc(100% - 230px)',
                overflowY: 'scroll',
              }}>
              <Stack component='section' spacing={1} marginY='12px'>
                {info.data.subRoutes.map(
                  ({ subrouteid, origincity, destinycity, price }) => {
                    return (
                      <Box
                        key={subrouteid}
                        position='relative'
                        display='flex'
                        alignItems='center'
                        style={{ overflowX: 'hidden', paddingBottom: '2px' }}
                        onClick={() =>
                          handleSearch({
                            from: { value: origincity, label: origincity },
                            to: { value: destinycity, label: destinycity },
                          })
                        }>
                        <span
                          style={{
                            width: '15px',
                            height: '15px',
                            borderRadius: '50%',
                            background: '#f0eeee',
                            position: 'absolute',
                            left: '-7.5px',
                          }}></span>

                        <Card
                          style={{
                            borderRadius: '6px',
                            height: '54px',
                            width: '100%',
                          }}
                          key={subrouteid}>
                          <CardActionArea>
                            <CardContent>
                              <Stack
                                direction='row'
                                alignItems='center'
                                justifyContent='space-between'>
                                <Stack direction='row' gap='5px'>
                                  <p style={{ fontSize: '14px', margin: 0 }}>
                                    {origincity}
                                  </p>

                                  <span>-</span>

                                  <p style={{ fontSize: '14px', margin: 0 }}>
                                    {destinycity}
                                  </p>
                                </Stack>

                                <Box
                                  style={{
                                    background: '#DADCE8',
                                    padding: '5px',
                                    borderRadius: '6px',
                                  }}>
                                  <p
                                    style={{
                                      fontSize: '12px',
                                      fontWeight: '700',
                                      height: '13px',
                                      margin: 0,
                                    }}>
                                    Ref. {price}
                                  </p>
                                </Box>
                              </Stack>
                            </CardContent>
                          </CardActionArea>
                        </Card>

                        <span
                          style={{
                            width: '15px',
                            height: '15px',
                            borderRadius: '50%',
                            background: '#f0eeee',
                            position: 'absolute',
                            right: '-7.5px',
                          }}></span>
                      </Box>
                    )
                  },
                )}
              </Stack>
            </Box>
          </Box>
        </Box>
      </section>
    )
  }

  return (
    <section className='pages-search-main'>
      <SearchBar
        title={`Buscar...`}
        openSearch={openSearch}
        setOpenSearch={setOpenSearch}
        values={routes}
        busLineId={selectedLine.id}
      />

      <Box
        component='section'
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
        paddingTop='70px'
        paddingX='22px'>
        <GoBack
          title={selectedLine.name}
          onHandleClick={() => navegate('/main/search')}
        />

        <Box style={{ height: 'calc(100% - 34px)', maxWidth: '660px' }}>
          <Card style={{ background: '#29348F', borderRadius: '14px' }}>
            <CardActionArea>
              <CardContent>
                <figure
                  style={{
                    width: '100%',
                    background: 'white',
                    margin: 0,
                    borderRadius: '17px',
                    padding: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '18px',
                  }}>
                  <img
                    style={{ maxHeight: '40px' }}
                    src={selectedLine.logo}
                    alt={selectedLine.name}
                  />
                </figure>

                <Stack direction='row' justifyContent='space-between'>
                  <p style={{ color: '#F4F4F4', fontSize: '16px', margin: 0 }}>
                    Servicio
                  </p>
                  <Rating
                    name='read-only'
                    value={Number(info.data.averagescore)}
                    precision={0.5}
                    readOnly
                    icon={<StarIcon fontSize='inherit' color='gold' />}
                    emptyIcon={
                      <StarBorderIcon fontSize='inherit' color='gold' />
                    }
                  />
                </Stack>

                <Stack direction='row' justifyContent='space-between'>
                  <p style={{ color: '#F4F4F4', fontSize: '16px', margin: 0 }}>
                    Autobúses
                  </p>
                  <Rating
                    name='read-only'
                    value={Number(info.data.busscore)}
                    precision={0.5}
                    readOnly
                    icon={<StarIcon fontSize='inherit' color='gold' />}
                    emptyIcon={
                      <StarBorderIcon fontSize='inherit' color='gold' />
                    }
                  />
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>

          <h2
            style={{
              fontSize: '16px',
              marginBottom: '0px',
              marginTop: '12px',
            }}>
            Rutas más vendidas
          </h2>
          <Divider
            variant='fullWidth'
            style={{ marginBottom: '5px', marginTop: '5px' }}
          />
          <p
            style={{
              fontSize: '12px',
              fontWeight: 400,
              textWrap: 'balance',
            }}>
            Conoce las 5 rutas más populares de esta línea. Explora el resto de
            las rutas desde "Buscar...".
          </p>

          <Box
            component='section'
            height='calc(100% - 210px)'
            style={{
              overflowY: 'scroll',
            }}>
            <Stack component='section' spacing={1} marginY='12px'>
              {info.data.subRoutes.map(
                ({ subrouteid, origincity, destinycity, price }) => {
                  return (
                    <Box
                      key={subrouteid}
                      position='relative'
                      display='flex'
                      alignItems='center'
                      style={{ overflowX: 'hidden', paddingBottom: '2px' }}
                      onClick={() =>
                        handleSearch({
                          from: { value: origincity, label: origincity },
                          to: { value: destinycity, label: destinycity },
                        })
                      }>
                      <span
                        style={{
                          width: '15px',
                          height: '15px',
                          borderRadius: '50%',
                          background: '#f0eeee',
                          position: 'absolute',
                          left: '-7.5px',
                        }}></span>

                      <Card
                        style={{
                          borderRadius: '6px',
                          height: '54px',
                          width: '100%',
                        }}
                        key={subrouteid}>
                        <CardActionArea>
                          <CardContent>
                            <Stack
                              direction='row'
                              alignItems='center'
                              justifyContent='space-between'>
                              <Stack direction='row' gap='5px'>
                                <p style={{ fontSize: '14px', margin: 0 }}>
                                  {origincity}
                                </p>

                                <span>-</span>

                                <p style={{ fontSize: '14px', margin: 0 }}>
                                  {destinycity}
                                </p>
                              </Stack>

                              <Box
                                style={{
                                  background: '#DADCE8',
                                  padding: '5px',
                                  borderRadius: '6px',
                                }}>
                                <p
                                  style={{
                                    fontSize: '12px',
                                    fontWeight: '700',
                                    height: '13px',
                                    margin: 0,
                                  }}>
                                  Ref. {price}
                                </p>
                              </Box>
                            </Stack>
                          </CardContent>
                        </CardActionArea>
                      </Card>

                      <span
                        style={{
                          width: '15px',
                          height: '15px',
                          borderRadius: '50%',
                          background: '#f0eeee',
                          position: 'absolute',
                          right: '-7.5px',
                        }}></span>
                    </Box>
                  )
                },
              )}
            </Stack>
          </Box>
        </Box>
      </Box>
    </section>
  )
}

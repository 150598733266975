import React from 'react'

export const CaretUpIcon = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'>
      <path
        d='M5.29018 15.79C5.38315 15.8837 5.49375 15.9581 5.61561 16.0089C5.73746 16.0597 5.86817 16.0858 6.00018 16.0858C6.13219 16.0858 6.2629 16.0597 6.38476 16.0089C6.50662 15.9581 6.61722 15.8837 6.71018 15.79L11.2902 11.21C11.3831 11.1163 11.4937 11.0419 11.6156 10.9911C11.7375 10.9404 11.8682 10.9142 12.0002 10.9142C12.1322 10.9142 12.2629 10.9404 12.3848 10.9911C12.5066 11.0419 12.6172 11.1163 12.7102 11.21L17.2902 15.79C17.3831 15.8837 17.4937 15.9581 17.6156 16.0089C17.7375 16.0597 17.8682 16.0858 18.0002 16.0858C18.1322 16.0858 18.2629 16.0597 18.3848 16.0089C18.5066 15.9581 18.6172 15.8837 18.7102 15.79C18.8964 15.6026 19.001 15.3492 19.001 15.085C19.001 14.8208 18.8964 14.5674 18.7102 14.38L14.1202 9.79001C13.5577 9.22821 12.7952 8.91266 12.0002 8.91266C11.2052 8.91266 10.4427 9.22821 9.88018 9.79001L5.29018 14.38C5.10393 14.5674 4.99939 14.8208 4.99939 15.085C4.99939 15.3492 5.10393 15.6026 5.29018 15.79Z'
        fill='#29348F'
      />
    </svg>
  )
}

import { InputMaterial } from '../index'
import './styles.css'

export default function FormsEmail({ error, registerValidation }) {
  return (
    <div className='input-field'>
      <InputMaterial
        register={registerValidation}
        label='Correo electrónico'
        type='email'
      />

      {error && <p className='error_message'>{error}</p>}
    </div>
  )
}

import './styles.css'

export default function FormsForgottenPassword({ handleClick }) {
  return (
    <div className='forgotten-password_container'>
      <p onClick={handleClick} className='forgotten-password'>
        Olvide mi contraseña
      </p>
    </div>
  )
}

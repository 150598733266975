// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.components-button-blue {
  padding-top: 1rem;
  width: 277px;
  height: 44px;
  border-radius: 10px;
  background: #29348f;
  color: #fff;
  text-align: center;
}

.blue_buttom {
  width: 285px;
  height: 44px;
  border-radius: 10px !important;
  text-align: center;
  border: 0;
  color: #fff !important;
  &.primary {
    background: #29348f;

    &.disabled {
      background: #a4a1a1;
    }
  }

  &.primary:focus {
    background: #29348f;
  }

  &.secondary {
    background: transparent;
    color: #29348f;

    border: 1px solid #29348f;
  }

  &.red {
    background: #e7194f;
  }
}

/* .blue_buttom:focus {
  background: #29348f;
} */

@media (max-width: 375px) {
  .blue_buttom {
    &.primary {
      width: 100%;
      min-width: 285px;
    }
    &.secondary {
      width: 100%;
      min-width: 285px;
    }
  }
}

@media (max-width: 320px) {
  .blue_buttom {
    &.primary {
      width: 250px;
      min-width: 250px;
    }
    &.secondary {
      width: 250px;
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/buttons/styles.button.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;EACnB,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,8BAA8B;EAC9B,kBAAkB;EAClB,SAAS;EACT,sBAAsB;EACtB;IACE,mBAAmB;;IAEnB;MACE,mBAAmB;IACrB;EACF;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,uBAAuB;IACvB,cAAc;;IAEd,yBAAyB;EAC3B;;EAEA;IACE,mBAAmB;EACrB;AACF;;AAEA;;GAEG;;AAEH;EACE;IACE;MACE,WAAW;MACX,gBAAgB;IAClB;IACA;MACE,WAAW;MACX,gBAAgB;IAClB;EACF;AACF;;AAEA;EACE;IACE;MACE,YAAY;MACZ,gBAAgB;IAClB;IACA;MACE,YAAY;IACd;EACF;AACF","sourcesContent":[".components-button-blue {\n  padding-top: 1rem;\n  width: 277px;\n  height: 44px;\n  border-radius: 10px;\n  background: #29348f;\n  color: #fff;\n  text-align: center;\n}\n\n.blue_buttom {\n  width: 285px;\n  height: 44px;\n  border-radius: 10px !important;\n  text-align: center;\n  border: 0;\n  color: #fff !important;\n  &.primary {\n    background: #29348f;\n\n    &.disabled {\n      background: #a4a1a1;\n    }\n  }\n\n  &.primary:focus {\n    background: #29348f;\n  }\n\n  &.secondary {\n    background: transparent;\n    color: #29348f;\n\n    border: 1px solid #29348f;\n  }\n\n  &.red {\n    background: #e7194f;\n  }\n}\n\n/* .blue_buttom:focus {\n  background: #29348f;\n} */\n\n@media (max-width: 375px) {\n  .blue_buttom {\n    &.primary {\n      width: 100%;\n      min-width: 285px;\n    }\n    &.secondary {\n      width: 100%;\n      min-width: 285px;\n    }\n  }\n}\n\n@media (max-width: 320px) {\n  .blue_buttom {\n    &.primary {\n      width: 250px;\n      min-width: 250px;\n    }\n    &.secondary {\n      width: 250px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import { get } from '../api/axiosHelper'
import { useQuery } from '@tanstack/react-query'

export const useGetPaymentRemaining = (tikectId, options = {}) => {
  return useQuery({
    queryKey: ['payment-remain'],
    queryFn: () => get(`/ticket/${tikectId}/payment-remaining`, options),
    retryDelay: 20000,
    enabled: tikectId !== undefined,
  })
}

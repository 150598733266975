import { useState } from 'react'
import { Alert } from '../index'
import { AcceptTermsCheckbox } from '../../../TermsConditions/AcceptCheck'
import { ButtonBlue } from '../../../buttons'
import { YellowInfoCard2 } from '../../../ui/Cards/Info'

import './styles.warning.css'

export const WarningModal = ({
  isWarning = false,
  setIsWarning,
  showCheck = true,
  onClick,
  termsLabel = 'He leído y acepto los terminos y condiciones de transporte',
  termUrl = '',
}) => {
  const [confirm, setConfirm] = useState(false)

  return (
    <Alert
      type='warning'
      isShow={isWarning}
      setShow={setIsWarning}
      showButton={false}>
      <p className='one_hour_text'>
        Todos los pasajeros deben presentarse <span>al menos 1 hora antes</span>{' '}
        del viaje por taquilla con:
      </p>

      <ul className='require_list_travel' style={{ marginBottom: 0 }}>
        <li>
          <h3>Venezolano:</h3>
          <p>- Cedula de identidad original o pasaporte.</p>
        </li>

        <li>
          <h3>Menores de edad:</h3>
          <p>- Documentación y permisos de viaje del CPNNA.</p>
        </li>

        <li>
          <h3>Extranjero:</h3>
          <p>- Pasaporte sellado con entrada al país y vigencia de 90 días.</p>
        </li>
      </ul>

      <YellowInfoCard2>
        <p style={{ textAlign: 'center', fontSize: '14px' }}>
          <b>¡IMPORTANTE!</b>
        </p>
        <ul
          style={{
            padding: '5px 5px 5px 18px',
            margin: 0,
            listStyleType: 'disc',
          }}>
          <li style={{ fontSize: '12px' }}>
            La tasa de salida no está incluida en el costo del boleto. El costo
            de este pago dependerá del terminal de salida.
          </li>
          <li style={{ fontSize: '12px' }}>
            Los boletos de tercera edad pueden tener un recargo adicional.
          </li>
        </ul>
      </YellowInfoCard2>

      <AcceptTermsCheckbox
        label={termsLabel}
        link={termUrl}
        termsPostion='up'
        getValue={(data) => setConfirm(data)}
        showCheck={showCheck}
      />

      <div className='button-action_confirm_terms'>
        <ButtonBlue onClick={onClick} disabled={showCheck ? !confirm : false}>
          Continuar
        </ButtonBlue>
      </div>
    </Alert>
  )
}

import React from 'react'

export const CalendarIcon = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      xmlns='http://www.w3.org/2000/svg'
      width='21'
      height='21'
      viewBox='0 0 21 21'
      fill='none'>
      <path
        d='M18.5937 21H2.4063C1.0789 21 0 19.9211 0 18.5937V4.1562C0 2.8288 1.0789 1.75006 2.4063 1.75006H18.5937C19.9211 1.75006 21 2.8288 21 4.1562V18.5937C21 19.9211 19.9211 21 18.5937 21ZM2.4063 3.06256C1.80341 3.06256 1.3125 3.55331 1.3125 4.1562V18.5937C1.3125 19.1966 1.80341 19.6875 2.4063 19.6875H18.5937C19.1966 19.6875 19.6875 19.1966 19.6875 18.5937V4.1562C19.6875 3.55331 19.1966 3.06256 18.5937 3.06256H2.4063Z'
        fill='#29348F'
      />
      <path
        d='M20.3438 8.31256H0.65625C0.293999 8.31256 0 8.01856 0 7.65631C0 7.29406 0.293999 7.00006 0.65625 7.00006H20.3438C20.706 7.00006 21 7.29406 21 7.65631C21 8.01856 20.706 8.31256 20.3438 8.31256Z'
        fill='#29348F'
      />
      <path
        d='M5.03125 5.25C4.669 5.25 4.375 4.956 4.375 4.59375V0.65625C4.375 0.293999 4.669 0 5.03125 0C5.3935 0 5.6875 0.293999 5.6875 0.65625V4.59375C5.6875 4.956 5.3935 5.25 5.03125 5.25Z'
        fill='#29348F'
      />
      <path
        d='M15.9688 5.25C15.6065 5.25 15.3125 4.956 15.3125 4.59375V0.65625C15.3125 0.293999 15.6065 0 15.9688 0C16.331 0 16.625 0.293999 16.625 0.65625V4.59375C16.625 4.956 16.331 5.25 15.9688 5.25Z'
        fill='#29348F'
      />
    </svg>
  )
}

import axios from 'axios'
import { busiAPi } from '../utils/urls'
import { usePersistStore } from '../store'

//TODO: set base url, check if we have this in and enviroment variable or something like it
const axiosInstance = axios.create({
  baseURL: busiAPi,
  headers: {
    // 'Access-Control-Allow-Origin': '*',
    'Content-type': 'application/json',
  },
  withCredentials: true,
  crossOrigin: true,
  timeout: 2500,
})

axiosInstance.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: localStorage.getItem('token'),
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    }

    return config
  },
  (error) => {
    Promise.reject(error)
  },
)

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async function (error) {
    const originalRequest = error.config

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const refreshToken = localStorage.getItem('refreshToken')

      // console.log({ refreshToken })
      return axios
        .put(`${busiAPi}/auth/passengers/refresh-token`, {
          email: usePersistStore.getState().userData.email,
          refreshToken,
        })
        .then(({ data: info }) => {
          if (info) {
            // console.log({ info })
            const token = info.data.token
            const refreshToken = info.data.refreshToken

            localStorage.setItem('token', token)
            localStorage.setItem('refreshToken', refreshToken)

            console.log('New token received')
            // console.log({ bum: axios.defaults.headers.common, originalRequest })
            axios.defaults.headers.common['Authorization'] = token

            return axiosInstance(originalRequest)
          } else {
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('token')
            localStorage.removeItem('busi_passenger_user')
            window.location.replace('/main/search')
          }
        })
        .catch((err) => {
          console.log({ err })
          localStorage.removeItem('refreshToken')
          localStorage.removeItem('token')
          localStorage.removeItem('busi_passenger_user')
          window.location.replace('/main/search')
        })
    }
    return Promise.reject(error)
  },
)

/**
 * Axios wrapper for Http Get method
 * @function
 * @param {string} apiURL - url path to resorce.
 * @param {object} requestParams - object that contain the query string.
 * @returns {Array}
 */
const get = async (apiURL = '', requestHeaders = {}, requestParams = {}) => {
  // console.log({
  //   axiosInstance,
  //   requestParams,
  //   Authorization: axios.defaults.headers.common['Authorization'],
  //   to: localStorage.getItem('token'),
  // })

  try {
    const { data } = await axiosInstance.get(apiURL, {
      params: requestParams,
      timeout: 20000,
      headers: {
        Authorization:
          axios.defaults.headers.common['Authorization'] ??
          localStorage.getItem('token'),
      },
    })

    if (Object.keys(data).length === 0) {
      return []
    }

    return data
  } catch (err) {
    console.error(`Error on Get - url: ${apiURL}`, err)
    throw new Error(err)
  }
}

/**
 * Axios wrapper for Http Post method
 * @function
 * @param {string} apiURL - url path to resorce.
 * @param {object} body - body of the request to be sent.
 * @param {object} requestParams - object that contain the query string.
 * @returns {Array}
 */
const post = async (apiURL = '', body = {}, requestParams = {}) => {
  try {
    console.log(body)
    const { data } = await axiosInstance.post(`${apiURL}`, body, {
      params: requestParams,
    })

    return data
  } catch (err) {
    console.error(`Error on Post - url: ${apiURL}`, err)
    throw new Error(err)
  }
}

/**
 * Axios wrapper for Http Post method
 * @function
 * @param {string} apiURL - url path to resorce.
 * @param {object} body - body of the request to be sent.
 * @param {object} requestParams - object that contain the query string.
 * @returns {Array}
 */
const put = async (apiURL = '', body = {}, requestParams = {}) => {
  try {
    const { data } = await axiosInstance.put(`${apiURL}`, body, {
      params: requestParams,
    })

    return data
  } catch (err) {
    console.error(`Error on Post - url: ${apiURL}`, err)
    throw new Error(err)
  }
}

//TODO: add a delete function
export { get, post, put }

import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

// import { Share } from '@capacitor/share'
// import { Filesystem, Directory } from '@capacitor/filesystem'
// import { Screenshot } from 'capacitor-screenshot'

import { Box, Slide, useMediaQuery } from '@mui/material'
// import ShareIcon from '@mui/icons-material/Share'

import { Info } from '../../components/Tickets/Info'
import { TicketContainer } from '../../components/Tickets/Container'
import { Status } from '../../components/Tickets/Status'
import { ButtonBlue } from '../../components/buttons'
import { CodeQR } from '../../components/CodeQR'
import { ArrowBack } from '../../components/icons/ArrowBack'
import { Loader } from '../../components/ui/Loader'
import { ErrorPage } from '../../components/Error'
import { WarningModal } from '../../components/bottom-panel/Alert/WarningInfo'

import { useAmountsStorage, useStore } from '../../store'

import { useGetExchains } from '../../hooks/useExchain'

// import { Baby, Elder } from '../../components/icons/Passengers'

import './styles.detail.css'
import { InternationalYellowCard } from '../../components/ui/Cards/Info'
import { Alert } from '../../components/bottom-panel/Alert'

const TicketDetailPage = () => {
  const { data: res } = useGetExchains({
    Authorization: localStorage.getItem('token'),
  })
  const minW800 = useMediaQuery('(min-width:800px)')

  const { state, pathname } = useLocation()
  const navegate = useNavigate()

  const [isWarning, setIsWarning] = useState(false)
  const [showIntertional, setShowInternational] = useState(false)
  const [totalPrice, setTotalPrice] = useState('0')
  // const [totalPriceDes, setTotalPriceDes] = useState('0')
  const [totalPriceBs, setTotalPriceBs] = useState(0)
  const [dolarRate, setDolarRate] = useState(0)

  const { selectedTicket, setSelectedTickets, setDestination } = useStore(
    ({ selectedTicket, setSelectedTickets, setDestination }) => ({
      selectedTicket,
      setSelectedTickets,
      setDestination,
    }),
  )

  const { setDayRate } = useAmountsStorage()

  // #region EFFECTS
  useEffect(() => {
    if (!selectedTicket?.passengers) return

    const totalUsd = selectedTicket.passengers
      .map((value) => {
        if (
          Object.keys(value).includes('isUnderageSeated') &&
          !value.isUnderageSeated
        ) {
          return 0
        }

        if (value.type === 'senior') {
          // return (selectedTicket.priceUsd * 50) / 100
          return selectedTicket.elderlyPrice ?? 0
        }

        return selectedTicket.priceUsd
      })
      .reduce((a, b) => a + b, 0)

    // const totalUsdDes = selectedTicket.passengers
    //   .map(({ ticketPrice }) => ticketPrice)
    //   .reduce((a, b) => a + b, 0)

    // setTotalPriceDes(totalUsdDes)

    setTotalPrice(totalUsd)

    if (res?.success && res?.data.length > 0) {
      const usdBcv = res?.data?.find(({ key }) => key === 'EXCHANGE_RATE_BS')
      if (usdBcv) {
        setDolarRate(usdBcv.value)

        setDayRate(Number(usdBcv?.value)?.toFixed(2))

        const bsVale = Number(usdBcv.value) * totalUsd
        setTotalPriceBs(bsVale)
      } else {
        const usdRate = localStorage.getItem('usdRate')
        const usdValue = usdRate ? usdRate : 36.36

        setDolarRate(usdValue)
        setDayRate(Number(usdValue)?.toFixed(2))

        const bsVale = usdValue * totalUsd
        setTotalPriceBs(bsVale)
      }
    }
  }, [selectedTicket, res, setDayRate])

  // #region FUNCTIONS
  const handleBooked = () => {
    setSelectedTickets({
      ...selectedTicket,
      dayRate: Number(dolarRate)?.toFixed(2),
    })
    navegate('/main/payment', {
      state: { previousPath: pathname, status: selectedTicket.status },
    })
  }

  // const handleShared = async () => {
  //   // Take the screenshot
  //   try {
  //     const ret = await Screenshot.take()
  //     // const capturedImage = `data:image/png;base64,${ret.base64}`

  //     const data = await Filesystem.writeFile({
  //       path: 'ticket.png',
  //       data: ret.base64,
  //       directory: Directory.Cache,
  //     })

  //     // Compartir la imagen capturada como URL
  //     await Share.share({
  //       title: 'Compartir pantalla',
  //       text: 'Echa un vistazo a esta imagen capturada',
  //       url: data.uri,
  //       dialogTitle: 'Compartir imagen',
  //     })

  //     await Filesystem.deleteFile({
  //       path: 'ticket.png',
  //       directory: Directory.Cache,
  //     })
  //   } catch (error) {
  //     console.error('Error al capturar y compartir pantalla:', error)
  //   }
  // }

  if (Object.keys(selectedTicket).length === 0) {
    return <Loader />
  }

  if (!selectedTicket || !selectedTicket?.passengers) {
    return <ErrorPage />
  }

  if (minW800) {
    return (
      <>
        <Slide direction='up' in={true} timeout={200}>
          <section
            className='ticket-detail_container'
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: 'calc(100% - 134px)',
              minHeight: '700px',
              bottom: 0,
              borderRadius: 0,
              background: 'transparent',
            }}>
            <Box
              style={{
                maxWidth: '600px',
                height: '100%',
                width: '100%',
                overflowY: 'scroll',
              }}>
              <Box
                className='ticket-detail_header_container'
                onClick={() => navegate(state?.previousPath || '/main/ticket')}>
                <ArrowBack />

                <h2>Detalle del boleto</h2>
              </Box>

              <section className='container_tickets'>
                <TicketContainer type='head'>
                  <div className='ticket-detail_header'>
                    <section className='ticket-detail_header_info'>
                      <div onClick={() => setIsWarning(true)}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='20'
                          height='20'
                          viewBox='0 0 20 20'
                          fill='none'>
                          <path
                            d='M10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0ZM10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2ZM9.99 8C10.548 8 11 8.452 11 9.01V14.134C11.1906 14.2441 11.3396 14.414 11.4238 14.6173C11.5081 14.8207 11.5229 15.0462 11.4659 15.2588C11.4089 15.4714 11.2834 15.6593 11.1087 15.7933C10.9341 15.9273 10.7201 16 10.5 16H10.01C9.87737 16 9.74603 15.9739 9.62349 15.9231C9.50095 15.8724 9.38961 15.798 9.29582 15.7042C9.20203 15.6104 9.12764 15.499 9.07688 15.3765C9.02612 15.254 9 15.1226 9 14.99V10C8.73478 10 8.48043 9.89464 8.29289 9.70711C8.10536 9.51957 8 9.26522 8 9C8 8.73478 8.10536 8.48043 8.29289 8.29289C8.48043 8.10536 8.73478 8 9 8H9.99ZM10 5C10.2652 5 10.5196 5.10536 10.7071 5.29289C10.8946 5.48043 11 5.73478 11 6C11 6.26522 10.8946 6.51957 10.7071 6.70711C10.5196 6.89464 10.2652 7 10 7C9.73478 7 9.48043 6.89464 9.29289 6.70711C9.10536 6.51957 9 6.26522 9 6C9 5.73478 9.10536 5.48043 9.29289 5.29289C9.48043 5.10536 9.73478 5 10 5Z'
                            fill='#29348F'
                          />
                        </svg>
                      </div>

                      <div>
                        <h3 className='ticket-detail_price'>
                          Ref {totalPrice}
                        </h3>
                        <h3 className='ticket-detail_ref'>
                          Bs.{' '}
                          {new Intl.NumberFormat('es-VE', {
                            currency: 'USD',
                          }).format(totalPriceBs?.toFixed(2))}
                        </h3>

                        {/* {totalPriceDes !== totalPrice && (
                      <p className='ticket-detail_ref discount'>
                        Ref {totalPrice}
                      </p>
                    )} */}
                      </div>
                    </section>

                    <h1 className='ticket-detail_title'>
                      {selectedTicket.busLine}
                    </h1>
                  </div>
                  <Info
                    date={selectedTicket.date}
                    startTime={selectedTicket.startTime}
                    endTime={selectedTicket.endTime}
                    startLocation={selectedTicket.startLocation}
                    endLocation={selectedTicket.endLocation}
                  />

                  {selectedTicket?.children?.length > 0 && (
                    <section
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}>
                      <div
                        style={{
                          width: '75%',
                        }}
                        onClick={() => setShowInternational(true)}>
                        <InternationalYellowCard
                          title='Más sobre conexiones internacionales'
                          noMargin={true}
                        />
                      </div>
                    </section>
                  )}
                </TicketContainer>

                <TicketContainer type='body'>
                  <section className='passenger_container'>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}>
                      <h3 className='passenger_title'>Pasajeros</h3>
                      <h3 className='passenger_title'>Documentos</h3>
                    </div>

                    <section
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '15px',
                        marginTop: '8px',
                      }}>
                      {selectedTicket?.passengers?.map(
                        ({ firstName, lastName, type, documentId }, i) => {
                          return (
                            <div
                              key={i}
                              style={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                justifyContent: 'space-between',
                              }}>
                              <Box>
                                {type === 'underage' ? (
                                  <p
                                    style={{
                                      fontSize: '10px',
                                      paddingBottom: '2px',
                                    }}>
                                    Menor de 4
                                  </p>
                                ) : type === 'senior' ? (
                                  <p
                                    style={{
                                      fontSize: '10px',
                                      paddingBottom: '2px',
                                    }}>
                                    3ra edad
                                  </p>
                                ) : (
                                  <p
                                    style={{
                                      fontSize: '10px',
                                      paddingBottom: '2px',
                                    }}>
                                    General
                                  </p>
                                )}
                                <p className='passenger_text'>
                                  {`${firstName} ${lastName}`}{' '}
                                </p>
                              </Box>
                              <p className='passenger_text'>
                                {documentId ?? '-'}
                              </p>
                            </div>
                          )
                        },
                      )}
                    </section>
                  </section>

                  <section className='flex-center'>
                    <div className='passenger-detail_disclaimer'>
                      <p>
                        <span>IMPORTANTE:</span> Debes estar al menos 1 hora{' '}
                        <br />
                        antes de la hora de salida en el terminal.
                      </p>
                    </div>
                  </section>
                </TicketContainer>

                {selectedTicket.status === 'active' && (
                  <TicketContainer type='body'>
                    <p className='detail-suport-text'>
                      <span style={{ textDecoration: 'underline' }}>
                        48 horas antes de tu viaje
                      </span>{' '}
                      podrás <br /> seleccionar tus puestos.
                    </p>

                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '10px 0px',
                      }}>
                      <ButtonBlue
                        onClick={() => {
                          setDestination({
                            to: selectedTicket?.endLocation?.long,
                            from: selectedTicket?.startLocation?.long,
                            date: selectedTicket.date,
                            shortTo: selectedTicket?.endLocation?.short,
                            shortFrom: selectedTicket?.startLocation?.short,
                          })
                          navegate(
                            `/main/seat?ticketsNumber=${selectedTicket?.passengers?.length}`,
                          )
                        }}>
                        Escoger asientos
                      </ButtonBlue>
                    </Box>
                  </TicketContainer>
                )}

                <TicketContainer type='footer'>
                  <div className='center mb-2'>
                    <Status status={selectedTicket.status} />
                  </div>

                  {selectedTicket.status === 'waiting_verification' &&
                  selectedTicket.paymentStatus === 'pending_verification' ? (
                    <section className='center' style={{ gap: '13px' }}>
                      <p className='detail-verify-text'>
                        Tu pago será verificado dentro de las <br /> primeras 24
                        horas después de tu compra.
                      </p>

                      <section className='mt-2'>
                        <p className='detail-suport-text'>
                          Si tienes alguna duda, puedes escribir a soporte.
                        </p>

                        <div className='flex-center mt-2'>
                          <ButtonBlue>
                            <a
                              href='https://api.whatsapp.com/send?phone=584242229359'
                              target='_blank'
                              rel='noreferrer'
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '5px',
                              }}>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='13'
                                height='13'
                                viewBox='0 0 13 13'
                                fill='none'>
                                <path
                                  d='M11.1055 1.89153C10.5066 1.28959 9.79323 0.81232 9.007 0.48757C8.22077 0.162821 7.37741 -0.00291207 6.52613 3.87201e-05C2.9593 3.87201e-05 0.0522614 2.89253 0.0522614 6.44152C0.0522614 7.57902 0.352764 8.68401 0.914573 9.65901L0 13L3.42965 12.103C4.37688 12.6165 5.44171 12.8895 6.52613 12.8895C10.093 12.8895 13 9.99701 13 6.44802C13 4.72552 12.3271 3.10703 11.1055 1.89153ZM6.52613 11.7975C5.5593 11.7975 4.61206 11.5375 3.78241 11.05L3.58643 10.933L1.54824 11.466L2.09045 9.49001L1.9598 9.28851C1.42265 8.43504 1.13743 7.44852 1.13668 6.44152C1.13668 3.49053 3.55377 1.08554 6.5196 1.08554C7.95678 1.08554 9.30904 1.64453 10.3216 2.65853C10.823 3.15511 11.2203 3.74576 11.4906 4.39625C11.7608 5.04675 11.8986 5.74414 11.896 6.44802C11.909 9.39901 9.49196 11.7975 6.52613 11.7975ZM9.47889 7.79352C9.31558 7.71552 8.51859 7.32552 8.37487 7.26702C8.22462 7.21502 8.1201 7.18902 8.00904 7.34502C7.89799 7.50752 7.59095 7.87152 7.4995 7.97551C7.40804 8.08601 7.31005 8.09901 7.14673 8.01451C6.98342 7.93651 6.4608 7.76102 5.84673 7.21502C5.36332 6.78602 5.04322 6.25952 4.94523 6.09702C4.85377 5.93452 4.93216 5.85002 5.01709 5.76552C5.08894 5.69402 5.1804 5.57702 5.25879 5.48602C5.33719 5.39502 5.36985 5.32352 5.42211 5.21952C5.47437 5.10902 5.44824 5.01802 5.40904 4.94002C5.36985 4.86202 5.04322 4.06903 4.91256 3.74403C4.78191 3.43203 4.64472 3.47103 4.54673 3.46453H4.23317C4.12211 3.46453 3.95226 3.50353 3.80201 3.66603C3.65829 3.82853 3.2402 4.21853 3.2402 5.01152C3.2402 5.80452 3.82161 6.57152 3.9 6.67552C3.97839 6.78602 5.04322 8.41101 6.66332 9.10651C7.04874 9.27551 7.34925 9.37301 7.58442 9.44451C7.96985 9.56801 8.32261 9.54851 8.60352 9.50951C8.91709 9.46401 9.56382 9.11951 9.69447 8.74251C9.83166 8.36551 9.83166 8.04702 9.78593 7.97551C9.7402 7.90402 9.64221 7.87151 9.47889 7.79352Z'
                                  fill='#FDFDFD'
                                />
                              </svg>
                              <span style={{ color: 'white' }}>
                                Contactar línea
                              </span>
                            </a>
                          </ButtonBlue>
                        </div>
                      </section>
                    </section>
                  ) : selectedTicket.status === 'waiting_seats' ? (
                    <>
                      <p className='detail-suport-text'>
                        <span style={{ textDecoration: 'underline' }}>
                          48 horas antes de tu viaje
                        </span>{' '}
                        podrás <br /> seleccionar tus puestos.
                      </p>
                    </>
                  ) : selectedTicket.status === 'booked' ? (
                    <>
                      <p className='detail-verify-text'>
                        Puedes pagar el restante de tus boletos en taquilla o en
                        la app través de pago móvil.
                      </p>

                      <div style={{ marginTop: '15px' }}>
                        <p className='number_booked_text'>
                          Número de comprobante de reserva:
                        </p>

                        <p
                          className='number_booked_value'
                          style={{ marginTop: '3px' }}>
                          {selectedTicket.voucherNumber}
                        </p>
                      </div>

                      {selectedTicket.paymentStatus === 'paid' && (
                        <div className='flex-center'>
                          <ButtonBlue buttonStyle={{ marginTop: '15px' }}>
                            <div
                              onClick={handleBooked}
                              style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '5px',
                              }}>
                              <svg
                                width='10'
                                height='14'
                                viewBox='0 0 10 14'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'>
                                <path
                                  d='M8.57143 11.4545H1.42857V2.54545H8.57143M8.57143 0H1.42857C0.635714 0 0 0.566364 0 1.27273V12.7273C0 13.0648 0.15051 13.3885 0.418419 13.6272C0.686328 13.8659 1.04969 14 1.42857 14H8.57143C8.95031 14 9.31367 13.8659 9.58158 13.6272C9.84949 13.3885 10 13.0648 10 12.7273V1.27273C10 0.935179 9.84949 0.611456 9.58158 0.372773C9.31367 0.134091 8.95031 0 8.57143 0Z'
                                  fill='white'
                                />
                              </svg>
                              <span style={{ color: 'white' }}>
                                Completar pago
                              </span>
                            </div>
                          </ButtonBlue>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className='center mt-2'>
                        <CodeQR ticketId={selectedTicket.id} />
                      </div>

                      <div className='center'>
                        <p className='passenger-code'>
                          {selectedTicket.voucherNumber}
                        </p>
                      </div>

                      {/* <Box style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                      variant='contained'
                      style={{
                        borderRadius: '50%',
                        width: 36,
                        height: 36,
                        minWidth: 0,
                      }}
                      onClick={() => handleShared()}>
                      <ShareIcon />
                    </Button>
                  </Box> */}
                    </>
                  )}
                </TicketContainer>
              </section>
            </Box>
          </section>
        </Slide>

        <WarningModal
          isWarning={isWarning}
          setIsWarning={setIsWarning}
          showCheck={false}
          onClick={() => setIsWarning(false)}
        />

        <Alert
          type='warning'
          isShow={showIntertional}
          setShow={setShowInternational}
          onClickButton={() => setShowInternational(false)}
          title='Boleto con conexión'>
          <p style={{ textAlign: 'center' }}>
            Al comprar un boleto con conexión deberás abordar dos autobuses para
            llegar hasta tu destino final.
          </p>
          <p style={{ textAlign: 'center' }}>
            El costo del boleto incluye ambos traslados.
          </p>
        </Alert>
      </>
    )
  }

  // #region HTML
  return (
    <>
      <Slide direction='up' in={true} timeout={200}>
        <section
          className='ticket-detail_container'
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}>
          <Box style={{ maxWidth: '600px', height: '100%', width: '100%' }}>
            <Box
              className='ticket-detail_header_container'
              onClick={() => navegate(state?.previousPath || '/main/ticket')}>
              <ArrowBack />

              <h2>Detalle del boleto</h2>
            </Box>

            <section className='container_tickets'>
              <TicketContainer type='head'>
                <div className='ticket-detail_header'>
                  <section className='ticket-detail_header_info'>
                    <div onClick={() => setIsWarning(true)}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='20'
                        viewBox='0 0 20 20'
                        fill='none'>
                        <path
                          d='M10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0ZM10 2C7.87827 2 5.84344 2.84285 4.34315 4.34315C2.84285 5.84344 2 7.87827 2 10C2 12.1217 2.84285 14.1566 4.34315 15.6569C5.84344 17.1571 7.87827 18 10 18C12.1217 18 14.1566 17.1571 15.6569 15.6569C17.1571 14.1566 18 12.1217 18 10C18 7.87827 17.1571 5.84344 15.6569 4.34315C14.1566 2.84285 12.1217 2 10 2ZM9.99 8C10.548 8 11 8.452 11 9.01V14.134C11.1906 14.2441 11.3396 14.414 11.4238 14.6173C11.5081 14.8207 11.5229 15.0462 11.4659 15.2588C11.4089 15.4714 11.2834 15.6593 11.1087 15.7933C10.9341 15.9273 10.7201 16 10.5 16H10.01C9.87737 16 9.74603 15.9739 9.62349 15.9231C9.50095 15.8724 9.38961 15.798 9.29582 15.7042C9.20203 15.6104 9.12764 15.499 9.07688 15.3765C9.02612 15.254 9 15.1226 9 14.99V10C8.73478 10 8.48043 9.89464 8.29289 9.70711C8.10536 9.51957 8 9.26522 8 9C8 8.73478 8.10536 8.48043 8.29289 8.29289C8.48043 8.10536 8.73478 8 9 8H9.99ZM10 5C10.2652 5 10.5196 5.10536 10.7071 5.29289C10.8946 5.48043 11 5.73478 11 6C11 6.26522 10.8946 6.51957 10.7071 6.70711C10.5196 6.89464 10.2652 7 10 7C9.73478 7 9.48043 6.89464 9.29289 6.70711C9.10536 6.51957 9 6.26522 9 6C9 5.73478 9.10536 5.48043 9.29289 5.29289C9.48043 5.10536 9.73478 5 10 5Z'
                          fill='#29348F'
                        />
                      </svg>
                    </div>

                    <div>
                      <h3 className='ticket-detail_price'>Ref {totalPrice}</h3>
                      <h3 className='ticket-detail_ref'>
                        Bs.{' '}
                        {new Intl.NumberFormat('es-VE', {
                          currency: 'USD',
                        }).format(totalPriceBs?.toFixed(2))}
                      </h3>

                      {/* {totalPriceDes !== totalPrice && (
                      <p className='ticket-detail_ref discount'>
                        Ref {totalPrice}
                      </p>
                    )} */}
                    </div>
                  </section>

                  <h1 className='ticket-detail_title'>
                    {selectedTicket.busLine}
                  </h1>
                </div>
                <Info
                  date={selectedTicket.date}
                  startTime={selectedTicket.startTime}
                  endTime={selectedTicket.endTime}
                  startLocation={selectedTicket.startLocation}
                  endLocation={selectedTicket.endLocation}
                />

                {selectedTicket?.children?.length > 0 && (
                  <section
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}>
                    <div
                      style={{
                        width: '75%',
                      }}
                      onClick={() => setShowInternational(true)}>
                      <InternationalYellowCard
                        title='Más sobre conexiones internacionales'
                        noMargin={true}
                      />
                    </div>
                  </section>
                )}
              </TicketContainer>

              <TicketContainer type='body'>
                <section className='passenger_container'>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}>
                    <h3 className='passenger_title'>Pasajeros</h3>
                    <h3 className='passenger_title'>Documentos</h3>
                  </div>

                  <section
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '15px',
                      marginTop: '8px',
                    }}>
                    {selectedTicket?.passengers?.map(
                      ({ firstName, lastName, type, documentId }, i) => {
                        return (
                          <div
                            key={i}
                            style={{
                              display: 'flex',
                              alignItems: 'flex-end',
                              justifyContent: 'space-between',
                            }}>
                            <Box>
                              {type === 'underage' ? (
                                <p
                                  style={{
                                    fontSize: '10px',
                                    paddingBottom: '2px',
                                  }}>
                                  Menor de 4
                                </p>
                              ) : type === 'senior' ? (
                                <p
                                  style={{
                                    fontSize: '10px',
                                    paddingBottom: '2px',
                                  }}>
                                  3ra edad
                                </p>
                              ) : (
                                <p
                                  style={{
                                    fontSize: '10px',
                                    paddingBottom: '2px',
                                  }}>
                                  General
                                </p>
                              )}
                              <p className='passenger_text'>
                                {`${firstName} ${lastName}`}{' '}
                              </p>
                            </Box>
                            <p className='passenger_text'>
                              {documentId ?? '-'}
                            </p>
                          </div>
                        )
                      },
                    )}
                  </section>
                </section>

                <section className='flex-center'>
                  <div className='passenger-detail_disclaimer'>
                    <p>
                      <span>IMPORTANTE:</span> Debes estar al menos 1 hora{' '}
                      <br />
                      antes de la hora de salida en el terminal.
                    </p>
                  </div>
                </section>
              </TicketContainer>

              {/* {selectedTicket.status === 'spacial' && (
                <TicketContainer type='body'>
                  <p className='detail-suport-text'>
                    <span style={{ textDecoration: 'underline' }}>
                      48 horas antes de tu viaje
                    </span>{' '}
                    podrás <br /> seleccionar tus puestos.
                  </p>

                  <Box
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      padding: '10px 0px',
                    }}>
                    <ButtonBlue
                      onClick={() => {
                        setDestination({
                          to: selectedTicket?.endLocation?.long,
                          from: selectedTicket?.startLocation?.long,
                          date: selectedTicket.date,
                          shortTo: selectedTicket?.endLocation?.short,
                          shortFrom: selectedTicket?.startLocation?.short,
                        })
                        navegate(
                          `/main/seat?ticketsNumber=${selectedTicket?.passengers?.length}`,
                        )
                      }}>
                      Escoger asientos
                    </ButtonBlue>
                  </Box>
                </TicketContainer>
              )} */}

              <TicketContainer type='footer'>
                <div className='center mb-2'>
                  <Status status={selectedTicket.status} />
                </div>

                {selectedTicket.status === 'waiting_verification' &&
                selectedTicket.paymentStatus === 'pending_verification' ? (
                  <section className='center' style={{ gap: '13px' }}>
                    <p className='detail-verify-text'>
                      Tu pago será verificado dentro de las <br /> primeras 24
                      horas después de tu compra.
                    </p>

                    <section className='mt-2'>
                      <p className='detail-suport-text'>
                        Si tienes alguna duda, puedes escribir a soporte.
                      </p>

                      <div className='flex-center mt-2'>
                        <ButtonBlue>
                          <a
                            href='https://api.whatsapp.com/send?phone=584242229359'
                            target='_blank'
                            rel='noreferrer'
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: '5px',
                            }}>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='13'
                              height='13'
                              viewBox='0 0 13 13'
                              fill='none'>
                              <path
                                d='M11.1055 1.89153C10.5066 1.28959 9.79323 0.81232 9.007 0.48757C8.22077 0.162821 7.37741 -0.00291207 6.52613 3.87201e-05C2.9593 3.87201e-05 0.0522614 2.89253 0.0522614 6.44152C0.0522614 7.57902 0.352764 8.68401 0.914573 9.65901L0 13L3.42965 12.103C4.37688 12.6165 5.44171 12.8895 6.52613 12.8895C10.093 12.8895 13 9.99701 13 6.44802C13 4.72552 12.3271 3.10703 11.1055 1.89153ZM6.52613 11.7975C5.5593 11.7975 4.61206 11.5375 3.78241 11.05L3.58643 10.933L1.54824 11.466L2.09045 9.49001L1.9598 9.28851C1.42265 8.43504 1.13743 7.44852 1.13668 6.44152C1.13668 3.49053 3.55377 1.08554 6.5196 1.08554C7.95678 1.08554 9.30904 1.64453 10.3216 2.65853C10.823 3.15511 11.2203 3.74576 11.4906 4.39625C11.7608 5.04675 11.8986 5.74414 11.896 6.44802C11.909 9.39901 9.49196 11.7975 6.52613 11.7975ZM9.47889 7.79352C9.31558 7.71552 8.51859 7.32552 8.37487 7.26702C8.22462 7.21502 8.1201 7.18902 8.00904 7.34502C7.89799 7.50752 7.59095 7.87152 7.4995 7.97551C7.40804 8.08601 7.31005 8.09901 7.14673 8.01451C6.98342 7.93651 6.4608 7.76102 5.84673 7.21502C5.36332 6.78602 5.04322 6.25952 4.94523 6.09702C4.85377 5.93452 4.93216 5.85002 5.01709 5.76552C5.08894 5.69402 5.1804 5.57702 5.25879 5.48602C5.33719 5.39502 5.36985 5.32352 5.42211 5.21952C5.47437 5.10902 5.44824 5.01802 5.40904 4.94002C5.36985 4.86202 5.04322 4.06903 4.91256 3.74403C4.78191 3.43203 4.64472 3.47103 4.54673 3.46453H4.23317C4.12211 3.46453 3.95226 3.50353 3.80201 3.66603C3.65829 3.82853 3.2402 4.21853 3.2402 5.01152C3.2402 5.80452 3.82161 6.57152 3.9 6.67552C3.97839 6.78602 5.04322 8.41101 6.66332 9.10651C7.04874 9.27551 7.34925 9.37301 7.58442 9.44451C7.96985 9.56801 8.32261 9.54851 8.60352 9.50951C8.91709 9.46401 9.56382 9.11951 9.69447 8.74251C9.83166 8.36551 9.83166 8.04702 9.78593 7.97551C9.7402 7.90402 9.64221 7.87151 9.47889 7.79352Z'
                                fill='#FDFDFD'
                              />
                            </svg>
                            <span style={{ color: 'white' }}>
                              Contactar línea
                            </span>
                          </a>
                        </ButtonBlue>
                      </div>
                    </section>
                  </section>
                ) : selectedTicket.status === 'waiting_seats' ? (
                  <>
                    <p className='detail-suport-text'>
                      <span style={{ textDecoration: 'underline' }}>
                        48 horas antes de tu viaje
                      </span>{' '}
                      podrás <br /> seleccionar tus puestos.
                    </p>
                  </>
                ) : selectedTicket.status === 'booked' ? (
                  <>
                    <p className='detail-verify-text'>
                      Puedes pagar el restante de tus boletos en taquilla o en
                      la app través de pago móvil.
                    </p>

                    <div style={{ marginTop: '15px' }}>
                      <p className='number_booked_text'>
                        Número de comprobante de reserva:
                      </p>

                      <p
                        className='number_booked_value'
                        style={{ marginTop: '3px' }}>
                        {selectedTicket.voucherNumber}
                      </p>
                    </div>

                    {selectedTicket.paymentStatus === 'paid' && (
                      <div className='flex-center'>
                        <ButtonBlue buttonStyle={{ marginTop: '15px' }}>
                          <div
                            onClick={handleBooked}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              gap: '5px',
                            }}>
                            <svg
                              width='10'
                              height='14'
                              viewBox='0 0 10 14'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'>
                              <path
                                d='M8.57143 11.4545H1.42857V2.54545H8.57143M8.57143 0H1.42857C0.635714 0 0 0.566364 0 1.27273V12.7273C0 13.0648 0.15051 13.3885 0.418419 13.6272C0.686328 13.8659 1.04969 14 1.42857 14H8.57143C8.95031 14 9.31367 13.8659 9.58158 13.6272C9.84949 13.3885 10 13.0648 10 12.7273V1.27273C10 0.935179 9.84949 0.611456 9.58158 0.372773C9.31367 0.134091 8.95031 0 8.57143 0Z'
                                fill='white'
                              />
                            </svg>
                            <span style={{ color: 'white' }}>
                              Completar pago
                            </span>
                          </div>
                        </ButtonBlue>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className='center mt-2'>
                      <CodeQR ticketId={selectedTicket.id} />
                    </div>

                    <div className='center'>
                      <p className='passenger-code'>
                        {selectedTicket.voucherNumber}
                      </p>
                    </div>

                    {/* <Box style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                      variant='contained'
                      style={{
                        borderRadius: '50%',
                        width: 36,
                        height: 36,
                        minWidth: 0,
                      }}
                      onClick={() => handleShared()}>
                      <ShareIcon />
                    </Button>
                  </Box> */}
                  </>
                )}
              </TicketContainer>
            </section>
          </Box>
        </section>
      </Slide>

      <WarningModal
        isWarning={isWarning}
        setIsWarning={setIsWarning}
        showCheck={false}
        onClick={() => setIsWarning(false)}
      />

      <Alert
        type='warning'
        isShow={showIntertional}
        setShow={setShowInternational}
        onClickButton={() => setShowInternational(false)}
        title='Boleto con conexión'>
        <p style={{ textAlign: 'center' }}>
          Al comprar un boleto con conexión deberás abordar dos autobuses para
          llegar hasta tu destino final.
        </p>
        <p style={{ textAlign: 'center' }}>
          El costo del boleto incluye ambos traslados.
        </p>
      </Alert>
    </>
  )
}

export default TicketDetailPage

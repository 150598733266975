import { useEffect, useRef, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'

import { Capacitor } from '@capacitor/core'
import { Keyboard } from '@capacitor/keyboard'

import { Box, useMediaQuery } from '@mui/material'

import { NavMenu } from '../../components/NavMenu'
import { HeaderMenu } from '../../components/Header'

import { useMeasureStorage } from '../../store'

import { Footer } from '../../components/Footer'
import { busiAPi } from '../../utils/urls'
import './styles.css'

export default function MainPage() {
  const { pathname } = useLocation()
  const minW800 = useMediaQuery('(min-width:800px)')
  // const maxW768 = useMediaQuery('(min-width:584px)')

  const { mainHeight, setMainHeight, setHeaderHeight, setFooterHeight } =
    useMeasureStorage()

  const [visibility, setVisibility] = useState('inherit')
  const [hTop, setHTop] = useState(0)
  const [hBottom, setHBottom] = useState(0)

  const refHeader = useRef(null)
  const refFooter = useRef(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const headerHeight = refHeader.current?.clientHeight ?? 0
    let footerHeight = refFooter.current?.clientHeight ?? 0

    footerHeight =
      Capacitor.getPlatform() === 'ios' &&
      footerHeight === 66 &&
      pathname.includes('/main/buy/detail')
        ? 100
        : footerHeight

    setHTop(headerHeight)
    setHBottom(footerHeight)
  }, [visibility, pathname])

  useEffect(() => {
    setHeaderHeight(hTop)
    setFooterHeight(hBottom)
    setMainHeight(hTop + hBottom)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hTop, hBottom, setMainHeight])

  useEffect(() => {
    if (Capacitor.getPlatform() !== 'web') {
      Keyboard.addListener('keyboardWillShow', (info) => {
        setVisibility('none')
      })

      Keyboard.addListener('keyboardWillHide', () => {
        setVisibility('inherit')
      })
    }
  }, [])

  // function navigateToMenu() {
  //   setOpen(true)
  // }

  return (
    <section className='pages-main'>
      <header ref={refHeader} className='pages-main-top'>
        {/* <div onClick={navigateToMenu}>
              <img src='/assets/menu.svg' alt='burger menu'></img>
            </div> */}

        {/* <p className='pages-main-title'>
            {pathname.includes('ticket') || pathname.includes('all')
              ? 'Mis Boletos'
              : 'Buscador'}
          </p> */}
        {busiAPi.includes('dev') && !minW800 && (
          <h2 className='pages-login_dev'>USO INTERNO</h2>
        )}
        {busiAPi.includes('stage') && !minW800 && (
          <h2 className='pages-login_dev'>(STAGE) USO INTERNO</h2>
        )}
        {minW800 && <HeaderMenu />}
      </header>

      {minW800 && (
        <Box
          component='section'
          style={{
            // height: `calc(100vh - ${mainHeight}px)`,
            height: `calc(100vh - 110px)`,
            backgroundColor: '#f0eeee',
            width: '100%',
            minWidth: '320px',
            position: 'relative',
            bottom: '18px',
            borderRadius: '20px 20px 0px 0px',
            overflowY: 'scroll',
            // display: 'flex',
            // justifyContent: 'center',
            // flexDirection: 'column',
            // alignItems: 'center',
          }}>
          <Outlet></Outlet>
          <Footer />
        </Box>
      )}

      {!minW800 && (
        <Box
          component='section'
          style={{
            height: `calc(100vh - ${mainHeight}px)`,
            backgroundColor: '#f0eeee',
            width: '100%',
            minWidth: '320px',
          }}>
          <Outlet></Outlet>
        </Box>
      )}

      {!minW800 && (
        <footer
          ref={refFooter}
          className='pages-main-bottom'
          style={{ display: visibility }}>
          <NavMenu />
        </footer>
      )}

      {/* <Drawer
        open={open}
        onClose={() => setOpen(false)}
        transitionDuration={350}>
        <SidebarMenu onClose={() => setOpen(false)} />
      </Drawer> */}
    </section>
  )
}

// import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import dayjs from 'dayjs'
// import customParseFormat from 'dayjs/plugin/customParseFormat'

import { ArrowBack } from '../../components/icons/ArrowBack'

import { useMeasureStorage, useStore } from '../../store'
import { Box, useMediaQuery } from '@mui/material'
// import { Capacitor } from '@capacitor/core'

import './searchBar.css'

export const SearchResult = ({ previousPath = null }) => {
  const navegate = useNavigate()
  const minW800 = useMediaQuery('(min-width:800px)')

  const { destination } = useStore(({ destination }) => ({ destination }))
  const { headerHeight } = useMeasureStorage()

  const destinationTime = () => {
    if (!destination.date) {
      return null
    }
    const date = dayjs(destination.date, 'DD/MM/YYYY')
    const matchDate = Intl.DateTimeFormat('es-VE', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
    }).format(date.toDate())
    // .replaceAll('de ', '')

    return matchDate
    // return dayjs(destination.date, 'DD/MM/YYYY', 'es').format('DD MMMM YYYY')
  }
  if (minW800) {
    return (
      <section
        style={{ top: '-1px' }}
        // style={{ top: '0px' }}
        className='search-bar_container_old'>
        <Box
          className='search-bar-result_container'
          component='section'
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '6px',
            maxWidth: '600px',
            width: '100%',
          }}>
          <div className='search-bar-result_header'>
            <span onClick={() => navegate(previousPath ?? '/main/search')}>
              <ArrowBack color='#fff' />
            </span>

            <h2 className='search-bar-result_title'>Selección de boletos</h2>
          </div>

          <div className='search-bar_location_container'>
            <p className='location_text'>
              {destination.from} ({destination.shortFrom})
            </p>
            <hr className='divider-dash_search' />
            <p className='location_text'>
              {destination.to} ({destination.shortTo})
            </p>
          </div>

          <p className='search-bar_date_text'>{destinationTime()}</p>
        </Box>
      </section>
    )
  }

  return (
    <section
      style={{ top: `calc(${headerHeight}px - 18px` }}
      // style={{ top: '0px' }}
      className='search-bar_container_old'>
      <Box
        className='search-bar-result_container'
        component='section'
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
          maxWidth: '600px',
          width: '100%',
        }}>
        <div className='search-bar-result_header'>
          <span onClick={() => navegate(previousPath ?? '/main/search')}>
            <ArrowBack color='#fff' />
          </span>

          <h2 className='search-bar-result_title'>Selección de boletos</h2>
        </div>

        <div className='search-bar_location_container'>
          <p className='location_text'>
            {destination.from} ({destination.shortFrom})
          </p>
          <hr className='divider-dash_search' />
          <p className='location_text'>
            {destination.to} ({destination.shortTo})
          </p>
        </div>

        <p className='search-bar_date_text'>{destinationTime()}</p>
      </Box>
    </section>
  )
}

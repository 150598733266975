// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio_container {
  display: flex;
  align-items: center;
  gap: 5px;

  & label {
    color: #29348f;
    font-family: Kufam;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 157%; /* 21.98px */
    height: 20px;
  }
  & input[type='radio']:checked:before {
    accent-color: #29348f;
  }

  & input[type='radio']:checked {
    accent-color: #29348f;
    height: 16px;
    width: 16px;
  }
}

@media (max-width: 390px) {
  label {
    font-size: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/Radio/styles.radio.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;;EAER;IACE,cAAc;IACd,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB,EAAE,YAAY;IAC/B,YAAY;EACd;EACA;IACE,qBAAqB;EACvB;;EAEA;IACE,qBAAqB;IACrB,YAAY;IACZ,WAAW;EACb;AACF;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".radio_container {\n  display: flex;\n  align-items: center;\n  gap: 5px;\n\n  & label {\n    color: #29348f;\n    font-family: Kufam;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 157%; /* 21.98px */\n    height: 20px;\n  }\n  & input[type='radio']:checked:before {\n    accent-color: #29348f;\n  }\n\n  & input[type='radio']:checked {\n    accent-color: #29348f;\n    height: 16px;\n    width: 16px;\n  }\n}\n\n@media (max-width: 390px) {\n  label {\n    font-size: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { useEffect } from 'react'
import { Copy } from '../../../icons/Copy'

import { BanksSelect } from '../../../imputs/banks'
import { FormsPhoneNumber } from '../../../imputs/forms-phone-number'
import { Box } from '@mui/material'

import FormsCitizensId from '../../../imputs/forms-citizens-id'

import { ValidationCode } from '../../../imputs/forms-validation-code'
// import { InputFile } from '../../../imputs/forms-file'
import { useStore, useAmountsStorage } from '../../../../store'
import { useGetBanksAcounts } from '../../../../hooks/useBanksAccount'
import { useGetPaymentRemaining } from '../../../../hooks/usePaymentRemain'

import './styles.movil.css'

export const PagoMovilCard = () => {
  return (
    <section className='card-movil_container'>
      <div className='icon_movil_container'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='22'
          height='28'
          viewBox='0 0 22 28'
          fill='none'>
          <path
            d='M18.4526 22.9091H3.63116V5.09091H18.4526M18.4526 0H3.63116C1.98598 0 0.66687 1.13273 0.66687 2.54545V25.4545C0.66687 26.1296 0.979178 26.7771 1.53509 27.2545C2.091 27.7318 2.84498 28 3.63116 28H18.4526C19.2388 28 19.9927 27.7318 20.5486 27.2545C21.1046 26.7771 21.4169 26.1296 21.4169 25.4545V2.54545C21.4169 1.87036 21.1046 1.22291 20.5486 0.745546C19.9927 0.268181 19.2388 0 18.4526 0Z'
            fill='#29348F'
          />
        </svg>

        <p className='movil_text'>Pago móvil</p>
      </div>
    </section>
  )
}

export const PagoMovilData = ({
  isReserve = false,
  page = 'payment',
  isCompleteBooked = false,
}) => {
  const { selectedTrip, selectedTicket, setWsLink } = useStore()

  const {
    totalBs,
    totalUsd,
    // totalUsdDiscount,
    // totalBsDiscount,
    dayRate,
    //
    perTotalBs,
    perTotalUsd,
    setPerTotalBs,
    setPerTotalUsd,
    completedBookedUsd,
    completedBookedBs,
    setCompletedBookedUsd,
    setCompletedBookedBs,
  } = useAmountsStorage()

  const { data: info } = useGetBanksAcounts({
    requestHeaders: {},
    requestParams: {
      busLine: selectedTrip?.busLine ?? selectedTicket.busLine,
      busStation: selectedTrip.originName ?? selectedTicket.startLocation.long,
    },
  })

  const { data: remainData } = useGetPaymentRemaining(selectedTicket.id)

  // #region EFFECTS
  useEffect(() => {
    let percentInBs = (totalBs * selectedTrip.bookingPercentage) / 100

    percentInBs = new Intl.NumberFormat('es-VE', {
      currency: 'USD',
    }).format(percentInBs?.toFixed(2))

    setPerTotalBs(percentInBs)

    const percentInUsd = (totalUsd * selectedTrip.bookingPercentage) / 100

    setPerTotalUsd(percentInUsd)
  }, [selectedTrip, totalBs, totalUsd])

  useEffect(() => {
    if (remainData && remainData?.data?.remaining > 0) {
      const priceRef = remainData?.data?.remaining
      setCompletedBookedUsd(priceRef)

      if (dayRate) {
        const priceBs = dayRate * priceRef

        setCompletedBookedBs(
          new Intl.NumberFormat('es-VE', {
            currency: 'USD',
          }).format(priceBs?.toFixed(2)),
        )
      }
    }
  }, [remainData, selectedTicket])

  useEffect(() => {
    setWsLink(info?.data?.wsLink)
  }, [info?.data?.wsLink])

  // console.log({
  //   totalBs,
  //   totalUsd,
  //   remainData,
  //   totalUsdDiscount,
  //   totalBs,
  // })

  return (
    <section className='payment_info_container'>
      {page === 'reserve' ? (
        <>
          <div style={{ marginBottom: '8px' }}>
            <h2 className='regular_title'>Monto de reserva</h2>
            <p className='pay_percentage'>{selectedTrip?.bookingPercentage}%</p>
          </div>

          <div>
            <h2 className='regular_title'>Monto a pagar</h2>
            <p className='pay_price'>Bs. {perTotalBs}</p>
            <p className='pay_ref_price'>Ref {perTotalUsd}</p>
          </div>
        </>
      ) : (
        <>
          <h3 className='regular_title'>Monto a pagar</h3>
          <h4 className='pay_price'>
            Bs.{' '}
            {isReserve
              ? perTotalBs
              : isCompleteBooked
              ? completedBookedBs
              : new Intl.NumberFormat('es-VE', {
                  currency: 'USD',
                }).format(totalBs?.toFixed(2))}
          </h4>

          <p className='pay_ref_price'>
            Ref{' '}
            {isReserve
              ? perTotalUsd
              : isCompleteBooked
              ? completedBookedUsd
              : totalUsd}
          </p>

          <div className='bcv_container'>
            <p className='bcv_title'>Tasa BCV del día</p>
            <div className='tasa_day_container'>
              <p>Bs {dayRate ?? selectedTicket?.dayRate}</p>
            </div>
          </div>

          <div className='pago_movil_title_container'>
            <p className='regular_title'>Por favor realiza el pago móvil a:</p>
          </div>

          {/* <section className='grid_section'>
            <div className='grid_items'>
              <p>Número de teléfono</p>
              <p>Documento</p>
              <p>Banco</p>
            </div>

            <div className='grid_items'>
              <p className='info_pay_text'>
                {info?.data?.phoneNumber}{' '}
                <Copy text={info?.data?.phoneNumber} />
              </p>

              <p className='info_pay_text'>
                {info?.data?.ownerId}{' '}
                <Copy text={info?.data?.ownerId?.slice(2)} />
              </p>

              <p className='info_pay_text' style={{ marginRight: '15px' }}>
                {info?.data?.bankName}
              </p>
            </div>
          </section> */}

          <Box
            component='section'
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
            }}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
                width: '100%',
              }}>
              <p>Número de teléfono</p>
              <p className='info_pay_text'>
                {info?.data?.phoneNumber}{' '}
                <Copy text={info?.data?.phoneNumber} />
              </p>
            </Box>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'start',
                width: '100%',
              }}>
              <p>Documento</p>

              <p className='info_pay_text'>
                {info?.data?.ownerId}{' '}
                <Copy text={info?.data?.ownerId?.slice(2)} />
              </p>
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}>
              <p>Banco</p>
              <p className='info_pay_text' style={{ marginRight: '15px' }}>
                {info?.data?.bankName}
              </p>
            </div>
          </Box>
        </>
      )}
    </section>
  )
}

export const GetPaymentData = ({
  register,
  setValue,
  errors,
  control,
  codeWatch,
}) => {
  return (
    <section className='payment_info_container'>
      <h3 className='title_form'>Ingresa los datos de tu pago móvil</h3>
      <p className='text_form'>
        Con esta información podremos verificar tu pago
      </p>

      <section className='from_section'>
        <BanksSelect
          registerValidation={{ ...register('banks', { required: false }) }}
          setValue={setValue}
        />

        <FormsPhoneNumber
          registerValidation={{
            ...register(`phone`, {
              required: 'Este campo es requerido',
              maxLength: { value: 7, message: 'Deben ser 7 digitos' },
              minLength: { value: 7, message: 'Deben ser 7 digitos' },
              valueAsNumber: true,
            }),
          }}
          registerCode={{
            ...register(`code`, { required: false }),
          }}
          error={errors.phone?.message}
          setValue={setValue}
          codeType='ve'
        />

        <FormsCitizensId
          // registerValidation={{
          //   ...register(`ci`, {
          //     required: 'Este campo es requerido',
          //     maxLength: {
          //       value: codeWatch === 'J' ? 10 : 8,
          //       message: codeWatch === 'J' ? 'Maximo 10' : 'Maximo 8',
          //     },
          //     minLength: {
          //       value: 6,
          //       message: 'Minimo 6',
          //     },
          //   }),
          // }}
          // registerCode={{
          //   ...register(`ciCode`, {
          //     required: 'Este campo es requerido',
          //   }),
          // }}
          error={errors.ci?.message}
          setValue={setValue}
          control={control}
          getValue={codeWatch}
          // getValues={getValues}
        />

        <ValidationCode
          // registerValidation={{
          //   ...register(`refNumber`, {
          //     required: 'Este campo es requerido',
          //     pattern: {
          //       value: /^[1-9]\d*(\d+)?$/i,
          //       message: 'Solo deben ser numeros',
          //     },
          //     minLength: {
          //       value: 4,
          //       message: 'Minimo 4',
          //     },
          //   }),
          // }}
          label='Número de referencia'
          error={errors.refNumber?.message}
          control={control}
        />

        {/* <InputFile /> */}
      </section>
    </section>
  )
}

export const RemiderPaymentCard = () => {
  return (
    <section className='reminder-card_container'>
      <div className='icon-reminder-card_container'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='47'
          height='47'
          viewBox='0 0 47 47'
          fill='none'>
          <circle
            cx='23.5'
            cy='23.5'
            r='23.5'
            fill='#29348F'
            fillOpacity='0.5'
          />
          <circle cx='23.4999' cy='23.4999' r='18.1425' fill='#FFFFFE' />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M23.8647 4.36377C34.4825 4.36377 43.093 12.9742 43.093 23.592C43.093 34.2098 34.4825 42.8203 23.8647 42.8203C13.2469 42.8203 4.63647 34.2098 4.63647 23.592C4.63647 12.9742 13.2469 4.36377 23.8647 4.36377ZM23.8553 32.3981C24.9171 32.3981 25.7782 33.2591 25.7782 34.3209C25.7782 35.3827 24.9171 36.2438 23.8553 36.2438C22.7936 36.2438 21.9325 35.3827 21.9325 34.3209C21.9325 33.2591 22.7936 32.3981 23.8553 32.3981ZM21.914 29.8777V11.7986C21.914 11.2339 22.3787 10.7731 22.9395 10.7731H24.79C25.3508 10.7731 25.8154 11.2378 25.8154 11.7986V29.8777C25.8154 30.4385 25.3547 30.9032 24.79 30.9032H22.9395C22.3748 30.9032 21.914 30.4424 21.914 29.8777Z'
            fill='#29348F'
          />
        </svg>
      </div>

      <div className='text-reminder-card_container'>
        <h2 className='reminder_title'>
          ¡Recuerda hacer una captura <br /> del comprobante del pago!
        </h2>
      </div>
    </section>
  )
}

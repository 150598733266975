import Service from './service'
import './styles.css'

export function TicketServices({ services = [] }) {
  return (
    <section className='ticket-services'>
      {services.map((service, i) => (
        <div key={i}>
          <Service path={service.path} text={service.text} />
          {/* {i + 1 !== services.length ? <div className='line'></div> : null} */}
        </div>
      ))}
    </section>
  )
}

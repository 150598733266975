// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ticket-status {
  /* width: 40px; */
  max-height: 25px;
  border-radius: 5px;
  padding: 0px 8px;

  & p {
    text-align: center;
    font-family: Kufam;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    height: 14px;
  }

  &.active,
  &.paid,
  &.checked_in {
    background: rgba(59, 212, 174, 0.2);
    & p {
      color: #3bd4ae;
    }
  }

  &.booked {
    background: rgba(251, 230, 222, 0.2);
    & p {
      color: #f08b22;
    }
  }

  &.waiting_verification {
    background: rgb(255 198 0 / 20%);
    text-wrap: nowrap;
    & p {
      color: #dab226;
    }
  }

  &.pending {
    background: rgba(246, 238, 210, 0.2);
    & p {
      color: #dab226;
    }
  }

  &.used,
  &.expired,
  &.cancelled,
  &.booked_expired {
    background: rgba(211, 30, 74, 0.2);
    & p {
      color: #d31e4a;
    }
  }

  &.boarded {
    background: #5b629c;
    & p {
      color: #fff;
    }
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Tickets/Status/status.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;;EAEhB;IACE,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,SAAS;IACT,YAAY;EACd;;EAEA;;;IAGE,mCAAmC;IACnC;MACE,cAAc;IAChB;EACF;;EAEA;IACE,oCAAoC;IACpC;MACE,cAAc;IAChB;EACF;;EAEA;IACE,gCAAgC;IAChC,iBAAiB;IACjB;MACE,cAAc;IAChB;EACF;;EAEA;IACE,oCAAoC;IACpC;MACE,cAAc;IAChB;EACF;;EAEA;;;;IAIE,kCAAkC;IAClC;MACE,cAAc;IAChB;EACF;;EAEA;IACE,mBAAmB;IACnB;MACE,WAAW;IACb;EACF;AACF","sourcesContent":[".ticket-status {\n  /* width: 40px; */\n  max-height: 25px;\n  border-radius: 5px;\n  padding: 0px 8px;\n\n  & p {\n    text-align: center;\n    font-family: Kufam;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 600;\n    line-height: normal;\n    margin: 0;\n    height: 14px;\n  }\n\n  &.active,\n  &.paid,\n  &.checked_in {\n    background: rgba(59, 212, 174, 0.2);\n    & p {\n      color: #3bd4ae;\n    }\n  }\n\n  &.booked {\n    background: rgba(251, 230, 222, 0.2);\n    & p {\n      color: #f08b22;\n    }\n  }\n\n  &.waiting_verification {\n    background: rgb(255 198 0 / 20%);\n    text-wrap: nowrap;\n    & p {\n      color: #dab226;\n    }\n  }\n\n  &.pending {\n    background: rgba(246, 238, 210, 0.2);\n    & p {\n      color: #dab226;\n    }\n  }\n\n  &.used,\n  &.expired,\n  &.cancelled,\n  &.booked_expired {\n    background: rgba(211, 30, 74, 0.2);\n    & p {\n      color: #d31e4a;\n    }\n  }\n\n  &.boarded {\n    background: #5b629c;\n    & p {\n      color: #fff;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

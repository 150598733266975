import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Alert } from '../bottom-panel/Alert'

import { useGetAppVersion } from '../../hooks/useSettingsApi'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  useMediaQuery,
} from '@mui/material'
import { ArrowForwardIcon } from '../icons/ArrowForward'
import { ButtonBlue } from '../buttons'
import { ArrowBack } from '../icons/ArrowBack'

import axios from 'axios'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { busiAPi } from '../../utils/urls'
import { usePersistStore } from '../../store'
import { useWantToLogin } from '../../hooks/useWantLogin'

import './styles.settings.css'
import './ProfileDetails/styles.profile.css'

export function SidebarMenu() {
  const navigate = useNavigate()
  const minW800 = useMediaQuery('(min-width:800px)')

  const { data: version, isLoading } = useGetAppVersion()
  const { userData } = usePersistStore()

  const [showAlert, setSetShow] = useState(false)
  // const [show, setShow] = useState(false)
  const [date, setDate] = useState(undefined)
  const [isDelete, setIsDelete] = useState(false)
  const { wantToLogin } = useWantToLogin()
  // const isLogin = localStorage.getItem('isLogin')

  useEffect(() => {
    dayjs.extend(customParseFormat)
    const date = dayjs(userData.bornDate, 'YYYY-MM-DD').format('DD/MM/YYYY')
    setDate(date)
  }, [userData.bornDate])

  function logOut() {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('busi_passenger_user')
    navigate('/login', { state: { previousPath: '/main/profile' } })
  }

  const wantLogin = () => {
    // setWantToLogin(true)
    navigate('/login', { state: { previousPath: '/main/profile' } })
  }

  if (minW800) {
    return (
      <>
        {!isDelete ? (
          <Box
            component='section'
            className='pages-menu'
            style={{ height: 'calc(100% - 134px)', minHeight: '600px' }}>
            {/* <div className='pages-menu-top'></div> */}
            <Box
              style={{
                height: '100%',
                minHeight: '600px',

                background: 'transparent',
                padding: '16px',
                // position: 'relative',

                minWidth: '320px',
                // overflowY: 'scroll',
              }}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '20px',
                }}>
                {wantToLogin && (
                  <Card
                    style={{
                      borderRadius: '12px',
                      minHeight: '231px',
                      width: '100%',
                      maxWidth: '500px',
                    }}>
                    <CardActionArea>
                      <CardContent>
                        <h3 style={{ fontSize: '16px' }}>Mis datos</h3>
                        <Divider
                          style={{ borderColor: '#29348f', margin: '5px 0px' }}
                        />

                        <Box
                          style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gap: '8px',
                          }}>
                          <Box>
                            <p className='profile-personal_title'>Nombre</p>
                            <p className='profile-personal_subtitle'>
                              {userData.name}
                            </p>
                          </Box>

                          <Box>
                            <p className='profile-personal_title'>Apellido</p>
                            <p className='profile-personal_subtitle'>
                              {userData.lastName}
                            </p>
                          </Box>

                          <Box>
                            <p className='profile-personal_title'>
                              Cédula de identidad
                            </p>
                            <p className='profile-personal_subtitle'>
                              {userData.ci}
                            </p>
                          </Box>

                          <Box>
                            <p className='profile-personal_title'>
                              Fecha de nacimiento
                            </p>
                            <p className='profile-personal_subtitle'>{date}</p>
                          </Box>
                        </Box>

                        <Box marginTop={1}>
                          <p className='profile-personal_title'>
                            Correo electrónico
                          </p>
                          <p className='profile-personal_subtitle'>
                            {userData.email}
                          </p>
                        </Box>

                        <section
                          className='info-profile-card_container'
                          style={{ marginTop: '8px' }}>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='20'
                            height='20'
                            viewBox='0 0 20 20'
                            fill='none'>
                            <path
                              d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 15C9.45 15 9 14.55 9 14V10C9 9.45 9.45 9 10 9C10.55 9 11 9.45 11 10V14C11 14.55 10.55 15 10 15ZM11 7H9V5H11V7Z'
                              fill='#189AD6'
                            />
                          </svg>

                          <h2 className='info-profile_title'>
                            Para actualizar tus datos debes contactarnos
                          </h2>
                        </section>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                )}

                {!wantToLogin && (
                  <Card
                    style={{
                      borderRadius: '12px',
                      // minHeight: '188px',
                      maxWidth: '500px',
                      width: '100%',
                    }}>
                    <CardActionArea>
                      <CardContent>
                        <h3 style={{ fontSize: '16px' }}>
                          Disfruta de mas creando una cuenta
                        </h3>

                        <Divider
                          style={{ borderColor: '#29348f', marginTop: '5px' }}
                        />
                        <Box
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '15px',
                            flexDirection: 'column',
                            padding: '10px 0px',
                          }}>
                          <p>
                            Accede a tu cuenta o create un usuario para poder
                            continuar con el proceso de compra de boletos
                          </p>
                          <ButtonBlue onClick={() => wantLogin()}>
                            Login
                          </ButtonBlue>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                )}

                <Card
                  style={{
                    borderRadius: '12px',
                    // minHeight: '188px',
                    maxWidth: '500px',
                    width: '100%',
                  }}>
                  <CardActionArea>
                    <CardContent>
                      <h3 style={{ paddingLeft: '18px', fontSize: '16px' }}>
                        Información
                      </h3>

                      <Divider
                        style={{ borderColor: '#29348f', marginTop: '5px' }}
                      />

                      <ul className='menu-list_container'>
                        <li>
                          <a
                            className='menu-item-container'
                            href='https://www.busitransporte.com/about-7'
                            target='_blank'
                            rel='noreferrer'>
                            <div className='menu-item-icon'>
                              <img src='/assets/terms.svg' alt='terms' />
                            </div>

                            <p className='menu-item-prompt'>
                              Términos y condiciones
                            </p>

                            <div className='menu-item-action'>
                              <img src='/assets/arrow.svg' alt='arrow' />
                            </div>
                          </a>
                        </li>

                        <li>
                          <a
                            className='menu-item-container'
                            href='https://api.whatsapp.com/send?phone=584242229359'
                            target='_blank'
                            rel='noreferrer'>
                            <div className='menu-item-icon'>
                              <img src='/assets/phone.svg' alt='phone' />
                            </div>

                            <p className='menu-item-prompt'>Contáctenos</p>
                            <div className='menu-item-action'>
                              <img src='/assets/arrow.svg' alt='arrow' />
                            </div>
                          </a>
                        </li>

                        {wantToLogin && (
                          <li
                            className='menu-item-container'
                            style={{ borderBottom: 'none', height: '40px' }}
                            onClick={() => setIsDelete(true)}>
                            <div className='menu-item-icon'>
                              <svg
                                xmlns='http://www.w3.org/2000/svg'
                                width='12'
                                height='12'
                                viewBox='0 0 12 12'
                                fill='none'>
                                <path
                                  d='M6 0C2.69133 0 0 2.692 0 6C0 9.308 2.69133 12 6 12C9.30867 12 12 9.308 12 6C12 2.692 9.30867 0 6 0ZM6 10.6667C3.42733 10.6667 1.33333 8.57333 1.33333 6C1.33333 3.42667 3.42733 1.33333 6 1.33333C8.57267 1.33333 10.6667 3.42667 10.6667 6C10.6667 8.57333 8.57267 10.6667 6 10.6667ZM6.47133 6L8.23533 4.236C8.2975 4.17332 8.33238 4.08861 8.33238 4.00033C8.33238 3.91205 8.2975 3.82735 8.23533 3.76467C8.17265 3.7025 8.08795 3.66762 7.99967 3.66762C7.91139 3.66762 7.82668 3.7025 7.764 3.76467L6 5.52867L4.236 3.764C4.17313 3.70328 4.08893 3.66968 4.00153 3.67044C3.91413 3.6712 3.83053 3.70626 3.76873 3.76806C3.70692 3.82986 3.67187 3.91347 3.67111 4.00087C3.67035 4.08827 3.70395 4.17247 3.76467 4.23533L5.52867 6L3.76467 7.764C3.70208 7.8265 3.66688 7.91131 3.66681 7.99976C3.66675 8.08822 3.70183 8.17308 3.76433 8.23567C3.82684 8.29826 3.91164 8.33346 4.0001 8.33352C4.08855 8.33358 4.17341 8.2985 4.236 8.236L6 6.47133L7.764 8.23533C7.7947 8.26721 7.83145 8.29265 7.87209 8.31018C7.91274 8.3277 7.95646 8.33696 8.00072 8.33741C8.04498 8.33785 8.08888 8.32948 8.12987 8.31278C8.17085 8.29607 8.20811 8.27138 8.23945 8.24012C8.27079 8.20887 8.29559 8.17169 8.31241 8.13075C8.32923 8.08981 8.33772 8.04593 8.3374 8.00167C8.33708 7.95741 8.32795 7.91366 8.31054 7.87297C8.29313 7.83228 8.26779 7.79546 8.236 7.76467L6.47133 6Z'
                                  fill='#29348F'
                                />
                              </svg>
                            </div>

                            <p className='menu-item-prompt'>Eliminar cuenta</p>

                            <div className='menu-item-action'>
                              <img src='/assets/arrow.svg' alt='arrow' />
                            </div>
                          </li>
                        )}
                      </ul>
                    </CardContent>
                  </CardActionArea>
                </Card>

                {wantToLogin && (
                  <Card
                    style={{
                      borderRadius: '12px',
                      minHeight: '55px',
                      maxWidth: '500px',
                      width: '100%',
                    }}
                    onClick={() => setSetShow(true)}>
                    <CardActionArea>
                      <CardContent>
                        <Box
                          component='section'
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            gap: '10px',
                          }}>
                          <Box display='flex' alignItems='center' gap='10px'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='12'
                              height='12'
                              viewBox='0 0 12 12'
                              fill='none'>
                              <path
                                d='M7.50001 1.21338V2.26338C8.50083 2.61722 9.34434 3.31348 9.88146 4.2291C10.4186 5.14472 10.6147 6.22074 10.4352 7.26698C10.2557 8.31322 9.71211 9.26232 8.9005 9.94654C8.0889 10.6307 7.06154 11.006 6.00001 11.006C4.93848 11.006 3.91113 10.6307 3.09952 9.94654C2.28792 9.26232 1.74433 8.31322 1.56482 7.26698C1.38531 6.22074 1.58145 5.14472 2.11857 4.2291C2.65568 3.31348 3.49919 2.61722 4.50001 2.26338V1.21338C3.22282 1.57543 2.11968 2.3872 1.39414 3.49891C0.668594 4.61061 0.369622 5.94722 0.552387 7.26209C0.735152 8.57697 1.38732 9.78137 2.38855 10.6531C3.38978 11.5247 4.6725 12.0049 6.00001 12.0049C7.32753 12.0049 8.61025 11.5247 9.61148 10.6531C10.6127 9.78137 11.2649 8.57697 11.4476 7.26209C11.6304 5.94722 11.3314 4.61061 10.6059 3.49891C9.88035 2.3872 8.7772 1.57543 7.50001 1.21338Z'
                                fill='#E7194F'
                              />
                              <path
                                d='M6.50001 0H5.5V4H6.50001V0Z'
                                fill='#E7194F'
                              />
                            </svg>

                            <p className='pages-menu-middle-bottom-prompt'>
                              Cerrar sesión
                            </p>
                          </Box>

                          <div className='menu-item-action'>
                            <ArrowForwardIcon
                              width={20}
                              height={20}
                              fill='#e7194f'
                            />
                          </div>
                        </Box>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                )}

                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '5px',
                  }}>
                  <p style={{ margin: 0, fontSize: '10px', color: '#898989' }}>
                    version: {isLoading ? '2.1.0' : version?.data?.value}
                  </p>
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <DeletePage isDelete={isDelete} setIsDelete={setIsDelete} />
        )}

        <Alert
          type='signout'
          isShow={showAlert}
          setShow={() => setSetShow(false)}
          buttonText='Cerrar sesión'
          title={
            <>
              ¿Estás seguro que deseas <br /> cerrar la sesión?
            </>
          }
          onClickButton={logOut}
        />
      </>
    )
  }

  return (
    <>
      {!isDelete ? (
        <Box component='section' className='pages-menu'>
          {/* <div className='pages-menu-top'></div> */}
          <Box
            style={{
              padding: '16px',
              position: 'relative',
              bottom: '18px',
              borderRadius: '20px 20px 0px 0px',
              backgroundColor: '#f0eeee',
              minWidth: '320px',
              overflowY: 'scroll',
            }}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '20px',
              }}>
              {wantToLogin && (
                <Card
                  style={{
                    borderRadius: '12px',
                    minHeight: '231px',
                    width: '100%',
                    maxWidth: '500px',
                  }}>
                  <CardActionArea>
                    <CardContent>
                      <h3 style={{ fontSize: '16px' }}>Mis datos</h3>
                      <Divider
                        style={{ borderColor: '#29348f', margin: '5px 0px' }}
                      />

                      <Box
                        style={{
                          display: 'grid',
                          gridTemplateColumns: '1fr 1fr',
                          gap: '8px',
                        }}>
                        <Box>
                          <p className='profile-personal_title'>Nombre</p>
                          <p className='profile-personal_subtitle'>
                            {userData.name}
                          </p>
                        </Box>

                        <Box>
                          <p className='profile-personal_title'>Apellido</p>
                          <p className='profile-personal_subtitle'>
                            {userData.lastName}
                          </p>
                        </Box>

                        <Box>
                          <p className='profile-personal_title'>
                            Cédula de identidad
                          </p>
                          <p className='profile-personal_subtitle'>
                            {userData.ci}
                          </p>
                        </Box>

                        <Box>
                          <p className='profile-personal_title'>
                            Fecha de nacimiento
                          </p>
                          <p className='profile-personal_subtitle'>{date}</p>
                        </Box>
                      </Box>

                      <Box marginTop={1}>
                        <p className='profile-personal_title'>
                          Correo electrónico
                        </p>
                        <p className='profile-personal_subtitle'>
                          {userData.email}
                        </p>
                      </Box>

                      <section
                        className='info-profile-card_container'
                        style={{ marginTop: '8px' }}>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='20'
                          height='20'
                          viewBox='0 0 20 20'
                          fill='none'>
                          <path
                            d='M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 15C9.45 15 9 14.55 9 14V10C9 9.45 9.45 9 10 9C10.55 9 11 9.45 11 10V14C11 14.55 10.55 15 10 15ZM11 7H9V5H11V7Z'
                            fill='#189AD6'
                          />
                        </svg>

                        <h2 className='info-profile_title'>
                          Para actualizar tus datos debes contactarnos
                        </h2>
                      </section>
                    </CardContent>
                  </CardActionArea>
                </Card>
              )}

              {!wantToLogin && (
                <Card
                  style={{
                    borderRadius: '12px',
                    // minHeight: '188px',
                    maxWidth: '500px',
                    width: '100%',
                  }}>
                  <CardActionArea>
                    <CardContent>
                      <h3 style={{ fontSize: '16px' }}>
                        Disfruta de mas creando una cuenta
                      </h3>

                      <Divider
                        style={{ borderColor: '#29348f', marginTop: '5px' }}
                      />
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: '15px',
                          flexDirection: 'column',
                          padding: '10px 0px',
                        }}>
                        <p>
                          Accede a tu cuenta o create un usuario para poder
                          continuar con el proceso de compra de boletos
                        </p>
                        <ButtonBlue onClick={() => wantLogin()}>
                          Login
                        </ButtonBlue>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              )}

              <Card
                style={{
                  borderRadius: '12px',
                  // minHeight: '188px',
                  maxWidth: '500px',
                  width: '100%',
                }}>
                <CardActionArea>
                  <CardContent>
                    <h3 style={{ paddingLeft: '18px', fontSize: '16px' }}>
                      Información
                    </h3>

                    <Divider
                      style={{ borderColor: '#29348f', marginTop: '5px' }}
                    />

                    <ul className='menu-list_container'>
                      <li>
                        <a
                          className='menu-item-container'
                          href='https://www.busitransporte.com/about-7'
                          target='_blank'
                          rel='noreferrer'>
                          <div className='menu-item-icon'>
                            <img src='/assets/terms.svg' alt='terms' />
                          </div>

                          <p className='menu-item-prompt'>
                            Términos y condiciones
                          </p>

                          <div className='menu-item-action'>
                            <img src='/assets/arrow.svg' alt='arrow' />
                          </div>
                        </a>
                      </li>

                      <li>
                        <a
                          className='menu-item-container'
                          href='https://api.whatsapp.com/send?phone=584242229359'
                          target='_blank'
                          rel='noreferrer'>
                          <div className='menu-item-icon'>
                            <img src='/assets/phone.svg' alt='phone' />
                          </div>

                          <p className='menu-item-prompt'>Contáctenos</p>
                          <div className='menu-item-action'>
                            <img src='/assets/arrow.svg' alt='arrow' />
                          </div>
                        </a>
                      </li>

                      {wantToLogin && (
                        <li
                          className='menu-item-container'
                          style={{ borderBottom: 'none', height: '40px' }}
                          onClick={() => setIsDelete(true)}>
                          <div className='menu-item-icon'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='12'
                              height='12'
                              viewBox='0 0 12 12'
                              fill='none'>
                              <path
                                d='M6 0C2.69133 0 0 2.692 0 6C0 9.308 2.69133 12 6 12C9.30867 12 12 9.308 12 6C12 2.692 9.30867 0 6 0ZM6 10.6667C3.42733 10.6667 1.33333 8.57333 1.33333 6C1.33333 3.42667 3.42733 1.33333 6 1.33333C8.57267 1.33333 10.6667 3.42667 10.6667 6C10.6667 8.57333 8.57267 10.6667 6 10.6667ZM6.47133 6L8.23533 4.236C8.2975 4.17332 8.33238 4.08861 8.33238 4.00033C8.33238 3.91205 8.2975 3.82735 8.23533 3.76467C8.17265 3.7025 8.08795 3.66762 7.99967 3.66762C7.91139 3.66762 7.82668 3.7025 7.764 3.76467L6 5.52867L4.236 3.764C4.17313 3.70328 4.08893 3.66968 4.00153 3.67044C3.91413 3.6712 3.83053 3.70626 3.76873 3.76806C3.70692 3.82986 3.67187 3.91347 3.67111 4.00087C3.67035 4.08827 3.70395 4.17247 3.76467 4.23533L5.52867 6L3.76467 7.764C3.70208 7.8265 3.66688 7.91131 3.66681 7.99976C3.66675 8.08822 3.70183 8.17308 3.76433 8.23567C3.82684 8.29826 3.91164 8.33346 4.0001 8.33352C4.08855 8.33358 4.17341 8.2985 4.236 8.236L6 6.47133L7.764 8.23533C7.7947 8.26721 7.83145 8.29265 7.87209 8.31018C7.91274 8.3277 7.95646 8.33696 8.00072 8.33741C8.04498 8.33785 8.08888 8.32948 8.12987 8.31278C8.17085 8.29607 8.20811 8.27138 8.23945 8.24012C8.27079 8.20887 8.29559 8.17169 8.31241 8.13075C8.32923 8.08981 8.33772 8.04593 8.3374 8.00167C8.33708 7.95741 8.32795 7.91366 8.31054 7.87297C8.29313 7.83228 8.26779 7.79546 8.236 7.76467L6.47133 6Z'
                                fill='#29348F'
                              />
                            </svg>
                          </div>

                          <p className='menu-item-prompt'>Eliminar cuenta</p>

                          <div className='menu-item-action'>
                            <img src='/assets/arrow.svg' alt='arrow' />
                          </div>
                        </li>
                      )}
                    </ul>
                  </CardContent>
                </CardActionArea>
              </Card>

              {wantToLogin && (
                <Card
                  style={{
                    borderRadius: '12px',
                    minHeight: '55px',
                    maxWidth: '500px',
                    width: '100%',
                  }}
                  onClick={() => setSetShow(true)}>
                  <CardActionArea>
                    <CardContent>
                      <Box
                        component='section'
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          gap: '10px',
                        }}>
                        <Box display='flex' alignItems='center' gap='10px'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='12'
                            height='12'
                            viewBox='0 0 12 12'
                            fill='none'>
                            <path
                              d='M7.50001 1.21338V2.26338C8.50083 2.61722 9.34434 3.31348 9.88146 4.2291C10.4186 5.14472 10.6147 6.22074 10.4352 7.26698C10.2557 8.31322 9.71211 9.26232 8.9005 9.94654C8.0889 10.6307 7.06154 11.006 6.00001 11.006C4.93848 11.006 3.91113 10.6307 3.09952 9.94654C2.28792 9.26232 1.74433 8.31322 1.56482 7.26698C1.38531 6.22074 1.58145 5.14472 2.11857 4.2291C2.65568 3.31348 3.49919 2.61722 4.50001 2.26338V1.21338C3.22282 1.57543 2.11968 2.3872 1.39414 3.49891C0.668594 4.61061 0.369622 5.94722 0.552387 7.26209C0.735152 8.57697 1.38732 9.78137 2.38855 10.6531C3.38978 11.5247 4.6725 12.0049 6.00001 12.0049C7.32753 12.0049 8.61025 11.5247 9.61148 10.6531C10.6127 9.78137 11.2649 8.57697 11.4476 7.26209C11.6304 5.94722 11.3314 4.61061 10.6059 3.49891C9.88035 2.3872 8.7772 1.57543 7.50001 1.21338Z'
                              fill='#E7194F'
                            />
                            <path
                              d='M6.50001 0H5.5V4H6.50001V0Z'
                              fill='#E7194F'
                            />
                          </svg>

                          <p className='pages-menu-middle-bottom-prompt'>
                            Cerrar sesión
                          </p>
                        </Box>

                        <div className='menu-item-action'>
                          <ArrowForwardIcon
                            width={20}
                            height={20}
                            fill='#e7194f'
                          />
                        </div>
                      </Box>
                    </CardContent>
                  </CardActionArea>
                </Card>
              )}

              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: '5px',
                }}>
                <p style={{ margin: 0, fontSize: '10px', color: '#898989' }}>
                  version: {isLoading ? '2.1.0' : version?.data?.value}
                </p>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <DeletePage isDelete={isDelete} setIsDelete={setIsDelete} />
      )}

      <Alert
        type='signout'
        isShow={showAlert}
        setShow={() => setSetShow(false)}
        buttonText='Cerrar sesión'
        title={
          <>
            ¿Estás seguro que deseas <br /> cerrar la sesión?
          </>
        }
        onClickButton={logOut}
      />
    </>
  )
}

// #region DELETE PAGE
const DeletePage = ({ isDelete, setIsDelete }) => {
  const navigate = useNavigate()

  const handleDelete = async () => {
    try {
      const { data: info } = await axios.put(
        `${busiAPi}/passenger/delete`,
        { data: {} },
        { headers: { Authorization: localStorage.getItem('token') } },
      )

      if (info.success) {
        localStorage.removeItem('token')
        localStorage.removeItem('refreshToken')
        localStorage.removeItem('busi_passenger_user')
        navigate('/login')
      }
    } catch (error) {
      console.log({ error })
    }
  }

  function back() {
    if (isDelete) {
      setIsDelete(false)
    } else {
      // setShow(false)
    }
  }

  return (
    <section className='pages-profile_delete'>
      <ArrowBack onClick={back} />

      <h2 className='title'>
        Esta acción <br /> <span>eliminará</span> toda tu <br /> información de
        Busi
      </h2>

      <ul>
        <li>
          Esta decisión no es reversible. Podrás volver a registrarte cuando
          desees.
        </li>
        <li>
          Perderás todo tu historial como pasajero, y si vuelves a registrarte
          no podrás recuperarlo.
        </li>
        <li>No podrás volver a registrarte con el mismo correo electrónico.</li>
      </ul>

      <section className='flex-center'>
        <div className='delete-button_container'>
          <ButtonBlue design='red' onClick={handleDelete}>
            <div className='delete-button_info_container'>
              <svg
                width='8'
                height='10'
                viewBox='0 0 8 10'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M6 1.16667V2H7.5C7.63261 2 7.75979 2.05268 7.85355 2.14645C7.94732 2.24021 8 2.36739 8 2.5C8 2.63261 7.94732 2.75979 7.85355 2.85355C7.75979 2.94732 7.63261 3 7.5 3H0.5C0.367392 3 0.240215 2.94732 0.146447 2.85355C0.0526784 2.75979 0 2.63261 0 2.5C0 2.36739 0.0526784 2.24021 0.146447 2.14645C0.240215 2.05268 0.367392 2 0.5 2H2V1.16667C2 0.522667 2.52267 0 3.16667 0H4.83333C5.47733 0 6 0.522667 6 1.16667ZM1.664 4.45L2.104 8.85C2.10813 8.89114 2.12741 8.92927 2.15809 8.95699C2.18877 8.98471 2.22865 9.00004 2.27 9H5.73C5.77135 9.00004 5.81123 8.98471 5.84191 8.95699C5.87259 8.92927 5.89187 8.89114 5.896 8.85L6.336 4.45C6.35253 4.32085 6.41879 4.20324 6.52069 4.12219C6.62258 4.04113 6.75208 4.00302 6.88164 4.01596C7.0112 4.0289 7.1306 4.09186 7.21446 4.19147C7.29831 4.29108 7.34 4.41946 7.33067 4.54933L6.89067 8.94933C6.86219 9.23731 6.72759 9.5044 6.51305 9.6986C6.29851 9.89281 6.01938 10.0002 5.73 10H2.27C1.98081 9.99996 1.70193 9.89252 1.48747 9.6985C1.27301 9.50449 1.13825 9.23774 1.10933 8.95L0.669333 4.55C0.660984 4.4838 0.665969 4.4166 0.683994 4.35236C0.70202 4.28811 0.732722 4.22813 0.77429 4.17593C0.815859 4.12374 0.867454 4.0804 0.926035 4.04845C0.984616 4.01651 1.049 3.99661 1.11539 3.98994C1.18178 3.98326 1.24883 3.98995 1.3126 4.00959C1.37636 4.02923 1.43556 4.06144 1.48668 4.10431C1.53781 4.14719 1.57984 4.19986 1.61029 4.25923C1.64074 4.3186 1.659 4.38346 1.664 4.45ZM3 1.16667V2H5V1.16667C5 1.12246 4.98244 1.08007 4.95118 1.04882C4.91993 1.01756 4.87754 1 4.83333 1H3.16667C3.12246 1 3.08007 1.01756 3.04882 1.04882C3.01756 1.08007 3 1.12246 3 1.16667Z'
                  fill='white'
                />
              </svg>

              <p>Eliminar mi cuenta</p>
            </div>
          </ButtonBlue>
        </div>
      </section>
    </section>
  )
}

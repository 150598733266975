import { useState, useEffect } from 'react'
import { Radio } from '../../ui/Radio'
import './styles.css'
// eslint-disable-next-line import/no-anonymous-default-export
export default function ({ registerValidation = {}, setValue, error }) {
  return (
    <>
      <section className='gender_radio_container'>
        <Radio
          registerValidation={registerValidation}
          value='F'
          labelLeft='Femenino'
          id='woman'
        />

        <Radio
          registerValidation={registerValidation}
          value='M'
          labelLeft='Masculino'
          id='man'
        />
      </section>

      {error && <p className='error_message'>{error}</p>}
    </>
  )
}

export const GenderRadios = ({ registerValidation = {}, setValue, error }) => {
  const [isCheck, setCheck] = useState(undefined)

  useEffect(() => {
    setValue('gender', isCheck)
  }, [isCheck])

  return (
    <>
      <section className='gender_radio_container'>
        <Radio
          labelLeft='Femenino'
          registerValidation={registerValidation}
          value='F'
          id='woman'
          setValue={setCheck}
        />

        <Radio
          labelLeft='Masculino'
          registerValidation={registerValidation}
          value='M'
          id='man'
          setValue={setCheck}
        />
      </section>

      {error && <p className='error_message'>{error}</p>}
    </>
  )
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.92.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* input[type='password']:not(.browser-default) {
  background-color: transparent;
  border: none;
  border-bottom: none !important;
  border-radius: 0;
  outline: none;
  height: 1.4375em !important;
  width: 100%;
  font-size: 16px;
  margin: 0 !important;
  
  box-shadow: none;
  box-sizing: content-box;
  transition: none !important;
} */

input[type='password']::-ms-reveal,
input[type='password']::-ms-clear {
  display: none;
}

.input-field .prefix {
  right: 0;
  top: 1.3rem;
}

.error_message {
  color: #d31e4a;
  font-family: Kufam;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  /* margin: 0; */
  margin-bottom: 0;
}

/* .input-field {
  margin: 0;
  .input_password {
    border-bottom: 1px solid #29348f !important;
    color: #29348f;
    font-family: Kufam;
    margin: 0 !important;
  }
} */
`, "",{"version":3,"sources":["webpack://./src/components/imputs/forms-password/styles.password.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;;GAcG;;AAEH;;EAEE,aAAa;AACf;;AAEA;EACE,QAAQ;EACR,WAAW;AACb;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAEA;;;;;;;;GAQG","sourcesContent":["/* input[type='password']:not(.browser-default) {\n  background-color: transparent;\n  border: none;\n  border-bottom: none !important;\n  border-radius: 0;\n  outline: none;\n  height: 1.4375em !important;\n  width: 100%;\n  font-size: 16px;\n  margin: 0 !important;\n  \n  box-shadow: none;\n  box-sizing: content-box;\n  transition: none !important;\n} */\n\ninput[type='password']::-ms-reveal,\ninput[type='password']::-ms-clear {\n  display: none;\n}\n\n.input-field .prefix {\n  right: 0;\n  top: 1.3rem;\n}\n\n.error_message {\n  color: #d31e4a;\n  font-family: Kufam;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  /* margin: 0; */\n  margin-bottom: 0;\n}\n\n/* .input-field {\n  margin: 0;\n  .input_password {\n    border-bottom: 1px solid #29348f !important;\n    color: #29348f;\n    font-family: Kufam;\n    margin: 0 !important;\n  }\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

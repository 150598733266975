// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* NEW */
.pages-search-main {
  position: relative;
  bottom: 18px;
  border-radius: 20px 20px 0px 0px;
  background-color: #f0eeee;
  min-width: 320px;
  height: 100%;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.25);
}

.pages-tickets-main {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Search/styles.search.css"],"names":[],"mappings":"AAAA,QAAQ;AACR;EACE,kBAAkB;EAClB,YAAY;EACZ,gCAAgC;EAChC,yBAAyB;EACzB,gBAAgB;EAChB,YAAY;EACZ,gDAAgD;AAClD;;AAEA;EACE,WAAW;AACb","sourcesContent":["/* NEW */\n.pages-search-main {\n  position: relative;\n  bottom: 18px;\n  border-radius: 20px 20px 0px 0px;\n  background-color: #f0eeee;\n  min-width: 320px;\n  height: 100%;\n  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.25);\n}\n\n.pages-tickets-main {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

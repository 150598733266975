// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input:not([type]):not(.browser-default):not([readonly]),
input[type='email']:not(.browser-default):not([readonly]) {
  color: #29348f;
  border-bottom: 1px solid #29348f;
  font-family: Kufam;
  margin: 0;
}

input:not([type]):not(.browser-default):focus:not([readonly]),
input[type='email']:not(.browser-default):focus:not([readonly]) {
  border-bottom: 1px solid #29348f;
  box-shadow: 0 1px 0 0 #29348f;
}

input:not([type]):not(.browser-default):focus:not([readonly]) + label,
input[type='email']:not(.browser-default):focus:not([readonly]) + label {
  color: #898989;
  font-family: Kufam;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
}

.input-field .prefix {
  right: 0;
}

.input-field {
  .validation-number_imput {
    margin: 0 !important;
    color: #29348f;
  }
  label {
    color: #29348f !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/imputs/forms-validation-code/styles.validation.css"],"names":[],"mappings":"AAAA;;EAEE,cAAc;EACd,gCAAgC;EAChC,kBAAkB;EAClB,SAAS;AACX;;AAEA;;EAEE,gCAAgC;EAChC,6BAA6B;AAC/B;;AAEA;;EAEE,cAAc;EACd,kBAAkB;EAClB,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB,EAAE,SAAS;AAC9B;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE;IACE,oBAAoB;IACpB,cAAc;EAChB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":["input:not([type]):not(.browser-default):not([readonly]),\ninput[type='email']:not(.browser-default):not([readonly]) {\n  color: #29348f;\n  border-bottom: 1px solid #29348f;\n  font-family: Kufam;\n  margin: 0;\n}\n\ninput:not([type]):not(.browser-default):focus:not([readonly]),\ninput[type='email']:not(.browser-default):focus:not([readonly]) {\n  border-bottom: 1px solid #29348f;\n  box-shadow: 0 1px 0 0 #29348f;\n}\n\ninput:not([type]):not(.browser-default):focus:not([readonly]) + label,\ninput[type='email']:not(.browser-default):focus:not([readonly]) + label {\n  color: #898989;\n  font-family: Kufam;\n  font-size: 12px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: 24px; /* 200% */\n}\n\n.input-field .prefix {\n  right: 0;\n}\n\n.input-field {\n  .validation-number_imput {\n    margin: 0 !important;\n    color: #29348f;\n  }\n  label {\n    color: #29348f !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

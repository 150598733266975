// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divider-dash_search {
  border: none;
  border-top: 2px dashed #29348f;
  /* background-color: var(--color-white); */
  height: 1px;
  width: 100%;
  margin-top: 10px;
}
.divider-vertical_dash_search {
  border: none;
  border-right: 2px dashed #29348f;
  height: 69px;
  width: 2px;
  margin: 0px;
}

.parragraph_legend {
  text-align: center;
  font-size: 14px;
}

@media (max-width: 390px) {
  .parragraph_legend {
    font-size: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/ChooseSeat/style.seat.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,8BAA8B;EAC9B,0CAA0C;EAC1C,WAAW;EACX,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,YAAY;EACZ,gCAAgC;EAChC,YAAY;EACZ,UAAU;EACV,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[".divider-dash_search {\n  border: none;\n  border-top: 2px dashed #29348f;\n  /* background-color: var(--color-white); */\n  height: 1px;\n  width: 100%;\n  margin-top: 10px;\n}\n.divider-vertical_dash_search {\n  border: none;\n  border-right: 2px dashed #29348f;\n  height: 69px;\n  width: 2px;\n  margin: 0px;\n}\n\n.parragraph_legend {\n  text-align: center;\n  font-size: 14px;\n}\n\n@media (max-width: 390px) {\n  .parragraph_legend {\n    font-size: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// import { useState } from 'react'

import './styles.radio.css'

export const Radio = ({
  labelRight,
  labelLeft,
  id = 'radio',
  value,
  registerValidation,
  setValue,
}) => {
  return (
    <div className='radio_container' onClick={() => setValue(value)}>
      {labelLeft && <label htmlFor={id}>{labelLeft}</label>}

      <input {...registerValidation} type='radio' value={value} id={id} />

      {labelRight && <label htmlFor={id}>{labelRight}</label>}
    </div>
  )
}

import React from 'react'
import { InfoMarker } from '../../icons/InfoMarker'
import './info.css'

/**
 *
 * @param {object} props
 * @param {date} props.date - props to show ticket date.
 * @param {string} props.startTime - props to url to the image.
 * @param {string} props.endTime - props to url to the image.
 * @param {object} props.startLocation - props to url to the image.
 * @param {string} props.startLocation.short - props to url to the image.
 * @param {string} props.startLocation.long - props to url to the image.
 * @param {object} props.endLocation - props to url to the image.
 * @param {string} props.endLocation.short - props to url to the image.
 * @param {string} props.endLocation.long - props to url to the image.
 * @param {boolean} props.isStatusBorded - props to url to the image.
 * @param {boolean} props.isStatusExpired - props to url to the image.
 * @returns {React.JSX.Element}
 */
export const Info = ({
  date = '',
  startTime = '',
  endTime = '',
  startLocation = { short: '', long: '' },
  endLocation = { short: '', long: '' },
  isStatusBorded = false,
  isStatusExpired = false,
}) => {
  const colorsClass = isStatusBorded ? 'boarded' : isStatusExpired ? 'used' : ''

  return (
    <section className='info-container'>
      {isStatusBorded ? null : (
        <p className={`info-date ${colorsClass}`}>{date}</p>
      )}

      <div className='info-content'>
        {endTime ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '8px',
            }}
            className={`info-time ${colorsClass}`}>
            <p>{startTime}</p>
            <p>{endTime}</p>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              gap: '8px',
            }}
            className={`info-time ${colorsClass}`}>
            <InfoMarker
              color={
                isStatusExpired
                  ? '#6c6c6c'
                  : isStatusBorded
                  ? 'none'
                  : '#29348F'
              }
            />

            <p
              style={{
                color: isStatusExpired
                  ? '#6c6c6c'
                  : isStatusBorded
                  ? '#fff'
                  : '#29348F',
                fontSize: '10px',
              }}>
              {startTime}
            </p>

            <InfoMarker
              color={
                isStatusExpired
                  ? '#6c6c6c'
                  : isStatusBorded
                  ? 'none'
                  : '#29348F'
              }
            />
          </div>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '8px',
          }}
          className={`info-location-short ${colorsClass}`}>
          <p>{startLocation.short}</p>
          <hr className='divider_dash' />
          <p>{endLocation.short}</p>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '8px',
          }}
          className={`info-location-long ${colorsClass}`}>
          <p>{startLocation.long}</p>
          <p>{endLocation.long}</p>
        </div>
      </div>
    </section>
  )
}
